import React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import './style.bundle.css';
import './datatables.bundle.css';
import './ideabox.scss';
import './custom.scss';

const theme = createTheme({});

const DefaultThemeProvider = (props) => {
  return <ThemeProvider theme={theme}>{props.children}</ThemeProvider>;
};

export default DefaultThemeProvider;
