import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from '@mui/material/Modal';
import SVG from 'react-inlinesvg';

import { SingleSelect } from '../../../../components/Selects';
import {
  fetchDailyQuotesMoods,
  createDailyQuote,
  updateQuote,
  deleteQuote,
} from '../../../../../features/admin/dailyQuotes';

import FormCreateDailyQuote from './form';
import './styles.scss';
import { find } from 'lodash';

const DailyQuotes = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [quoteSelected, setQuoteSelected] = useState(null);
  const dispatch = useDispatch();
  const { moods, dailyQuoteCreated, quoteUpdated, quoteDeleted } = useSelector(
    (state) => state.dailyQuotes
  );
  const [dailyType, setDailyType] = useState(null);
  const [openQuotes, setOpenQuotes] = useState([]);

  const handleClose = () => {
    setQuoteSelected(null);
    return setIsOpen(false);
  };

  const handleSetDailyType = (item) => {
    setDailyType(item);
  };

  const handleSelectDaily = (quoteId) => () => {
    if (openQuotes.includes(quoteId)) {
      return setOpenQuotes([...openQuotes.filter((id) => id !== quoteId)]);
    }
    return setOpenQuotes([...openQuotes, quoteId]);
  };

  useEffect(() => {
    const fetchDailyQuotes = async () => {
      const dailyQuotes = await dispatch(fetchDailyQuotesMoods()).unwrap();
      if (dailyQuotes?.length > 0) {
        setDailyType(dailyQuotes[0]);
      }
    };
    fetchDailyQuotes();
  }, [dailyQuoteCreated, quoteUpdated?.id, quoteDeleted?.id]);

  const handleSubmitDailyQuote = (data) => {
    const body = {
      daily_quote_mood: dailyType.id,
      ...data,
    };
    const quoteId = quoteSelected?.id;
    if (quoteId) {
      dispatch(updateQuote({ quoteId, body }));
      return setQuoteSelected(null);
    }
    dispatch(createDailyQuote(body));
    return setIsOpen(false);
  };

  const onDeleteQuote = () => {
    const quoteId = quoteSelected?.id;
    if (quoteId) {
      dispatch(deleteQuote({ quoteId }));
      return setQuoteSelected(null);
    }
    return setIsOpen(false);
  };

  const getDefaultValue = () => {
    if (quoteSelected) {
      const matched = find(moods, { id: quoteSelected.daily_quote_mood });
      const { Mood, id, ...rest } = matched;
      return { title: Mood, id };
    } else {
      return {
        title: moods[0].Mood,
        id: moods[0].id,
      };
    }
  };

  return (
    <>
      <div className="card-header border-0 pt-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bolder text-dark fs-2">
            Daily quotes
          </span>
        </h3>
        <div className="card-toolbar">
          <a
            className="btn btn-primary ms-auto"
            onClick={() => setIsOpen(true)}
            data-bs-toggle="modal"
          >
            <i className="fas fa-plus me-2 opacity-50" />
            Create Quote
          </a>
        </div>
      </div>

      <div className="card-body pt-12 mt-n3">
        <div className="accordion accordion-icon-toggle" id="kt_accordion_1">
          {moods?.length > 0 &&
            moods.map((mood) => (
              <div className="mb-5" key={mood.id}>
                <div
                  className="accordion-header py-3 d-flex"
                  onClick={handleSelectDaily(mood.id)}
                >
                  <span
                    className={`collapse-icon ${
                      openQuotes.includes(mood.id) ? 'open-icon' : ''
                    }`}
                  >
                    <span className="svg-icon svg-icon-4">
                      <SVG src="/images/icons/down.svg" />
                    </span>
                  </span>
                  <h3 className="fs-4 text-gray-800 fw-bold mb-0 ms-4">
                    {mood.Mood}
                  </h3>
                </div>
                <div
                  className={`fs-6 ps-10 ${
                    openQuotes.includes(mood.id) ? 'appear' : 'disappear'
                  }`}
                >
                  {mood.daily_quotes?.length > 0 &&
                    mood.daily_quotes.map((daily, index) => (
                      <div
                        className="card mb-5 rounded"
                        key={index}
                        onClick={() => setQuoteSelected(daily)}
                        style={{ cursor: 'pointer' }}
                      >
                        <div className="card-body bg-light">
                          <div className="w-100 ms-5 ms-md-0 order-md-1">
                            <h5 className="mb-5">Quote {index + 1}</h5>
                            <div className="row">
                              <div className="col-md-9">
                                <label className="fw-bold fs-4 text-muted">
                                  Quote
                                </label>
                                <textarea
                                  className="form-control"
                                  disabled="disabled"
                                  rows="4"
                                  value={daily.quote}
                                >
                                  {daily.quote}
                                </textarea>
                              </div>
                              <div className="col-md-3">
                                <label className="fw-bold fs-4 text-muted">
                                  Name
                                </label>
                                <input
                                  className="form-control"
                                  value={daily.name}
                                  disabled="disabled"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            ))}
        </div>
      </div>
      <Modal open={isOpen || !!quoteSelected?.id} onClose={handleClose}>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <div className="row mb-8">
                <label className="col-lg-3 col-form-label">User Type</label>
                <div className="col-lg-9">
                  {moods?.length > 0 && (
                    <SingleSelect
                      data={
                        moods?.length > 0
                          ? moods.map((mood) => ({
                              id: mood.id,
                              title: mood.Mood,
                            }))
                          : []
                      }
                      getData={(value) => handleSetDailyType(value)}
                      defaultValue={getDefaultValue}
                    />
                  )}
                </div>
              </div>
              <FormCreateDailyQuote
                handleClose={handleClose}
                onSubmit={handleSubmitDailyQuote}
                quoteSelected={quoteSelected}
                onDeleteQuote={onDeleteQuote}
              />
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default DailyQuotes;
