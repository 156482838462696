import React from 'react';
import SVG from 'react-inlinesvg';
import './styles.scss';

const SearchCar = ({ keyword, handleOnChange, onClearKeyWord }) => {
  return (
    <div className="d-flex flex-column flex-xxl-row">
      <div className="mb-2 me-2" style={{ maxHeight: 46 }}>
        <form
          className="w-100 position-relative mb-5"
          data-kt-search-element="form"
          autoComplete="off"
          onSubmit={(e) => e.preventDefault()}
        >
          <input type="hidden" />
          <span className="svg-icon svg-icon-2 svg-icon-info svg-icon-lg-1 position-absolute top-50 ms-5 translate-middle-y">
            <SVG src="/images/icons/search.svg" />
          </span>
          <input
            className="form-control form-control-lg px-15 border-0"
            type="text"
            name="search"
            placeholder="Search Car"
            data-kt-search-element="input"
            value={keyword}
            onChange={(e) => handleOnChange(e)}
          />
          <span
            className={`btn btn-flush btn-active-color-primary position-absolute top-50 end-0 translate-middle-y lh-0 me-5 ${
              keyword === '' ? 'd-none' : ''
            }`}
            data-kt-search-element="clear"
            onClick={onClearKeyWord}
          >
            <i className="bi bi-x fs-2" />
          </span>
        </form>
      </div>
    </div>
  );
};

export default SearchCar;
