import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { uniqBy } from 'lodash';

import { fetchCars } from '../../../../../features/admin/cars';
import './Components/styles.scss';
import { initialStrapiPagination } from '../../../../utils/constants';
import CarGrid from './Components/CarGrid';
import CarTable from './Components/CarTable';

const ShowType = {
  List: 0,
  Grid: 1,
};

const Cars = ({ typeShow, querySearch }) => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const dispatch = useDispatch();

  // list activity
  const [carsList, setCarsList] = useState([]);
  const [totalCars, setTotalCars] = useState(0);

  // search, pagination
  const [valueTopic, setValueTopic] = useState([]);
  const [pagination, setPagination] = useState({
    ...initialStrapiPagination,
    sort: 'name:asc',
  });

  // fetch cars list in first time
  useEffect(() => {
    const fetchLatestCars = async () => {
      const result = await dispatch(fetchCars({ ...pagination })).unwrap();
      setCarsList(result?.data);
      setTotalCars(result?.meta?.pagination?.total);
    };

    fetchLatestCars();
    return () => {
      setCarsList([]);
    };
  }, []);

  const onChangeTopic = (topic) => {
    setValueTopic(topic);
  };

  const onClickTopic = (e, topic) => {
    e.preventDefault();
    const topics = uniqBy([...valueTopic, topic], 'id');
    setValueTopic(topics);
  };

  /* Search key word */
  const queryParams = useMemo(() => {
    let params = new URLSearchParams();
    if (querySearch) {
      params.append('filters[name][$contains]', querySearch);
    }

    for (const key in pagination) {
      params.append(key, pagination[key]);
    }
    return params;
  }, [querySearch, pagination]);

  // control pagination and load more cars
  useEffect(() => {
    const searchCars = async (params) => {
      const result = await dispatch(fetchCars(params)).unwrap();
      if (typeShow === ShowType.Grid) {
        setCarsList([...carsList, ...result.data]);
      } else {
        setCarsList(result.data);
        setTotalCars(result?.meta?.pagination?.total);
      }
    };
    if (queryParams) {
      searchCars(queryParams);
    }
  }, [queryParams]);

  // reset result and pagination when change keyword or topic
  useEffect(() => {
    setCarsList([]);
    setPagination({
      ...initialStrapiPagination,
      sort: 'name:asc',
    });
  }, [valueTopic, querySearch, typeShow]);

  const handleLoadMore = () => {
    if (carsList.length >= totalCars) return;
    if (carsList.length > 0) {
      setPagination({
        ...pagination,
        'pagination[page]': pagination['pagination[page]'] + 1,
      });
    }
  };

  const onPageClick = (page) => {
    setPagination({
      ...pagination,
      'pagination[page]': page + 1,
    });
  };

  const onChangeSize = (size) => {
    setPagination({
      ...pagination,
      'pagination[page]': 1,
      'pagination[pageSize]': size,
    });
  };

  return (
    <>
      {typeShow === ShowType.Grid && (
        <CarGrid
          latestActivities={[]}
          cars={carsList}
          onClickTopic={onClickTopic}
          handleLoadMore={handleLoadMore}
          setCarsList={setCarsList}
        />
      )}
      {typeShow === ShowType.List && (
        <CarTable
          cars={carsList}
          onPageClick={onPageClick}
          onChangeSize={onChangeSize}
          totalCars={totalCars}
          onClickTopic={onClickTopic}
        />
      )}
    </>
  );
};

export default Cars;
