import axios from 'axios';

const API_ROOT = process.env.REACT_APP_API_ENDPOINT;
/* eslint-disable dot-notation */

axios.defaults.baseURL = API_ROOT;
axios.defaults.timeout = 120000;
axios.defaults.headers.common['Accept'] = 'application/json';

axios.interceptors.request.use(
  (config) => {
    const dataPersist = JSON.parse(localStorage.getItem('persist:root'));
    const auth = JSON.parse(dataPersist.auth);
    if (auth.accessToken) {
      config.headers['Authorization'] = `Bearer ${auth.accessToken}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

axios.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error.response || error.request || error.message)
);

const axiosPublicInstance = axios.create({
  baseURL: API_ROOT,
  timeout: 120000,
  headers: { Accept: 'application/json' },
});

const http = {
  setAuthorizationHeader(accessToken) {
    axios.defaults.headers.Authorization = `Bearer ${accessToken}`;
  },
  request(config = {}) {
    return axios.request(config);
  },
  get(url, data) {
    return axios.get(url, { params: data });
  },
  post(url, data) {
    return axios.post(url, data);
  },
  put(url, data) {
    return axios.put(url, data);
  },
  patch(url, data) {
    return axios.patch(url, data);
  },
  delete(url) {
    return axios.delete(url);
  },
  getPublicEndPoint(url, data) {
    return axiosPublicInstance.get(url, { params: data });
  },
};

export default http;
