import React from 'react';
import SVG from 'react-inlinesvg';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getTab, setCurrentTab } from '../../../../features/admin/dailyQuotes';
import DailyQuotes from './DailyQuotes';
import Faq from './Faq';
import FaqCategory from './FaqCategory';
import KnowledgeHub from './KnowledgeHub';
import Locations from './Locations';
import PrivacyPolicy from './PrivacyPolicy';
import TermCondition from './TermCondition';

const Cms = () => {
  const tab = useSelector(getTab);
  const dispatch = useDispatch();

  const sideBar = [
    {
      tab: 'daily-quotes',
      title: 'Daily quotes',
      icon: '/images/icons/dailyQuotes.svg',
    },
    {
      tab: 'faq',
      title: 'FAQ',
      icon: '/images/icons/faq.svg',
    },
    {
      tab: 'faq-category',
      title: 'FAQ Category',
      icon: '/images/icons/faq.svg',
    },
    // {
    //   tab: 'term-condition',
    //   title: 'Terms & Conditions',
    //   icon: '/images/icons/faq.svg',
    // },
    // {
    //   tab: 'privacy-policy',
    //   title: 'Privacy Policy',
    //   icon: '/images/icons/faq.svg',
    // },
    {
      tab: 'knowledge-hub',
      title: 'Knowledge hub',
      icon: '/images/icons/knowHub.svg',
    },
    {
      tab: 'locations',
      title: 'Locations',
      icon: '/images/icons/location.svg',
    },
  ];

  const renderTabContent = () => {
    switch (tab) {
      case 'daily-quotes':
        return <DailyQuotes />;
      case 'faq':
        return <Faq />;
      case 'faq-category':
        return <FaqCategory />;
      // case 'term-condition':
      //   return <TermCondition />;
      // case 'privacy-policy':
      //   return <PrivacyPolicy />;
      case 'knowledge-hub':
        return <KnowledgeHub />;
      case 'locations':
        return <Locations />;
      default:
        return null;
    }
  };

  const handleSelectTab = (e, tabName, title) => {
    e.preventDefault();
    dispatch(setCurrentTab({ title, tab: tabName }));
  };

  const getBtnClass = (name) => {
    return tab === name
      ? 'btn btn-icon btn-primary'
      : 'btn btn-icon btn-bg-light';
  };

  return (
    <div>
      <div className="d-flex flex-column flex-column-fluid">
        <div className="content fs-6 d-flex flex-column-fluid" id="kt_content">
          <div className="container">
            <div className="d-flex flex-column flex-md-row">
              <div className="flex-column flex-md-row-auto w-100 w-md-250px w-lg-300px w-xxl-350px">
                <div className="card mb-10 mb-md-0">
                  <div className="card-body py-10 px-6">
                    {sideBar.map((item, index) => (
                      <div
                        key={index}
                        className="d-flex flex-wrap align-items-center mb-7"
                      >
                        <div className="symbol symbol-40px symbol-2by3 me-4">
                          <div className={getBtnClass(item.tab)}>
                            <span className="svg-icon svg-icon-1">
                              <SVG src={item.icon} />
                            </span>
                          </div>
                        </div>
                        <div className="d-flex flex-column flex-grow-1 my-lg-0 my-2 pe-3">
                          <Link
                            className="text-dark fw-bolder text-hover-primary fs-6"
                            onClick={(e) =>
                              handleSelectTab(e, item.tab, item.title)
                            }
                            to="#"
                          >
                            {item.title}
                          </Link>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="flex-md-row-fluid ms-md-12">
                <div className="card card-stretch mb-5 mb-xxl-8">
                  {renderTabContent()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cms;
