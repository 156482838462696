import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Topic from '../../api/admin/topic';
import { parseError } from '../../app/utils/helper';

export const fetchTopics = createAsyncThunk(
  'admin/fetch-topics',
  async (queryParams, { rejectWithValue }) => {
    try {
      const { data } = await Topic.fetchTopics(queryParams);
      return data;
    } catch (error) {
      return rejectWithValue(parseError(error));
    }
  }
);

export const updateTopic = createAsyncThunk(
  'admin/update-topic',
  async ({ id, body }, { rejectWithValue }) => {
    try {
      const { data } = await Topic.updateTopic(id, body);
      return { data, message: 'Update topic successfully' };
    } catch (error) {
      return rejectWithValue(parseError(error));
    }
  }
);

export const createTopic = createAsyncThunk(
  'admin/create-topic',
  async (body, { rejectWithValue }) => {
    try {
      const { data } = await Topic.createTopic(body);
      return { data, message: 'Create topic successfully' };
    } catch (error) {
      return rejectWithValue(parseError(error));
    }
  }
);

export const deleteTopic = createAsyncThunk(
  'admin/delete-topic',
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await Topic.deleteTopic(id);
      return { data, message: 'Delete topic successfully' };
    } catch (error) {
      return rejectWithValue(parseError(error));
    }
  }
);

const initialState = {
  listTopics: [],
};

const topicsSlice = createSlice({
  name: 'topic',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchTopics.fulfilled]: (state, action) => {
      state.listTopics = action.payload;
    },
  },
});

export const selectTopicList = (state) => state.topic.listTopics;

export default topicsSlice.reducer;
