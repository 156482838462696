import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import SVG from 'react-inlinesvg';
import {
  checkDailyPulse,
  createDailyPulse,
} from '../../../../features/client/dashboard';
import { useDispatch } from 'react-redux';
import { Modal } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const PulseType = {
  Struggling: 'Struggling',
  Ok: 'Ok',
  Well: 'Well',
};

const Dashboard = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isOpenModal, setOpenModal] = useState(false);
  const [isStruggling, setStruggling] = useState(false);

  useEffect(() => {
    const dailyPulse = async () => {
      const result = await dispatch(checkDailyPulse()).unwrap();
      if (result === true) {
        history.push('/client/dashboard2');
      }
    };
    dailyPulse();
  }, []);

  const onPulseDaily = async (status) => {
    const pulseDaily = await dispatch(createDailyPulse({ status })).unwrap();
    if (pulseDaily.struggling === true) {
      setStruggling(true);
    } else {
      setStruggling(false);
    }
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
    history.push('/client/dashboard2');
  };

  return (
    <>
      <div
        className="header"
        id="kt_header"
        data-kt-sticky="true"
        data-kt-sticky-name="header"
        data-kt-sticky-offset="{default: '200px', lg: '300px'}"
      >
        <div className="container d-flex align-items-center justify-content-between">
          <Link to="/client/dashboard">
            <img
              className="h-30px d-none d-lg-block"
              alt="Logo"
              src="/images/logos/logo-primary.svg"
            />
            <img
              className="h-30px d-lg-none"
              alt="Logo"
              src="/images/logos/logo-mobile-client.svg"
            />
          </Link>
        </div>
      </div>

      <div className="d-flex flex-column flex-column-fluid">
        <div className="content fs-6 d-flex flex-column-fluid" id="kt_content">
          <div className="container">
            <div className="d-flex flex-column flex-column-auto flex-column-md-fluid flex-center h-md-100">
              <h3 className="fs-2x mb-10">How are you feeling today?</h3>
              <div className="container">
                <div className="row g-5 flex-center">
                  <div className="col-md-4 col-xl-3">
                    <div
                      className="card hoverable min-h-125px shadow-sm mb-0 mb-md-5 client-state client-state--yellow"
                      onClick={() => onPulseDaily(PulseType.Struggling)}
                    >
                      <div className="card-body d-flex flex-column flex-center px-5">
                        <span className="svg-icon svg-icon-6x  ">
                          <SVG src="/images/icons/feel-struggling.svg" />
                        </span>
                        <p className="mb-0 mt-5 text-gray-600">
                          I am struggling
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-xl-3">
                    <div
                      className="card hoverable min-h-125px shadow-sm mb-0 mb-md-5 client-state client-state--pink"
                      onClick={() => onPulseDaily(PulseType.Ok)}
                    >
                      <div className="card-body d-flex flex-column flex-center px-5">
                        <span className="svg-icon svg-icon-6x  ">
                          <SVG src="/images/icons/feel-ok.svg" />
                        </span>
                        <p className="mb-0 mt-5 text-gray-600">I am OK</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-xl-3">
                    <div
                      className="card hoverable min-h-125px shadow-sm mb-0 mb-md-5 client-state client-state--purple-light"
                      onClick={() => onPulseDaily(PulseType.Well)}
                    >
                      <div className="card-body d-flex flex-column flex-center px-5">
                        <span className="svg-icon svg-icon-6x  ">
                          <SVG src="/images/icons/feel-well.svg" />
                        </span>
                        <p className="mb-0 mt-5 text-gray-600">
                          I am doing well
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal open={isOpenModal} onClose={handleClose}>
        <div className="modal-content modal-centered">
          <div className="modal-body">
            <div className="text-center">
              <div className="my-10">
                {isStruggling ? (
                  <i className="bi bi-exclamation-circle text-danger fs-5x"></i>
                ) : (
                  <CheckCircleIcon color="success" sx={{ fontSize: 40 }} />
                )}
              </div>
              <h3>{isStruggling ? 'Struggling' : 'Nicely done!'}</h3>

              {isStruggling && (
                <p>
                  we would like to hear from you if anything is not well. You
                  can contact us via{' '}
                  <a href="mailto:helpsupport@strong.com.au">
                    helpsupport@strong.com.au
                  </a>
                </p>
              )}

              {!isStruggling && (
                <p>You have taken your pulse check for the day!</p>
              )}
            </div>
          </div>
          <div className="modal-footer border-0 justify-content-center">
            <button
              className="w-100px btn btn-light-primary"
              type="button"
              onClick={handleClose}
            >
              Ok
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Dashboard;
