import http from '../../services/http-common';

export default class DailyQuotes {
  static fetchDailyQuotesMoods() {
    return http.get('/daily-quote-moods');
  }

  static createDailyQuote(body) {
    return http.post('/daily-quotes', body);
  }

  static updateQuote(quoteId, body) {
    return http.put(`/daily-quotes/${quoteId}`, body);
  }

  static deleteQuote(quoteId) {
    return http.delete(`/daily-quotes/${quoteId}`);
  }
}
