import http from '../../services/http-common';

export default class Activity {
  static fetchActivities(queryParams) {
    return http.get('/activities', queryParams);
  }

  static searchActivitiesByClientId(clientId, queryParams) {
    return http.get(`/activities/search/client/${clientId}`, queryParams);
  }

  static fetchActivityById(id) {
    return http.get(`/activities/${id}`);
  }

  static fetchChapterById(id) {
    return http.get(`/chapters/${id}`);
  }

  static fetchQuestionById(id) {
    return http.get(`/questions/${id}`);
  }

  static create(body) {
    return http.post('/activities/unique', body);
  }

  static getActivityDetail(id) {
    return http.get(`/activities/${id}`);
  }

  static deleteActivity(id) {
    return http.delete(`/activities/${id}`);
  }

  static deleteChapter(id) {
    return http.delete(`/chapters/${id}`);
  }

  static deleteQuestion(id) {
    return http.delete(`/questions/${id}`);
  }

  static deleteAnswer(id) {
    return http.delete(`/answers/${id}`);
  }
}
