import React, { useState, useRef } from 'react';
import { get } from 'lodash';
import { displayIconSort, formatDate } from '../../../../../utils/helper';
import { updateCar } from '../../../../../../features/admin/cars';
import Pagination from '../../../../../components/Pagination';
import { useSortableData } from '../../../../../../hooks/useSortableData';
import DeleteButton from '../../../../../components/DeleteButton';
import ModalConfirm from '../../../../../components/Modal/ModalConfirm';
import { useDispatch } from 'react-redux';

function CarTable({
  cars,
  onPageClick,
  onChangeSize,
  totalCars,
}) {
  const dispatch = useDispatch();
  const handleNavigate = (activity) => {
    // history.push(`/admin/activity-summary/${activity.id}`);
  };
  const { items: dataTable, requestSort, sortConfig } = useSortableData(cars);
  const [isShowModal, setShowModal] = useState(false);
  const selected = useRef(0);
  return (
    <div className="card">
      <div className="card-body">
        <div className="card">
          <div className="card-body p-0">
            <div
              id="kt_datatable_example_2_wrapper"
              className="dataTables_wrapper dt-bootstrap4 no-footer"
            >
              <div className="mobile-table">
                <table
                  className="table table-striped table-row-bordered gy-5 gs-7 align-middle w-100"
                  id="kt_datatable_example_2"
                >
                  <thead>
                    <tr className="fw-bold fs-6 text-gray-800">
                      <th
                        className="text-center hoverable fw-bolder"
                        onClick={() => requestSort('id')}
                      >
                        Id
                        <span className="w-20px h-20px">
                          {displayIconSort('id', sortConfig)}
                        </span>
                      </th>
                      <th className="fw-bolder">Car</th>
                      <th
                        className="text-center hoverable fw-bolder"
                        onClick={() => requestSort('name')}
                      >
                        Name
                        <span className="w-20px h-20px">
                          {displayIconSort('name', sortConfig)}
                        </span>
                      </th>
                      <th
                        className="text-center hoverable fw-bolder"
                        onClick={() => requestSort('regNumber')}
                      >
                        Registration no.
                        <span className="w-20px h-20px">
                          {displayIconSort('regNumber', sortConfig)}
                        </span>
                      </th>
                      <th
                        className="text-center hoverable fw-bolder"
                        onClick={() => requestSort('status')}
                      >
                        Status
                        <span className="w-20px h-20px">
                          {displayIconSort('status', sortConfig)}
                        </span>
                      </th>

                      <th
                        className="text-center hoverable fw-bolder"
                        onClick={() => requestSort('incidentReport')}
                      >
                        Incident Report
                        <span className="w-20px h-20px">
                          {displayIconSort('incidentReport', sortConfig)}
                        </span>
                      </th>

                      <th
                        className="text-center hoverable fw-bolder"
                        onClick={() => requestSort('createdAt')}
                      >
                        Created
                        <span className="w-20px h-20px">
                          {displayIconSort('createdAt', sortConfig)}
                        </span>
                      </th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {dataTable &&
                      dataTable.map((item, index) => (
                        <tr
                          onClick={(item) => handleNavigate(item)}
                          key={index}
                        >
                          <td className="min-w-100px text-center ">
                            {get(item, 'id')}
                          </td>
                          <td className="min-w-300px">
                            <div className="d-flex align-items-center">
                              <div
                                className="bgi-no-repeat bgi-size-cover rounded min-w-100 min-h-sm-100px min-h-md-150px h-100 p-5"
                                style={{
                                  backgroundImage: `url(${get(
                                    item,
                                    'attributes.image.data.attributes.url'
                                  ) || '/images/illustrations/carModel.png'
                                    })`,
                                  backgroundPosition: 'center',
                                }}
                              ></div>
                            </div>
                          </td>
                          <td className="min-w-150px text-center ">
                            {get(item, 'attributes.name')}
                          </td>
                          <td className="min-w-150px text-center">
                            {get(item, 'attributes.regNumber')}
                          </td>
                          <td className="min-w-150px text-center text-info fw-bolder">
                            <span className="hoverable">
                              {get(item, 'attributes.status')}
                            </span>
                          </td>
                          <td className="min-w-150px text-center">
                            {get(item, 'attributes.incidentReport')
                              ? 'Yes'
                              : 'No'}
                          </td>
                          <td className="min-w-150px text-center">
                            {formatDate(get(item, 'attributes.createdAt'))}
                          </td>
                          <td>
                            <DeleteButton onClick={() => {
                              selected.current = get(item, 'id');
                              setShowModal(true);
                            }} />
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
              {cars.length > 0 && (
                <Pagination
                  onChangeSize={onChangeSize}
                  onPageClick={onPageClick}
                  total={totalCars}
                />
              )}
              <ModalConfirm
                isOpen={isShowModal}
                handleClose={() => setShowModal(false)}
                confirm={async () => {
                  setShowModal(false);
                  await dispatch(updateCar({
                    id: selected.current,
                    body: {
                      data: {
                        publishedAt: null
                      },
                    },
                  }));
                  onPageClick(0);
                }}
                modalTitle={'Are you sure you want to do this?'}
                modalNotes={'This action can not be undone'}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CarTable;
