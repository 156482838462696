import http from '../../services/http-common';

export default class DashBoard {
  static fetchClientDashboardInfo() {
    return http.get('/dashboard/client');
  }

  static checkDailyPulse() {
    return http.get('/daily-pulses/check');
  }

  static createDailyPulse(data) {
    return http.post('/daily-pulses', data);
  }

  static getDataPulseChart(queryParams) {
    return http.get('/daily-pulses', queryParams);
  }
}
