import http from '../../services/http-common';

export default class Car {
  static fetchCars(queryParams) {
    return http.get('/api/cars', queryParams);
  }

  static updateCar(carId, body) {
    return http.put(`/api/cars/${carId}`, body);
  }

  static createCar(body) {
    return http.post(`/api/cars`, body);
  }
}
