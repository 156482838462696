import React from 'react';
import SVG from 'react-inlinesvg';
import { useHistory } from 'react-router-dom';

const LinkBack = () => {
  const history = useHistory();
  return (
    <button className="btn text-nowrap pe-0" onClick={() => history.goBack()}>
      <span className="svg-icon svg-icon-4 svg-icon-info me-2">
        <SVG src="/images/icons/back.svg" />
      </span>
      Back
    </button>
  );
};

export default LinkBack;
