import React from 'react';
import SVG from 'react-inlinesvg';
import { Link } from 'react-router-dom';
import { get } from 'lodash';

function Activities({ listActivitiesClientDashboard }) {
  return (
    <div className="card card-stretch mb-5 mb-xxl-8">
      <div className="card-header border-0 pt-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bolder text-dark fs-2">
            Activities
          </span>
          <span className="text-muted mt-2 fw-bold fs-5 ellipsis-1row">
            Checkout your activities
          </span>
        </h3>
      </div>
      <div className="card-body pt-12 mt-n3">
        <ul className="list-group list-group-flush">
          {listActivitiesClientDashboard?.map((activity, index) => (
            <li
              key={index}
              className="list-group-item flex-center border-0 px-0 py-3 d-flex"
            >
              <div className="symbol symbol-55px mt-1 me-5 overflow-hidden">
                <span className="symbol-label bg-light-primary">
                  <img
                    className="mh-55px"
                    alt="Logo"
                    src={get(activity, 'activity.activity_picture.url')}
                  />
                </span>
              </div>
              <div className="pe-10 flex-row-fluid">
                <h3 className="fw-bolder mw-300px ellipsis-1row">
                  <Link
                    className="text-gray-800 text-hover-primary"
                    to={`/client/activity/${activity.activity.id}`}
                  >
                    {get(activity, 'activity.title')}
                  </Link>
                </h3>
                <span className="fw-bold fs-6 text-gray-500 ellipsis-1row">
                  {get(activity, 'activity.summary')}
                </span>
              </div>
              <div className="ms-auto">
                <Link
                  className="btn btn-icon btn-bg-light btn-active-primary btn-sm"
                  to={`/client/activity/${activity.activity.id}`}
                >
                  <span className="svg-icon svg-icon-4  ">
                    <SVG src="/images/icons/right.svg" />
                  </span>
                </Link>
              </div>
            </li>
          ))}
          <li className="list-group-item flex-center border-0 px-0 py-3 d-none d-md-flex"></li>
          <li className="list-group-item flex-center border-0 px-0 py-3 d-none d-md-flex"></li>
        </ul>
        <div className="text-end">
          <Link
            className="btn btn-primary fw-bolder mt-5 w-100 w-lg-auto py-5"
            to="/client/activity"
          >
            View all
            <span className="svg-icon svg-icon-3  me-0 ms-2">
              <SVG src="/images/icons/right.svg" />
            </span>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Activities;
