import http from '../../services/http-common';

export default class Activity {
  static fetchTrips(queryParams) {
    return http.get('/api/trips/all', queryParams);
  }

  static fetchTripById(id, queryParams) {
    return http.get(`/api/trips/${id}`, queryParams);
  }

  static updateTripById(id, body) {
    return http.put(`/api/trips/${id}`, body);
  }
}
