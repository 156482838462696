import { createSlice } from '@reduxjs/toolkit';
import {
  isFulfilledAction,
  isPendingAction,
  isRejectedAction,
} from '../../app/utils/helper';

const initialState = {
  status: 'idle',
};

const features = [
  'user/',
  'upload/',
  'auth/',
  'admin/',
  'adminActivity/',
  'adminAssignedActivity/',
  'clientActivity/',
  'adminDashboard/',
  'clientDashboard/',
  'clientKnowledges/',
  'clientFAQ/',
  'Car/fetchCars',
];

const loadingSlice = createSlice({
  name: 'loading',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(isPendingAction(features), (state) => {
      state.status = 'loading';
    });
    builder.addMatcher(isFulfilledAction(features), (state) => {
      state.status = 'idle';
    });
    builder.addMatcher(isRejectedAction(features), (state) => {
      state.status = 'idle';
    });
  },
});

export const selectStateLoading = (state) => state.loading.status;
export default loadingSlice.reducer;
