import React from 'react';
import './styles.scss';

const TripInfoView = ({ timeText, locationText, lat, lng, time, location }) => {
  return (
    <>
      <div className="d-flex justify-content-sm-between trip-info-log">
        <div>
          <div>{timeText}</div>
          <div>{time}</div>
        </div>
        <div>
          <div>{locationText}</div>
          <div>{location}</div>
        </div>
      </div>
      <img
        className="image-map"
        src={`https://maps.googleapis.com/maps/api/staticmap?center=${lat},${lng}&markers=${lat},${lng}&zoom=15&size=1000x500&key=AIzaSyC697tRMOHzfTCtJmtLaWCRDVPpbIh_vuc`}
        width="100%"
        height="300"
      />
    </>
  );
};

export default TripInfoView;
