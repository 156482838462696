import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import SVG from 'react-inlinesvg';

import { toggleOpenProfile } from '../../../features/user';
import useComponentVisible from '../../../hooks/use-invisible-component';
import {
  selectAuthUser,
  updateMyPassword,
  logout,
  updateMe,
} from '../../../features/user/auth';
import { upload } from '../../../features/upload';
import ChangePWForm from './ChangePWForm';

import './Profile.style.scss';

const ProfileTab = () => {
  const { ref } = useComponentVisible();
  const userInfo = useSelector(selectAuthUser);
  const dispatch = useDispatch();
  const [image, setImage] = useState(
    get(userInfo, 'profile_picture[0].formats.thumbnail.url') || ''
  );
  const firstName = get(userInfo, 'first_name', '');
  const surname = get(userInfo, 'surname', '');

  const getAvatar = async (file) => {
    const formData = new FormData();
    formData.append('files', file);
    formData.append(
      'fileInfo',
      JSON.stringify({ alternativeText: '', caption: '', name: null })
    );
    const uploadResponse = await dispatch(upload(formData)).unwrap();
    if (uploadResponse && uploadResponse?.length > 0) {
      const data = {
        profile_picture: uploadResponse,
      };
      dispatch(updateMe({ id: userInfo.id, data }));
    }
  };

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      const img = event.target.files[0];
      setImage(URL.createObjectURL(img));
      getAvatar(img);
    }
  };

  const onChangePW = (data) => {
    dispatch(updateMyPassword(data));
  };

  const onHanleSignOut = (e) => {
    e.preventDefault();
    dispatch(logout());
  };

  const handleCancel = (cbReset) => () => {
    cbReset();
    dispatch(toggleOpenProfile(false));
  };

  return (
    <div className="profile-container" ref={ref}>
      <div className="card w-100 bg-profile-info rounded-0 h-100">
        <div className="card-header border-0 header-profile">
          <div className="w-100">
            <div
              className="d-flex flex-center position-relative bgi-no-repeat bgi-size-contain bgi-position-x-center bgi-position-y-center mt-10 mb-5 mt-lg-10"
              style={{
                backgroundImage: 'url(/images/svg/illustrations/bg-3.svg)',
              }}
            >
              <div
                className="image-input image-input-empty symbol symbol-circle symbol-100px symbol-lg-130px border-info-3px"
                style={{ backgroundImage: `url(${image})` }}
              >
                <div className="image-input-wrapper border-circle w-60px w-md-100px h-60px h-md-100px"></div>
                <label
                  className="btn btn-icon btn-circle btn-active-color-primary w-20px w-md-25px h-20px h-md-25px bg-white shadow"
                  data-kt-image-input-action="change"
                  data-bs-toggle="tooltip"
                  data-bs-dismiss="click"
                  title="Change avatar"
                >
                  <i className="bi bi-pencil-fill fs-7" />
                  <input
                    type="file"
                    name="avatar"
                    accept=".png, .jpg, .jpeg"
                    onChange={onImageChange}
                  />
                  <input type="hidden" name="avatar_remove" />
                </label>
                <span
                  className="btn btn-icon btn-circle btn-active-color-primary w-20px w-md-25px h-20px h-md-25px bg-white shadow"
                  data-kt-image-input-action="cancel"
                  data-bs-toggle="tooltip"
                  data-bs-dismiss="click"
                  title="Cancel avatar"
                >
                  <i className="bi bi-x fs-2" />
                </span>
                <span
                  className="btn btn-icon btn-circle btn-active-color-primary w-20px w-md-25px h-20px h-md-25px bg-white shadow"
                  data-kt-image-input-action="remove"
                  data-bs-toggle="tooltip"
                  data-bs-dismiss="click"
                  title="Remove avatar"
                >
                  <i className="bi bi-x fs-2" />
                </span>
              </div>
            </div>
            <div className="text-center pb-5">
              <h3 className="fw-bolder text-white fs-5 fs-xl-2">
                {firstName}
                <span className="d-md-block ps-2 ps-md-0">{surname}</span>
              </h3>
            </div>
            <div className="row row-cols-2 px-5 px-xl-13 sidebar-toolbar">
              <div className="col">
                Account type
                <br />
                <span className="fw-bolder fs-6">Employee</span>
              </div>
              <div className="col">
                Department
                <br />
                <span className="fw-bolder fs-6">Sales</span>
              </div>
            </div>
          </div>
        </div>
        <div className="card-body py-0 h-25 overflow-auto">
          <div className="scroll-y me-n5 pe-5">
            <div className="card-body p-0">
              <ChangePWForm onChangePW={onChangePW} onCancel={handleCancel} />
            </div>
          </div>
        </div>
        <div
          className="media-special-device card-footer pt-4 text-center border-0"
          id="kt_drawer_chat_messenger_footer"
        >
          <div className="mb-5">
            <a
              className="btn btn-info w-100 fw-bolder d-flex flex-center py-5"
              href="https://veretis-group.au2.cliniko.com/bookings?appointment_type_id[]=569775020276980285&appointment_type_id[]=569775335982242367#service"
              target="_blank"
              rel="noopener noreferrer"
            >
              Book an appointment
              <i className="far fa-calendar ms-3 text-gray-500 fs-4" />
            </a>
          </div>
          <p className="mb-4">
            To update your name, account type and department, contact HR
            directly.
          </p>
          <div className="text-center mt-4">
            <a
              className="btn btn-link text-info text-hover-primary"
              onClick={onHanleSignOut}
              data-bs-dismiss="modal"
            >
              Sign out
              <span className="svg-icon svg-icon-3  me-0 ms-2 opacity-50">
                <SVG src="/images/icons/sign-out.svg" />
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileTab;
