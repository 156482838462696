import React, { useMemo, useEffect } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import Dashboard from './Dashboard';
import Dashboard2 from './Dashboard2';
import Knowledgeable from './Knowledgeable';
import Faq from './Faq';
import ActivityList from './Activity/ActivityList';
import Intro from './Activity/Detail/Intro';
import Chapters from './Activity/Detail/Chapters';
import SideBar from './Layout/SideBar';
import Account from './Account';
import ClientFooter from '../../components/ClientFooter';
import HeaderClient from '../../components/HeaderClient';
import { selectAssignedActivityDetail } from '../../../features/client/activity';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import { selectOpenProfile } from '../../../features/user';
import ProfileTab from '../../components/HeaderProfile';
import ScrollTop from '../../components/ScrollToTop';
import useAnimationMobileMenu from '../../../hooks/use-animation-mobile-menu';

const Client = () => {
  const { isScrollAnimated } = useAnimationMobileMenu();
  const location = useLocation();
  const { activity } = useSelector(selectAssignedActivityDetail);
  const isOpenProfile = useSelector(selectOpenProfile);
  const routers = useMemo(() => {
    return [
      {
        displayHeader: false,
        key: 'Dashboard',
        path: '/client/dashboard',
        component: Dashboard,
      },
      {
        displayHeader: true,
        key: 'Dashboard2',
        path: '/client/dashboard2',
        isShowUser: true,
        component: Dashboard2,
      },

      {
        displayHeader: true,
        key: 'ChapterDetail',
        title: get(activity, 'title'),
        path: '/client/activity/:id/chapters',
        needBack: true,
        // have to use it because in header chapter (mobile) is different
        needBackMobile: false,
        breadCrumbs: [
          {
            name: 'Home',
            path: '/client/dashboard2',
          },
          {
            name: 'Activities',
            path: '/client/activity',
          },
          {
            name: 'Intro',
            path: `/client/activity/${get(activity, 'id')}`,
          },
          { name: get(activity, 'title') },
        ],
        component: Chapters,
      },
      {
        displayHeader: true,
        key: 'ActivityDetail',
        title: 'Activity intro',
        path: '/client/activity/:id',
        needBack: true,
        needBackMobile: true,
        breadCrumbs: [
          {
            name: 'Home',
            path: '/client/dashboard2',
          },
          {
            name: 'Activities',
            path: '/client/activity',
          },
          {
            name: 'Activity intro',
          },
        ],
        component: Intro,
      },
      {
        displayHeader: true,
        key: 'ActivityList',
        title: 'Activities',
        path: '/client/activity',
        needBack: true,
        needBackMobile: true,
        breadCrumbs: [
          {
            name: 'Home',
            path: '/client/dashboard2',
          },
          {
            name: 'Activities',
          },
        ],
        component: ActivityList,
      },
      {
        displayHeader: true,
        key: 'KnowledgeHub',
        title: 'Knowledge hub',
        path: '/client/knowledge-hub',
        isShowUser: true,
        breadCrumbs: [
          {
            name: 'Home',
            path: '/client/dashboard2',
          },
          {
            name: 'Knowledge hub',
          },
        ],
        component: Knowledgeable,
      },
      {
        displayHeader: true,
        key: 'FAQ',
        title: 'FAQ',
        path: '/client/faq',
        isShowUser: true,
        breadCrumbs: [
          {
            name: 'Home',
            path: '/client/dashboard2',
          },
          {
            name: 'FAQ',
          },
        ],
        component: Faq,
      },
      {
        displayHeader: true,
        key: 'account',
        name: 'My account',
        path: '/client/account',
        component: Account,
      },
    ];
  }, [activity]);

  const getWrapperClassName = () => {
    if (location.pathname === '/client/dashboard') {
      return 'header-fixed header-tablet-and-mobile-fixed aside-secondary-disabled vh-100';
    }
    return 'header-fixed header-tablet-and-mobile-fixed aside-secondary-disabled vh-100 aside-enabled aside-fixed';
  };

  useEffect(() => {
    const body = document.getElementById('root');
    if (body) {
      body.style.overflow = isOpenProfile ? 'hidden' : '';
    }
  }, [isOpenProfile]);

  return (
    <div className={getWrapperClassName()}>
      <div className="d-flex flex-column flex-root vh-100">
        <div className="page d-flex flex-row flex-column-fluid ">
          {location.pathname !== '/client/dashboard' && <SideBar />}
          <div
            className="wrapper d-flex flex-column flex-row-fluid"
            id="kt_wrapper"
          >
            <HeaderClient
              routers={routers}
              isScrollAnimated={isScrollAnimated}
            />
            {isOpenProfile && <ProfileTab />}
            <Switch>
              {routers.map((item, index) => (
                <Route
                  key={index}
                  path={item.path}
                  component={item.component}
                />
              ))}
            </Switch>
            <ClientFooter />
            {location.pathname !== '/client/dashboard' && <ScrollTop />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Client;
