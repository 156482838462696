import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from '@mui/material/Modal';
import SVG from 'react-inlinesvg';
import { sortBy, findIndex } from 'lodash';

import FormFaq from './form';
import {
  fetchFaqs,
  createFaq,
  updateFaq,
  deleteFaq,
  updateOrderFaq,
  fetchFaqCategory,
} from '../../../../../features/admin/faqs';

const Faq = () => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [faqSelected, setFaqSelected] = useState(null);
  const [faqs, setFaqs] = useState([]);
  const { faqCreated, faqUpdated, faqDeleted, updateOrder, faqCategory } =
    useSelector((state) => state.faqs);

  useEffect(() => {
    dispatch(fetchFaqCategory());
  }, []);

  useEffect(() => {
    const fetchingFaqs = async () => {
      const faqs = await dispatch(fetchFaqs()).unwrap();
      setFaqs(faqs?.length > 0 ? sortBy(faqs, 'order') : []);
    };
    fetchingFaqs();
  }, [faqCreated?.id, faqUpdated?.id, faqDeleted?.id, updateOrder]);

  const handleClose = () => {
    setFaqSelected(null);
    return setIsOpen(false);
  };

  const handleFaqSubmiss = (data) => {
    const body = { ...data, order: faqs.length + 1 };
    if (faqSelected?.id) {
      dispatch(updateFaq({ id: faqSelected.id, body: data }));
      return setFaqSelected(null);
    }
    dispatch(createFaq(body));
    return setIsOpen(false);
  };

  const onDeleteFaq = () => {
    if (faqSelected?.id) {
      dispatch(deleteFaq({ id: faqSelected.id }));
      return setFaqSelected(null);
    }
    setFaqSelected(null);
    return setIsOpen(false);
  };

  const onHandleOrder = (orderValue, type) => () => {
    const faqId = orderValue.id;
    const faqOrder = orderValue.order;
    const faqIndex = findIndex(faqs, { id: faqId });
    if (faqs.length < 2) {
      return;
    }
    switch (type) {
      case 'up': {
        const orderValueNearBy = faqs[faqIndex - 1];
        if (!!orderValueNearBy) {
          const body = {
            orderUp: orderValueNearBy.order,
            idUp: faqId,
            orderDown: faqOrder,
            idDown: orderValueNearBy.id,
          };
          dispatch(updateOrderFaq(body));
        }
        break;
      }
      case 'down': {
        const orderValueNearBy = faqs[faqIndex + 1];
        if (!!orderValueNearBy) {
          const body = {
            orderUp: faqOrder,
            idUp: orderValueNearBy.id,
            orderDown: orderValueNearBy.order,
            idDown: faqId,
          };
          dispatch(updateOrderFaq(body));
        }
        break;
      }
      default: {
      }
    }
  };

  return (
    <>
      <div className="card-header border-0 pt-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bolder text-dark fs-2">FAQ</span>
        </h3>
        <div className="card-toolbar">
          <a
            className="btn btn-primary ms-auto"
            onClick={() => setIsOpen(true)}
            data-bs-toggle="modal"
          >
            <i className="fas fa-plus me-2 opacity-50" />
            Create New FAQ
          </a>
        </div>
      </div>

      <div className="card-body pt-12 mt-n3">
        <ul className="list-group list-group-flush">
          {faqs?.length > 0 &&
            faqs.map((faq) => (
              <li className="list-group-item" key={faq.id}>
                <div className="d-flex align-items-center">
                  <div className="d-flex">
                    <button
                      className="btn btn-icon btn-icon-primary btn-move-up"
                      onClick={onHandleOrder(faq, 'up')}
                    >
                      <span className="svg-icon svg-icon-4">
                        <SVG src="/images/icons/up-2.svg" />
                      </span>
                    </button>
                    <button
                      className="btn btn-icon btn-icon-info btn-move-down"
                      onClick={onHandleOrder(faq, 'down')}
                    >
                      <span className="svg-icon svg-icon-4">
                        <SVG src="/images/icons/arrow-down.svg" />
                      </span>
                    </button>
                  </div>
                  <span
                    className="text-dark text-hover-primary ms-5"
                    style={{ fontWeight: 500, cursor: 'pointer' }}
                    onClick={() => setFaqSelected(faq)}
                  >
                    {faq.question}
                  </span>
                </div>
              </li>
            ))}
        </ul>
      </div>
      <Modal open={isOpen || !!faqSelected?.id} onClose={handleClose}>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <FormFaq
                handleClose={handleClose}
                onSubmit={handleFaqSubmiss}
                faqSelected={faqSelected}
                onDeleteFaq={onDeleteFaq}
                faqCategory={faqCategory}
              />
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Faq;
