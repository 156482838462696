import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import Cars from './Cars';
import { get, debounce } from 'lodash';
import SVG from 'react-inlinesvg';
import Modal from '@mui/material/Modal';
import CarForm from './CreateCarForm/form';
import { upload } from '../../../../features/upload';
import { createCar } from '../../../../features/admin/cars';
import ModalConfirm from '../../../components/Modal/ModalConfirm';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { CustomizeTooltip } from '../../../components/ToolTips';
import SearchCar from './Cars/Components/SearchCar';
import { DebounceTimeDefault } from '../../../utils/constants';

const ShowType = {
  List: 0,
  Grid: 1,
};

const CarList = () => {
  const history = useHistory();

  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const dispatch = useDispatch();
  const [isOpenCreateCar, setIsOpenCreateCar] = useState(false);
  const [typeShow, setTypeShow] = useState(ShowType.Grid);
  const [tab, setTab] = useState('activities');
  const [image, setImage] = useState(null);
  const [keyword, setKeyword] = useState('');
  const [querySearch, setQuerySearch] = useState('');

  /* Search key word */
  const handleOnSearch = (inputValue) => {
    // if (inputValue.length <= 1) return;
    setQuerySearch(inputValue);
  };
  const onSearch = useCallback(
    debounce(handleOnSearch, DebounceTimeDefault),
    []
  );

  const handleOnChange = (e) => {
    setKeyword(e.target.value);
    onSearch(e.target.value);
  };

  const onClearKeyWord = () => {
    setKeyword('');
    setQuerySearch('');
  };

  const renderTabContent = () => {
    switch (tab) {
      case 'activities':
        return <Cars typeShow={typeShow} querySearch={querySearch} />;
      default:
        return null;
    }
  };

  // const handleSelectTab = (e, tabName) => {
  //   e.preventDefault();
  //   setTab(tabName);
  // };

  // const getActiveTabClass = (name) => {
  //   return tab === name ? 'active' : '';
  // };

  const onCreate = () => {
    setIsOpenCreateCar(true);
  };

  const handleClose = () => {
    setIsOpenCreateCar(false);
  };

  const handleSubmitCreateCar = async (data) => {
    try {
      const body = { ...data, image };
      const result = await dispatch(createCar({ data: body })).unwrap();
      setIsSuccess(true);
    } catch (error) {
      setIsError(true);
    }
  };

  const uploadImage = async (file) => {
    const formData = new FormData();
    formData.append('files', file);
    formData.append(
      'fileInfo',
      JSON.stringify({ alternativeText: '', caption: '', name: null })
    );
    try {
      const uploadResponse = await dispatch(upload(formData)).unwrap();
      return uploadResponse;
    } catch (error) {
      console.log(error);
    }
  };

  const getImageCar = async (file) => {
    const imageResource = await uploadImage(file);
    return setImage(!!imageResource?.length && get(imageResource, '0.id', ''));
  };

  const handleConfirmSuccess = () => {
    history.push('/admin/cars');
    setIsOpenCreateCar(false);
    window.location.reload();
    return setIsSuccess(false);
  };

  return (
    <>
      <div className="header bg-info bg-lg-transparent" id="kt_header">
        <div className="container d-flex align-items-stretch justify-content-between">
          <div className="d-none d-lg-block">
            <div className="d-flex align-items-center">
              <h3 className="text-dark fw-bolder my-1 me-5">Cars List</h3>
            </div>
          </div>
          <div className="d-lg-none">
            <Link to="/admin/cars">
              <img
                className="h-30px logo"
                alt="Logo"
                src="/images/logos/logo-compact.svg"
              />
            </Link>
          </div>

          <div className="d-lg-none mt-2 mx-10 text-center flex-row-fluid">
            <h3 className="text-white fw-bolder my-1 ellipsis-1row mx-5">
              Cars List
            </h3>
          </div>
          {tab === 'activities' && (
            <div className="d-flex align-items-center ms-auto">
              <div className="d-none d-lg-block">
                <div className="btn btn-primary" onClick={onCreate}>
                  <i className="fas fa-plus opacity-50 me-2"></i>
                  Create Car
                </div>
              </div>
            </div>
          )}
          <button
            className="btn btn-icon btn-info d-lg-none"
            data-bs-toggle="modal"
            data-bs-target="#kt_mega_menu_modal"
          >
            <span className="svg-icon svg-icon-1  ">
              <SVG src="/images/icons/menu.svg" />
            </span>
          </button>
        </div>
      </div>
      {tab === 'activities' && (
        <div
          className="bg-white shadow-sm d-lg-none py-2 px-4"
          data-kt-sticky="true"
          data-kt-sticky-name="docs-sticky-summary"
          data-kt-sticky-offset="{default: '200px'}"
          data-kt-sticky-width="100%"
          data-kt-sticky-left="auto"
          data-kt-sticky-top="55px"
          data-kt-sticky-animation="true"
          data-kt-sticky-zindex={95}
          style={{ animationDuration: '0.3s' }}
          id="kt_back"
        >
          <div className="d-flex align-items-center overflow-auto">
            <div className="ms-auto">
              <div className="btn btn-primary" onClick={onCreate}>
                <i className="fas fa-plus opacity-50 me-2"></i>
                Create Car
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="d-flex flex-column flex-column-fluid">
        <div className="toolbar pt-8 pt-lg-0" id="kt_toolbar">
          <div className="container d-flex flex-stack flex-wrap flex-sm-nowrap">
            <div className="d-flex align-items-center flex-nowrap text-nowrap overflow-auto py-1 w-100">
              {/* <button
                className={`btn btn-active-accent fw-bolder ${getActiveTabClass(
                  'activities'
                )}`}
                onClick={(e) => handleSelectTab(e, 'activities')}
              >
                Cars
              </button> */}
              <SearchCar
                keyword={keyword}
                handleOnChange={handleOnChange}
                onClearKeyWord={onClearKeyWord}
              />
              {tab === 'activities' && (
                <div className="ms-auto">
                  <ul className="nav flex-nowrap">
                    <CustomizeTooltip title="Show as grid" placement="top">
                      <li className="nav-item mb-1">
                        <button
                          className={`nav-link h-40px w-40px btn btn-custom btn-icon rounded-1 btn-view-mode me-5 ${
                            typeShow === ShowType.Grid ? 'active' : ''
                          }`}
                          onClick={() => setTypeShow(ShowType.Grid)}
                        >
                          <span className="svg-icon svg-icon-4 svg-icon-dark ">
                            <img src="/images/icons/activity-grid.svg" />
                          </span>
                        </button>
                      </li>
                    </CustomizeTooltip>
                    <CustomizeTooltip title="Show as list" placement="top">
                      <li className="nav-item mb-1">
                        <button
                          className={`nav-link h-40px w-40px btn btn-custom btn-icon rounded-1 btn-view-mode ${
                            typeShow === ShowType.List ? 'active' : ''
                          }`}
                          onClick={() => setTypeShow(ShowType.List)}
                        >
                          <span className="svg-icon svg-icon-4 svg-icon-dark">
                            <img
                              src="/images/icons/activity-grid-long.svg"
                              style={{ width: 16, height: 16 }}
                            />
                          </span>
                        </button>
                      </li>
                    </CustomizeTooltip>
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="content fs-6 d-flex flex-column-fluid" id="kt_content">
          <div className="container">{renderTabContent()}</div>
        </div>
      </div>
      <Modal open={isOpenCreateCar} onClose={handleClose}>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <CarForm
              handleClose={handleClose}
              onSubmit={handleSubmitCreateCar}
              getFile={getImageCar}
              imageCar={image}
            />
          </div>
        </div>
      </Modal>
      {isSuccess && (
        <ModalConfirm
          isOpen={!!isSuccess}
          handleClose={() => setIsSuccess(false)}
          confirm={handleConfirmSuccess}
          modalTitle={'Create Car Successfully'}
          modalNotes={'This action was created!'}
          CustomIcon={CheckCircleIcon}
        />
      )}

      {isError && (
        <ModalConfirm
          isOpen={!!isError}
          handleClose={() => setIsError(false)}
          confirm={() => setIsError(false)}
          modalTitle={'Create Activity Failed'}
          modalNotes={'This action can not be undone!'}
        />
      )}
    </>
  );
};

export default CarList;
