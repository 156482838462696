import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import LoadingButton from '@mui/lab/LoadingButton';
import * as yup from 'yup';
import BannerAuth from '../../components/BannerAuth';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { get } from 'lodash';
import {
  selectAcessToken,
  login,
  triggerSentMail,
} from '../../../features/user/auth';
import FooterAuth from '../../components/FooterAuth';

const Login = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isComeFromLogin = get(history, 'location.pathname', '');
  const accessToken = useSelector(selectAcessToken);

  useEffect(() => {
    if (accessToken) {
      history.push('/admin/cars');
    }
  }, [accessToken]);

  const schema = yup.object().shape({
    identifier: yup
      .string()
      .email('Please enter a valid email')
      .required('Email is required'),
    password: yup.string().required('Password is required'),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      identifier: '',
      password: '',
    },
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    dispatch(login(data));
    triggerSentMail(false);
  };

  return (
    <BannerAuth>
      <div className="login-content flex-lg-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden py-10 px-10 p-lg-7 mx-auto mw-450px w-100">
        <div className="d-flex flex-column-fluid flex-center py-10">
          <form
            className="form w-100"
            noValidate="novalidate"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="pb-5 pb-lg-15">
              <h3 className="fw-bolder text-dark display-6">
                Welcome to Lance East
              </h3>
            </div>
            {isComeFromLogin === '/login-confirmation' && (
              <div className="alert alert-info d-flex align-items-center p-5 mb-10">
                <span className="svg-icon svg-icon-2hx svg-icon-info me-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      opacity="0.3"
                      d="M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z"
                      fill="black"
                    ></path>
                    <path
                      d="M10.5606 11.3042L9.57283 10.3018C9.28174 10.0065 8.80522 10.0065 8.51412 10.3018C8.22897 10.5912 8.22897 11.0559 8.51412 11.3452L10.4182 13.2773C10.8099 13.6747 11.451 13.6747 11.8427 13.2773L15.4859 9.58051C15.771 9.29117 15.771 8.82648 15.4859 8.53714C15.1948 8.24176 14.7183 8.24176 14.4272 8.53714L11.7002 11.3042C11.3869 11.6221 10.874 11.6221 10.5606 11.3042Z"
                      fill="black"
                    ></path>
                  </svg>
                </span>
                <div className="d-flex flex-column">
                  <h4 className="mb-1 text-info">
                    We emailed you a temporary password
                  </h4>
                  <span>
                    We emailed you your new password. Please check your inbox.
                    You can change your password once you login.
                  </span>
                </div>
              </div>
            )}
            <div className="fv-row mb-10">
              <label className="form-label fs-6 fw-bolder text-dark">
                Email
              </label>
              <input
                className="form-control form-control-lg form-control-solid bg-white"
                type="text"
                name="identifier"
                autoComplete="off"
                {...register('identifier')}
              />
              <span className="invalid-feedback">
                {errors['identifier']?.message}
              </span>
            </div>
            <div className="fv-row mb-10">
              <div className="d-flex justify-content-between mt-n5">
                <label className="form-label fs-6 fw-bolder text-dark pt-5">
                  Password
                </label>
                <Link
                  to="/forgot-password"
                  className="text-info fs-6 fw-bolder text-hover-info pt-5"
                  underline="none"
                >
                  Forgot Password ?
                </Link>
              </div>
              <input
                className="form-control form-control-lg form-control-solid bg-white"
                type="password"
                name="password"
                autoComplete="off"
                {...register('password')}
              />
              <span className="invalid-feedback">
                {errors['password']?.message}
              </span>
            </div>
            <div className="pb-lg-0 pb-5">
              <p>
                By using Lance East you have read and agree to our{' '}
                <Link
                  className="cursor-pointer"
                  to="/term-condition"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms
                </Link>{' '}
                and you have read our{' '}
                <Link
                  className="cursor-pointer"
                  to="/privacy-policy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Data Use Policy
                </Link>
                .
              </p>
              <LoadingButton
                className="btn btn-info fw-bolder fs-6 px-8 py-4 my-3 me-3"
                type="submit"
                variant="contained"
              >
                Sign In
              </LoadingButton>
            </div>
          </form>
        </div>
        <FooterAuth />
      </div>
    </BannerAuth>
  );
};

export default Login;
