import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Trips from '../../api/admin/trips';
import { parseError } from '../../app/utils/helper';

export const fetchTrips = createAsyncThunk(
  'trip/fetchTrips',
  async (queryParams, { rejectWithValue }) => {
    try {
      const response = await Trips.fetchTrips(queryParams);
      return response.data;
    } catch (error) {
      return rejectWithValue(parseError(error));
    }
  }
);

export const fetchTripById = createAsyncThunk(
  'trip/fetchTripById',
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await Trips.fetchTripById(id, {});
      return response.data;
    } catch (error) {
      return rejectWithValue(parseError(error));
    }
  }
);

export const updateTripById = createAsyncThunk(
  'trip/updateTripById',
  async ({ id, body }, { rejectWithValue }) => {
    try {
      await Trips.updateTripById(id, body);
    } catch (error) {
      return rejectWithValue(parseError(error));
    }
  }
);

const tripSlice = createSlice({
  name: 'trip',
  initialState: {
    trips: [],
    total: 0,
    trip: undefined,
  },
  reducers: {
    resetTripList: (state, action) => {
      state.trips = [];
      state.total = 0;
    },
    resetTrip: (state, action) => {
      state.trip = undefined;
    },
  },
  extraReducers: {
    [fetchTrips.fulfilled]: (state, action) => {
      state.trips = action.payload.data;
      state.total = action.payload.meta.pagination.total;
    },
    [fetchTripById.fulfilled]: (state, action) => {
      state.trip = action.payload.data;
    },
  },
});

export default tripSlice.reducer;
