import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Location from '../../api/admin/location';
import { parseError } from '../../app/utils/helper';

export const fetchLocations = createAsyncThunk(
  'admin/fetch-location',
  async (_, { rejectWithValue }) => {
    try {
      // const response = await Location.fetchLocations();
      return [];
    } catch (error) {
      return rejectWithValue(parseError(error));
    }
  }
);

export const createLocation = createAsyncThunk(
  'admin/create-location',
  async (body, { rejectWithValue }) => {
    try {
      const { data } = await Location.createLocation(body);
      return { data, message: 'Create location successfully' };
    } catch (error) {
      return rejectWithValue(parseError(error));
    }
  }
);

export const updateLocation = createAsyncThunk(
  'admin/update-location',
  async ({ id, body }, { rejectWithValue }) => {
    try {
      const { data } = await Location.updateLocation(id, body);
      return { data, message: 'Update location successfully' };
    } catch (error) {
      return rejectWithValue(parseError(error));
    }
  }
);

export const deleteLocation = createAsyncThunk(
  'admin/delete-location',
  async ({ id }, { rejectWithValue }) => {
    try {
      const { data } = await Location.deleteLocation(id);
      return { data, message: 'Delete location successfully' };
    } catch (error) {
      return rejectWithValue(parseError(error));
    }
  }
);

const LocationSlice = createSlice({
  name: 'location',
  initialState: {
    locations: [],
    locationCreated: null,
    locationUpdated: null,
    locationDeleted: null,
  },
  reducers: {},
  extraReducers: {
    [fetchLocations.fulfilled]: (state, action) => {
      state.locations = action.payload;
    },
    [createLocation.fulfilled]: (state, action) => {
      state.locationCreated = action.payload.data;
    },
    [updateLocation.fulfilled]: (state, action) => {
      state.locationUpdated = action.payload.data;
    },
    [deleteLocation.fulfilled]: (state, action) => {
      state.locationDeleted = action.payload.data;
    },
  },
});

export default LocationSlice.reducer;
