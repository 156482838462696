import React from 'react';
import { Link } from 'react-router-dom';
import { get } from 'lodash';
import SVG from 'react-inlinesvg';

import { formatDate } from '../../../../../utils/helper';
import InfiniteScroll from 'react-infinite-scroll-component';

function ActivityGrid({ activities, handleLoadMore }) {
  return (
    <>
      {/* <div className="alert alert-dismissible bg-light-info d-flex p-5 my-lg-5">
        <i className="fas fa-info-circle text-info me-5 fs-1"></i>
        <div className="d-flex flex-column">
          <h5 className="mb-1">You can redo activity</h5>
          <span className="text-gray-500">
            Enjoyed doing an activity. Feel free to do the same activity again.
          </span>
        </div>
        <button
          className="position-sm-relative m-2 m-sm-0 btn btn-icon ms-sm-auto align-self-center"
          type="button"
          data-bs-dismiss="alert"
        >
          <span className="svg-icon svg-icon-2x svg-icon-info ">
            <SVG src="/images/icons/close.svg" />
          </span>
        </button>
      </div> */}
      <InfiniteScroll
        dataLength={activities.length}
        next={handleLoadMore}
        hasMore={true}
      >
        <div className="tab-pane fade show active" role="tabpanel">
          <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3">
            {activities.map((activity, index) => (
              <div className="col p-5" key={index}>
                <div className="card card-stretch">
                  <div className="card-body p-0 border-0 ribbon ribbon-start ribbon-clip">
                    <Link
                      to={`/client/activity/${get(activity, 'activity.id')}`}
                    >
                      <div
                        className="bgi-no-repeat bgi-size-cover rounded-top min-h-200px"
                        style={{
                          backgroundImage: `url(${get(
                            activity,
                            'activity.activity_picture.url'
                          )})`,
                        }}
                      ></div>
                    </Link>
                    <div className="ribbon-label ribbon-topic">
                      {get(activity, 'activity.code')}
                      <span className="ribbon-inner bg-warning" />
                    </div>
                  </div>
                  <div className="card-body px-5">
                    <h4 className="fw-bold">
                      <div
                        className="ellipsis-2rows text-dark"
                        href="./activities-detail.html"
                      >
                        {get(activity, 'activity.title')}
                      </div>
                    </h4>
                    <p className="description text-gray-500 fs-14 py-4">
                      {get(activity, 'activity.summary')}
                    </p>
                    {get(activity, 'activity.topics') && (
                      <div className="d-flex overflow-auto">
                        {get(activity, 'activity.topics').map(
                          (topic, index) => (
                            <span
                              key={index}
                              className="badge badge-pill badge-light-info me-3 mb-1 fw-normal"
                            >
                              {topic.name}
                            </span>
                          )
                        )}
                      </div>
                    )}
                  </div>
                  <div className="px-5 pb-5">
                    <div className="d-flex flex-center wrap-progress">
                      <div className="progress h-6px w-100 bg-secondary">
                        <div
                          className="progress-bar"
                          role="progressbar"
                          style={{
                            width: `${
                              (get(activity, 'total_answer') * 100) /
                              get(activity, 'total_question')
                            }%`,
                          }}
                          aria-valuenow={40}
                          aria-valuemin={0}
                          aria-valuemax={100}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="p-5 d-flex flex-stack text-gray-500">
                    <small className="ellipsis-1row">
                      {`Assigned By: ${get(
                        activity,
                        'assigned_by.first_name'
                      )} ${get(activity, 'assigned_by.surname')}`}
                    </small>
                    <small>
                      {formatDate(get(activity, 'activity.created_at'))}
                    </small>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </InfiniteScroll>
    </>
  );
}

export default ActivityGrid;
