import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AdminActivity from '../../api/admin/activity';
import { parseError } from '../../app/utils/helper';
import { push } from 'connected-react-router';

export const fetchActivities = createAsyncThunk(
  'adminActivity/fetchActivities',
  async (queryParams, { rejectWithValue }) => {
    try {
      const response = await AdminActivity.fetchActivities(queryParams);
      return response.data;
    } catch (error) {
      return rejectWithValue(parseError(error));
    }
  }
);

export const createActivity = createAsyncThunk(
  'adminActivity/createActivity',
  async (body, { rejectWithValue }) => {
    try {
      const response = await AdminActivity.create(body);
      return response.data;
    } catch (error) {
      return rejectWithValue(parseError(error));
    }
  }
);

export const fetchActivityById = createAsyncThunk(
  'adminActivity/fetchActivityById',
  async (activityId, { rejectWithValue }) => {
    try {
      const response = await AdminActivity.fetchActivityById(activityId);
      return response.data;
    } catch (error) {
      return rejectWithValue(parseError(error));
    }
  }
);

export const fetchChapterById = createAsyncThunk(
  'adminActivity/fetchChapterById',
  async (chapterId, { rejectWithValue }) => {
    try {
      const response = await AdminActivity.fetchChapterById(chapterId);
      return response.data;
    } catch (error) {
      return rejectWithValue(parseError(error));
    }
  }
);

export const fetchQuestionById = createAsyncThunk(
  'adminActivity/fetchQuestionById',
  async (questionId, { rejectWithValue }) => {
    try {
      const response = await AdminActivity.fetchQuestionById(questionId);
      return response.data;
    } catch (error) {
      return rejectWithValue(parseError(error));
    }
  }
);

export const searchActivitiesByClientId = createAsyncThunk(
  'adminActivity/searchActivitiesByClientId',
  async ({ clientId, queryParams }, { rejectWithValue }) => {
    try {
      const response = await AdminActivity.searchActivitiesByClientId(
        clientId,
        queryParams
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(parseError(error));
    }
  }
);

export const deleteActivity = createAsyncThunk(
  'adminActivity/deleteActivity',
  async (activityId, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await AdminActivity.deleteActivity(activityId);
      dispatch(push('/admin/activity-library'));
      return { data, message: 'Delete activity successfully' };
    } catch (error) {
      return rejectWithValue(parseError(error));
    }
  }
);

export const deleteChapter = createAsyncThunk(
  'adminActivity/deleteChapter',
  async (chapterId, { rejectWithValue }) => {
    try {
      const { data } = await AdminActivity.deleteChapter(chapterId);
      return { data, message: 'Delete chapter successfully' };
    } catch (error) {
      return rejectWithValue(parseError(error));
    }
  }
);

export const deleteQuestion = createAsyncThunk(
  'adminActivity/deleteQuestion',
  async (questionId, { rejectWithValue }) => {
    try {
      const { data } = await AdminActivity.deleteQuestion(questionId);
      return { data, message: 'Delete question successfully' };
    } catch (error) {
      return rejectWithValue(parseError(error));
    }
  }
);

export const deleteAnswer = createAsyncThunk(
  'adminActivity/deleteAnswer',
  async (answerId, { rejectWithValue }) => {
    try {
      const { data } = await AdminActivity.deleteAnswer(answerId);
      return { data, message: 'Delete answer successfully' };
    } catch (error) {
      return rejectWithValue(parseError(error));
    }
  }
);

const activitiesSlice = createSlice({
  name: 'adminActivity',
  initialState: {
    activities: [],
    createActivity: null,
    activityById: null,
    questionById: null,
    chapterById: null,
  },
  reducers: {
    resetActivityList: (state, action) => {
      state.activities = [];
    },
  },
  extraReducers: {
    [fetchActivities.fulfilled]: (state, action) => {
      state.activities = action.payload.activities;
    },
    [createActivity.fulfilled]: (state, action) => {
      state.createActivity = action.payload;
    },
    [fetchActivityById.fulfilled]: (state, action) => {
      state.activityById = action.payload;
    },
    [fetchChapterById.fulfilled]: (state, action) => {
      state.chapterById = action.payload;
    },
    [fetchQuestionById.fulfilled]: (state, action) => {
      state.questionById = action.payload;
    },
  },
});

export const { resetActivityList, resetSearchActivity } =
  activitiesSlice.actions;

export const selectActivityList = (state) => state.adminActivity.activities;

export default activitiesSlice.reducer;
