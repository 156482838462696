import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import {
  fetchAssignedActivityById,
  redoActivity,
  selectAssignedActivityDetail,
} from '../../../../../../features/client/activity';
import useProgress from '../../../../../../hooks/use-progress';
import SVG from 'react-inlinesvg';
import { useDispatch } from 'react-redux';
import { formatDate } from '../../../../../utils/helper';
import { get } from 'lodash';
import { selectAuthUser } from '../../../../../../features/user/auth';

const Intro = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();

  const activityDetail = useSelector(selectAssignedActivityDetail);
  const authUser = useSelector(selectAuthUser);
  // const [progress] = useProgress(activityDetail);

  useEffect(() => {
    if (id && authUser) {
      const data = {
        clientId: authUser.id,
        id,
      };
      dispatch(fetchAssignedActivityById(data));
    }
  }, [id, authUser]);

  const redo = async () => {
    if (get(activityDetail, 'completed_at') !== null) {
      await dispatch(redoActivity(id));
    }
    history.push(`/client/activity/${id}/chapters`);
  };

  return (
    <>
      <div className="d-flex flex-column flex-column-fluid">
        <div className="content fs-6 d-flex flex-column-fluid" id="kt_content">
          <div className="container">
            <div className="row">
              <div className="col-xl-7 col-lg-9 col-md-10 mx-auto">
                <div className="card card-stretch">
                  <div className="card-body py-md-10 py-lg-20 text-center">
                    <h1 className="w-md-400px mx-auto text-center text-gray-800 h2 mb-12">
                      {get(activityDetail, 'activity.title')}
                    </h1>
                    <div
                      className="bgi-no-repeat bgi-size-cover rounded min-h-200px mw-350px mx-auto ribbon ribbon-start ribbon-clip"
                      style={{
                        backgroundImage: `url(${get(
                          activityDetail,
                          'activity.activity_picture.url'
                        )})`,
                      }}
                    >
                      <div className="ribbon-label ribbon-topic">
                        {get(activityDetail, 'activity.code')}
                        <span className="ribbon-inner bg-warning"></span>
                      </div>
                    </div>
                    <div className="d-block mw-300px mx-auto my-10">
                      <div className="d-flex flex-center wrap-progress">
                        <div className="progress h-6px w-100 bg-secondary">
                          <div
                            className="progress-bar"
                            role="progressbar"
                            style={{
                              width: `${
                                (get(activityDetail, 'total_answer') * 100) /
                                get(activityDetail, 'total_question')
                              }%`,
                            }}
                            aria-valuenow="40"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                      </div>
                    </div>
                    <div className="wrap-text w-100 w-md-75 m-auto">
                      <p>{get(activityDetail, 'activity.description')}</p>
                    </div>
                    <div
                      className="mt-8 btn btn-primary w-200px"
                      onClick={redo}
                    >
                      {`${
                        get(activityDetail, 'completed_at') !== null
                          ? 'Redo'
                          : 'Start'
                      }`}
                      <span className="svg-icon svg-icon-4  me-0 ms-2 opacity-50">
                        <SVG src="/images/icons/right.svg" />
                      </span>
                    </div>
                    <p className="mt-10 text-gray-400 fs-7">
                      Helps build the following S.T.R.O.N.G compentencies:
                    </p>
                    {get(activityDetail, 'activity.topics') && (
                      <div className="d-flex justify-content-center">
                        <div className="d-flex overflow-auto">
                          {get(activityDetail, 'activity.topics').map(
                            (topic, index) => (
                              <span
                                key={index}
                                className="badge badge-pill badge-light-info me-3 mb-1 fw-normal"
                              >
                                {topic.name}
                              </span>
                            )
                          )}
                        </div>
                      </div>
                    )}
                    <div className="d-flex justify-content-center my-6">
                      <small className="text-gray-500 me-3 fs-9">
                        {`Assigned By: ${get(
                          activityDetail,
                          'assigned_by.first_name'
                        )} ${get(activityDetail, 'assigned_by.surname')}`}
                      </small>
                      <small className="text-gray-500 ms-3 fs-9">
                        {formatDate(get(activityDetail, 'created_at'))}
                      </small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Intro;
