import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { PasswordRegex } from '../../utils/constants';

function ChangePWForm({ onChangePW, onCancel }) {
  const schema = yup.object().shape({
    current_password: yup
      .string()
      .matches(
        PasswordRegex,
        'Please ensure your password contains at least 6 characters and includes one capital letter and one non alphanumeric character'
      )
      .required('Current password is required'),
    new_password: yup
      .string()
      .matches(
        PasswordRegex,
        'Please ensure your password contains at least 6 characters and includes one capital letter and one non alphanumeric character'
      )
      .required('New password is required'),
    // re_password: yup
    //   .string()
    //   .matches(
    //     PasswordRegex,
    //     'Please ensure your password contains at least 6 characters and includes one capital letter and one non alphanumeric character'
    //   )
    //   .required('Retype new password is required')
    //   .oneOf(
    //     [yup.ref('new_password'), null],
    //     'The password you have entered do not match. Please try again.'
    //   ),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      new_password: '',
      // re_password: '',
    },
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    const { re_password, ...transformData } = data;
    onChangePW(transformData);
  };

  return (
    <form
      className="form w-100"
      noValidate="novalidate"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="fv-row mb-8 mt-2">
        <p className="mb-0 text-warning fw-bolder">
          Minimum 6 characters, including one capital letter and one non
          alphanumeric character.
        </p>
      </div>

      <div className="fv-row mb-8 toggle-disabled">
        <div className="d-flex justify-content-between mt-n7">
          <label className="form-label fs-6 fw-bolder pt-5">
            Current password
          </label>
        </div>
        <input
          className="form-control form-control-lg form-control-solid"
          type="password"
          name="passwordCurrent"
          autoComplete="off"
          {...register('current_password')}
        />
        <span className="invalid-feedback">
          {errors['current_password']?.message}
        </span>
      </div>

      <div className="fv-row mb-8 toggle-disabled">
        <div className="d-flex justify-content-between mt-n7">
          <label className="form-label fs-6 fw-bolder pt-5">New Password</label>
        </div>
        <input
          className="form-control form-control-lg form-control-solid"
          type="password"
          name="passwordNew"
          autoComplete="off"
          {...register('new_password')}
        />
        <span className="invalid-feedback">
          {errors['new_password']?.message}
        </span>
      </div>
      {/* <div className="fv-row mb-8 toggle-disabled">
        <div className="d-flex justify-content-between mt-n7">
          <label className="form-label fs-6 fw-bolder pt-5">
            Re-type Password
          </label>
        </div>
        <input
          className="form-control form-control-lg form-control-solid"
          type="password"
          name="passwordRetype"
          autoComplete="off"
          {...register('re_password')}
        />
        <span className="invalid-feedback">
          {errors['re_password']?.message}
        </span>
      </div> */}
      <div className="d-flex pb-lg-0 toggle-disabled mx-n2">
        <button
          className="btn fw-bolder fs-6 p-4 mx-2 w-50 btn-info"
          type="submit"
        >
          Save Change
        </button>
        <button
          className="btn fw-bolder fs-6 p-4 mx-2 w-50 btn-light-info"
          type="button"
          onClick={onCancel(reset)}
        >
          Cancel
        </button>
      </div>
    </form>
  );
}

export default ChangePWForm;
