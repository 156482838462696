import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Incident from '../../api/admin/incident';
import { parseError } from '../../app/utils/helper';

export const fetchIncidents = createAsyncThunk(
  'incident/fetchIncidents',
  async (queryParams, { rejectWithValue }) => {
    try {
      const response = await Incident.fetchIncidents(queryParams);
      return response.data;
    } catch (error) {
      return rejectWithValue(parseError(error));
    }
  }
);

export const updateIncidentById = createAsyncThunk(
  'incident/updateIncidentById',
  async ({ id, body }, { rejectWithValue }) => {
    try {
      const response = await Incident.updateIncidentById(id, body);
      return response.data;
    } catch (error) {
      return rejectWithValue(parseError(error));
    }
  }
);

export const setActivePage = createAsyncThunk(
  'incident/setActivePage',
  (data) => {
    return data;
  }
);

export const setPageSize = createAsyncThunk('incident/setPageSize', (data) => {
  return data;
});

const incidentSlice = createSlice({
  name: 'incident',
  initialState: {
    incidents: [],
    activePage: 1,
    pageSize: 10,
    total: 0,
  },
  reducers: {
    resetIncidentList: (state, action) => {
      state.incidents = [];
    },
  },
  extraReducers: {
    [fetchIncidents.fulfilled]: (state, action) => {
      state.incidents = action.payload.data;
      state.activePage = action.payload.meta?.pagination?.page;
      state.pageSize = action.payload.meta?.pagination?.pageSize;
      state.total = action.payload.meta?.pagination?.total;
    },
    [setActivePage.fulfilled]: (state, action) => {
      state.activePage = action.payload;
    },
    [setPageSize.fulfilled]: (state, action) => {
      state.pageSize = action.payload;
    },
  },
});

export default incidentSlice.reducer;
