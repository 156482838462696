import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import Modal from '@mui/material/Modal';
import CarCard from './CarCard';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSortableData } from '../../../../../../hooks/useSortableData';
import ModalConfirm from '../../../../../components/Modal/ModalConfirm';
import { get } from 'lodash';
import { fetchCars, updateCar } from '../../../../../../features/admin/cars';
import useNotyf from '../../../../../../hooks/useNotyf';
import CarForm from '../../CreateCarForm/form';
import Loading from '../../../../../components/Loading';
import { upload } from '../../../../../../features/upload';

function CarGrid({ cars, onClickTopic, handleLoadMore, setCarsList }) {
  const dispatch = useDispatch();
  const notify = useNotyf();
  const [showConfirm, setShowConfirm] = useState(false);
  const [isOpenUpdateCar, setIsOpenUpdateCar] = useState(false);
  const [image, setImage] = useState(null);
  const [carSelect, setCarSelect] = useState({});
  const { items: dataGrid, requestSort, sortConfig } = useSortableData(cars);

  const handleShowConfirm = (car) => {
    if (get(car, 'attributes.incidentReport')) {
      setCarSelect(car);
      setShowConfirm(true);
    }
  };

  const handleConfirm = async () => {
    try {
      if (get(carSelect, 'id')) {
        const dataUpdate = {
          id: carSelect.id,
          body: {
            data: {
              id: carSelect.id,
              incidentReport: false,
            },
          },
        };
        const { data, message } = await dispatch(
          updateCar(dataUpdate)
        ).unwrap();

        //update new list
        const newListCar = [...cars].map((item) => {
          if (item.id === carSelect.id) {
            let newItem = { ...item };
            let newAttributes = { ...item.attributes, incidentReport: false };
            newItem.attributes = newAttributes;
            return newItem;
          }
          return item;
        });
        setCarsList(newListCar);
        notify.success('Car updated successfully');
        setShowConfirm(false);
      }
    } catch (error) {
      console.log(error);
      setShowConfirm(false);
      notify.error('Error when turn off incident report car');
    }
  };

  const handleEditCar = (data) => () => {
    setIsOpenUpdateCar(true);
    setCarSelect(data);
  };

  const handleClose = () => {
    setIsOpenUpdateCar(false);
  };

  const handleSubmitUpdateCar = async (dataForm) => {
    const newDataForm = { ...dataForm, image };
    const dataUpdate = {
      id: carSelect.id,
      body: {
        data: image ? newDataForm : dataForm,
      },
    };
    try {
      const { data, message } = await dispatch(updateCar(dataUpdate)).unwrap();
      notify.success('Car updated successfully');
      setIsOpenUpdateCar(false);
      window.location.reload();
    } catch (error) {
      setShowConfirm(false);
      setIsOpenUpdateCar(false);
      notify.error('Error when turn off incident report car');
    }
  };

  const uploadImage = async (file) => {
    const formData = new FormData();
    formData.append('files', file);
    formData.append(
      'fileInfo',
      JSON.stringify({ alternativeText: '', caption: '', name: null })
    );
    try {
      const uploadResponse = await dispatch(upload(formData)).unwrap();
      return uploadResponse;
    } catch (error) {
      console.log(error);
    }
  };

  const getImageCar = async (file) => {
    const imageResource = await uploadImage(file);
    return setImage(!!imageResource?.length && get(imageResource, '0.id', ''));
  };

  return (
    <>
      <Loading />
      <InfiniteScroll
        dataLength={dataGrid.length}
        next={handleLoadMore}
        hasMore={true}
      >
        <div className="row row-cols-sm-2 row-cols-md-3" style={{ '--bs-gutter-x': 0 }}>
          {dataGrid?.length > 0 &&
            dataGrid.map((car, index) => (
              <div className="p-5" key={`car_${index}`}>
                <CarCard
                  car={car}
                  onClickTopic={onClickTopic}
                  handleShowConfirm={handleShowConfirm}
                  handleEditCar={handleEditCar}
                  onDelete={() => setCarsList(cars.filter((item) => item !== car))}
                />
              </div>
            ))}
        </div>
      </InfiniteScroll>

      <ModalConfirm
        isOpen={showConfirm}
        modalTitle="Incident Report"
        modalNotes="Are you sure you want to turn off incident report on this car?"
        handleClose={() => setShowConfirm(false)}
        confirm={handleConfirm}
      />
      <Modal open={isOpenUpdateCar} onClose={handleClose}>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <CarForm
              handleClose={handleClose}
              onSubmit={handleSubmitUpdateCar}
              getFile={getImageCar}
              imageCar={image}
              carSelect={carSelect?.attributes}
              id={carSelect?.id}
              onDelete={() => setCarsList(cars.filter((item) => item !== carSelect))}
            />
          </div>
        </div>
      </Modal>
    </>
  );
}

export default CarGrid;
