import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import FAQ from '../../api/client/faq';
import { parseError } from '../../app/utils/helper';

export const fetchFAQ = createAsyncThunk(
  'clientFAQ/fetchFAQ',
  async (queryParams, { rejectWithValue }) => {
    try {
      const { data } = await FAQ.fetchFAQ(queryParams);
      return data;
    } catch (error) {
      return rejectWithValue(parseError(error));
    }
  }
);

const initialState = {
  listFAQ: [],
};

const FAQSlice = createSlice({
  name: 'clientFAQ',
  initialState,
  extraReducers: {
    [fetchFAQ.fulfilled]: (state, action) => {
      state.listFAQ = action.payload;
    },
  },
});

export default FAQSlice.reducer;
