import React, { useState, useEffect, useRef } from 'react';
import SVG from 'react-inlinesvg';
import Pagination from '../../../../components/Pagination';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchIncidents,
  setActivePage,
  updateIncidentById,
  setPageSize,
} from '../../../../../features/admin/incident';
import { Link } from 'react-router-dom';
import ModalConfirm from '../../../../components/Modal/ModalConfirm';
import DeleteButton from '../../../../components/DeleteButton';

const IncidentList = () => {
  const [isShowModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const [keyword, setKeyword] = useState('');
  const incidents = useSelector((state) => state.incident.incidents);
  const activePage = useSelector((state) => state.incident.activePage);
  const pageSize = useSelector((state) => state.incident.pageSize);
  const total = useSelector((state) => state.incident.total);
  const selected = useRef(0);

  useEffect(() => {
    getListIncidents();
  }, [pageSize, activePage, keyword]);

  const getListIncidents = () => {
    const queryParams = {
      'pagination[page]': activePage,
      'pagination[pageSize]': pageSize,
      'populate[trip][populate][0]': 'authorisedBy',
      sort: 'createdAt:desc',
    };
    if (keyword) {
      queryParams['filters[locationName][$contains]'] = keyword;
    }
    dispatch(fetchIncidents(queryParams));
  };

  const onPageClick = (page) => {
    dispatch(setActivePage(page));
  };

  const onChangeSize = (size) => {
    dispatch(setPageSize(size));
  };

  const getAuthorisedUser = (data) => {
    return data?.attributes?.name;
  };

  const renderIncidentList = () => {
    return incidents.map((item, index) => {
      return (
        <tr key={index}>
          <td>
            <div className="car-trip">{item?.id}</div>
          </td>
          <td>{item?.attributes?.createdAt}</td>
          <td>{item?.attributes?.time}</td>
          <td>
            <div className="car-trip"><Link style={{ color: "blue" }} to={`/admin/trip/${item?.attributes?.trip?.data?.id}`}>{item?.attributes?.trip?.data?.id}</Link></div>
          </td>
          <td>{item?.attributes?.locationName}</td>
          <td></td>
          <td>
            <div className="car-trip">
              {getAuthorisedUser(
                item?.attributes?.trip?.data?.attributes?.authorisedBy?.data
              )}
            </div>
          </td>
          <td><DeleteButton onClick={() => {
            setShowModal(true);
            selected.current = item.id;
          }} /></td>
        </tr>
      );
    });
  };

  return (
    <div className="d-flex flex-column flex-column-fluid">
      <div className="container mb-5">
        <div className="d-flex align-items-center position-relative my-1">
          <span className="svg-icon svg-icon-2 svg-icon-info ms-6 z-index-1">
            <SVG src="/images/icons/search.svg" />
          </span>
          <input
            className="form-control form-control-solid w-100 mw-350px ps-15 ms-n12 bg-white"
            type="text"
            data-kt-docs-table-filter="search"
            placeholder="Search (location)"
            value={keyword}
            onChange={(e) => setKeyword(e.target.value)}
          />
        </div>
      </div>
      <div className="content fs-6 d-flex flex-column-fluid" id="kt_content">
        <div className="container">
          <div className="card">
            <div className="card-body">
              <div
                id="kt_datatable_example_2_wrapper"
                className="dataTables_wrapper dt-bootstrap4 no-footer"
              >
                <div className="mobile-table">
                  <table
                    className="table table-striped table-row-bordered gy-5 gs-7 w-100 align-middle"
                    id="kt_datatable_example_2"
                  >
                    <thead>
                      <tr className="fw-bold fs-6 text-gray-800">
                        <th className="min-w-200px sorting sorting_asc">
                          IncidentId
                        </th>
                        <th className="min-w-100px ">Date</th>
                        <th className="min-w-200px ">Time</th>
                        <th className="min-w-100px ">TripId</th>
                        <th className="min-w-100px ">Location</th>
                        <th className="min-w-100px ">User</th>
                        <th className="min-w-125px ">Authorised By</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>{renderIncidentList()}</tbody>
                  </table>
                </div>
                <Pagination
                  limit={pageSize}
                  onChangeSize={onChangeSize}
                  onPageClick={onPageClick}
                  total={total}
                />
                <ModalConfirm
                  isOpen={isShowModal}
                  handleClose={() => setShowModal(false)}
                  confirm={async () => {
                    setShowModal(false);
                    await dispatch(updateIncidentById({
                      id: selected.current,
                      body: {
                        data: {
                          publishedAt: null
                        }
                      },
                    }));
                    onPageClick(0);
                  }}
                  modalTitle={'Are you sure you want to do this?'}
                  modalNotes={'This action can not be undone'}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IncidentList;
