import http from '../../services/http-common';

export default class AssignedActivity {
  static fetchAssignedActivities(activityId = null) {
    return http.get(
      activityId
        ? `/assigned-activities?activity=${activityId}`
        : '/assigned-activities'
    );
  }

  static fetchAssignedUserByName(queryParams) {
    return http.get('/assigned-activities', queryParams);
  }

  static createAssignActivity(data = {}) {
    return http.post('/assigned-activities', data);
  }

  static deleteAssignActivity(id) {
    return http.delete(`/assigned-activities/${id}`);
  }
}
