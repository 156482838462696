import dayjs from 'dayjs';
import { filter } from 'lodash';

export const pulseStatus = {
  Struggling: 1,
  Ok: 2,
  Well: 3,
};

export function getOptionChart(datasets) {
  const colors = ['#5710B2', '#00B2FF', '#FFC700', '#F1416C'];
  const total = 100;
  const labels = datasets.map((d) => d.name);

  const series = datasets.map((d) =>
    Math.round((d.completed * 100) / total, 0)
  );

  const options = {
    chart: {
      height: 265,
      type: 'radialBar',
    },
    colors,
    plotOptions: {
      radialBar: {
        hollow: {
          size: '45%',
        },
        dataLabels: {
          name: {
            fontSize: '12px',
            offsetY: 15,
            fontWeight: 500,
            formatter: function (w) {
              return w;
            },
          },
          value: {
            fontSize: '18px',
            fontWeight: 800,
            offsetY: -20,
            formatter: function (val, opt) {
              var index = series.findIndex((s) => s === parseInt(val));
              if (index < 0) index = 0;
              //-   return  datasets[index].completed // + ' of ' + datasets[index].total
              return datasets[index].completed;
            },
          },
          total: {
            show: true,
            label: labels[0],
            color: colors[0],
            fontSize: '12px',
            fontWeight: 500,
            formatter: function (w) {
              return datasets[0].completed; //+ ' of ' + datasets[0].total
            },
          },
        },
        track: {
          background: '#e7e7e7',
        },
      },
    },
    stroke: {
      lineCap: 'round',
    },
    labels,
    responsive: [
      {
        breakpoint: 1025,
        options: {
          chart: {
            height: 250,
          },
        },
      },
      {
        breakpoint: 414,
        options: {
          chart: {
            height: 220,
          },
        },
      },
    ],
  };

  return options;
}

export function transformDataPulseChart(chartData) {
  const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const categories = [];
  const data = [];

  //filter to remove sunday and saturday
  chartData = filter(chartData, function (o) {
    if (
      days[dayjs(o.date).get('day')] === 'Sat' ||
      days[dayjs(o.date).get('day')] === 'Sun'
    ) {
      return false;
    }
    return true;
  });

  let result = [];
  for (var i = 0; i < chartData.length; i++) {
    const day = dayjs(chartData[i].date);
    if (i === 0) {
      result.push({ date: day, count: pulseStatus[chartData[i].status] });
    } else {
      if (day.isSame(result[result.length - 1].date, 'day')) {
        continue;
      } else {
        result.push({ date: day, count: pulseStatus[chartData[i].status] });
      }
    }
  }

  result.forEach((item) => {
    data.push(item.count);
    categories.push([days[item.date.get('day')], item.date.get('date')]);
  });

  return { data, categories };
}
