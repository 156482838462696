import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ClientDashboard from '../../api/client/dashboard';
import { parseError } from '../../app/utils/helper';

export const fetchClientDashboardInfo = createAsyncThunk(
  'clientDashboard/info',
  async (_, { rejectWithValue }) => {
    try {
      const response = await ClientDashboard.fetchClientDashboardInfo();
      return response.data;
    } catch (error) {
      return rejectWithValue(parseError(error));
    }
  }
);

export const checkDailyPulse = createAsyncThunk(
  'clientDashboard/checkDailyPulse',
  async (_, { rejectWithValue }) => {
    try {
      const response = await ClientDashboard.checkDailyPulse();
      return response.data;
    } catch (error) {
      return rejectWithValue(parseError(error));
    }
  }
);

export const createDailyPulse = createAsyncThunk(
  'clientDashboard/createDailyPulse',
  async (data, { rejectWithValue }) => {
    try {
      const response = await ClientDashboard.createDailyPulse(data);
      return response.data;
    } catch (error) {
      return rejectWithValue(parseError(error));
    }
  }
);

export const getDataPulseChart = createAsyncThunk(
  'clientDashboard/getDataPulseChart',
  async (queryParams, { rejectWithValue }) => {
    try {
      const response = await ClientDashboard.getDataPulseChart(queryParams);
      return response.data;
    } catch (error) {
      return rejectWithValue(parseError(error));
    }
  }
);

const dashBoardSlice = createSlice({
  name: 'clientDashboard',
  initialState: {
    dashBoardInfo: {},
    dataPulseChart: [],
  },
  extraReducers: {
    [fetchClientDashboardInfo.fulfilled]: (state, action) => {
      state.dashBoardInfo = action.payload;
    },

    [getDataPulseChart.fulfilled]: (state, action) => {
      state.dataPulseChart = action.payload;
    },
  },
});

export const selectListActivities = (state) =>
  state.clientDashboard.dashBoardInfo.listActivities;

export const selectDailyQuote = (state) =>
  state.clientDashboard.dashBoardInfo.dailyQuote;
export const selectAchievements = (state) =>
  state.clientDashboard.dashBoardInfo.achievements;
export const selectPulseChart = (state) => state.clientDashboard.dataPulseChart;

export default dashBoardSlice.reducer;
