import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Faqs from '../../api/admin/faqs';
import { parseError } from '../../app/utils/helper';

export const fetchFaqs = createAsyncThunk(
  'admin/fetch-faqs',
  async (_, { rejectWithValue }) => {
    try {
      const response = await Faqs.fetchFaqs();
      return response.data;
    } catch (error) {
      return rejectWithValue(parseError(error));
    }
  }
);

export const createFaq = createAsyncThunk(
  'admin/create-faqs',
  async (body, { rejectWithValue }) => {
    try {
      const { data } = await Faqs.createFaq(body);
      return { data, message: 'Create Faq successfully' };
    } catch (error) {
      return rejectWithValue(parseError(error));
    }
  }
);

export const updateFaq = createAsyncThunk(
  'admin/update-faqs',
  async ({ id, body }, { rejectWithValue }) => {
    try {
      const { data } = await Faqs.updateFaq(id, body);
      return { data, message: 'Update Faq successfully' };
    } catch (error) {
      return rejectWithValue(parseError(error));
    }
  }
);

export const deleteFaq = createAsyncThunk(
  'admin/delete-faqs',
  async ({ id }, { rejectWithValue }) => {
    try {
      const { data } = await Faqs.deleteFaq(id);
      return { data, message: 'Delete Faq successfully' };
    } catch (error) {
      return rejectWithValue(parseError(error));
    }
  }
);

export const updateOrderFaq = createAsyncThunk(
  'admin/updateOrderFaq',
  async (body, { rejectWithValue }) => {
    try {
      const { data } = await Faqs.updateOrderFaq(body);
      return { data, message: 'Update Order Faq successfully' };
    } catch (error) {
      return rejectWithValue(parseError(error));
    }
  }
);

export const fetchFaqCategory = createAsyncThunk(
  'admin/fetchFaqCategory',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await Faqs.fetchFaqCategory();
      return data;
    } catch (error) {
      return rejectWithValue(parseError(error));
    }
  }
);

const FaqsSlice = createSlice({
  name: 'faqs',
  initialState: {
    faqs: [],
    faqCreated: null,
    faqUpdated: null,
    faqDeleted: null,
    updateOrder: null,
    faqCategory: [],
  },
  reducers: {},
  extraReducers: {
    [fetchFaqs.fulfilled]: (state, action) => {
      state.faqs = action.payload;
    },
    [createFaq.fulfilled]: (state, action) => {
      state.faqCreated = action.payload.data;
    },
    [updateFaq.fulfilled]: (state, action) => {
      state.faqUpdated = action.payload.data;
    },
    [deleteFaq.fulfilled]: (state, action) => {
      state.faqDeleted = action.payload.data;
    },
    [updateOrderFaq.fulfilled]: (state, action) => {
      state.updateOrder = action.payload.data;
    },
    [fetchFaqCategory.fulfilled]: (state, action) => {
      state.faqCategory = action.payload;
    },
  },
});

export default FaqsSlice.reducer;
