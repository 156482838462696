import React, { useEffect, useState } from 'react';
import { get } from 'lodash';

const Question = ({
  question,
  index,
  chapter,
  answers,
  onAnswered,
  answered,
}) => {
  const [inputAnswer, setInputAnswer] = useState('');
  const [selectAnswer, setSelectAnswer] = useState();
  useEffect(() => {
    if (answered) {
      if (get(question, 'answer_type') === 'text') {
        setInputAnswer(answered.text_answer);
      } else if (get(question, 'answer_type') === 'multiple_choice') {
        setSelectAnswer(answered.answer);
      }
    }
  }, [answered]);

  const handleChangeInput = (e) => {
    setInputAnswer(e.target.value);
    onAnswered({
      questionId: question.id,
      text_answer: e.target.value,
      type: 'text',
      isCorrect: true,
      chapterId: chapter,
    });
  };

  const handleChangeSelect = (e, isCorrect) => {
    setSelectAnswer(+e.target.value);
    onAnswered({
      questionId: question.id,
      answer: +e.target.value,
      type: 'multiple_choice',
      isCorrect,
      chapterId: chapter,
    });
  };

  return (
    <div className="card mb-5 mb-lg-8 bg-light">
      <div className="card-body p-5 p-md-8">
        <div className="d-md-flex">
          <div className="symbol symbol-45px symbol-circle">
            <span className="symbol-label fs-2 fw-bold bg-primary text-inverse-primary">
              {index < 9 ? 0 : ''}
              {index + 1}
            </span>
          </div>
          <div className="mt-5 mt-md-0 ms-md-8 w-100">
            <div className="bg-secondary p-5 rounded mb-5">
              {get(question, 'question')}
            </div>
            {get(question, 'answer_type') == 'text' && (
              <textarea
                value={inputAnswer}
                className="form-control"
                rows="10"
                placeholder="Type..."
                onChange={handleChangeInput}
              ></textarea>
            )}
            {get(question, 'answer_type') == 'multiple_choice' && (
              <div>
                {answers.map((answer, index) => (
                  <div
                    className={`form-check form-check-custom form-check-solid ${
                      index < answers.length - 1 ? 'mb-5' : ''
                    }`}
                    key={index}
                  >
                    <input
                      className="form-check-input"
                      id={answer.id}
                      type="radio"
                      value={answer.id}
                      checked={selectAnswer === answer.id}
                      onChange={(e) => handleChangeSelect(e, answer.is_correct)}
                    />
                    <label className="form-check-label" htmlFor={answer.id}>
                      {answer.description}
                    </label>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Question;
