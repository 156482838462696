import React, { useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';
import BannerAuth from '../../components/BannerAuth';
import FooterAuth from '../../components/FooterAuth';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch } from 'react-redux';
import { forgotPassword, triggerSentMail } from '../../../features/user/auth';
import { get } from 'lodash';

const ForgotPassword = () => {
  const hiddenBtn = useRef(null);
  const dispatch = useDispatch();
  const history = useHistory();

  const schema = yup.object().shape({
    email: yup
      .string()
      .email('Must be a valid email')
      .required('Email is required'),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: '',
    },
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    const result = await dispatch(forgotPassword(data)).unwrap();
    if (get(result, 'ok') === true) {
      hiddenBtn.current.click();
    }
  };

  const onNavigate = () => {
    history.push('/');
    triggerSentMail(true);
  };

  return (
    <>
      <BannerAuth>
        <div className="login-content flex-lg-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden py-10 px-10 p-lg-7 mx-auto mw-450px w-100">
          <div className="d-flex flex-column-fluid flex-center py-10">
            <form
              className="form w-100"
              noValidate="novalidate"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="pb-5 pb-lg-10">
                <h3 className="fw-bolder text-dark display-6">
                  Forgotten Password ?
                </h3>
                <p className="text-muted fw-bold fs-3">
                  Enter your email below and we will send you a link to reset
                  your password
                </p>
              </div>
              <div className="fv-row mb-10">
                <label className="form-label fs-6 fw-bolder text-dark pt-5">
                  Email
                </label>
                <input
                  className="form-control form-control-lg form-control-solid bg-white"
                  type="email"
                  placeholder=""
                  name="email"
                  autoComplete="off"
                  {...register('email')}
                />
              </div>
              <span className="invalid-feedback">
                {errors['email']?.message}
              </span>
              <div className="d-flex flex-wrap pb-lg-0">
                <button
                  className="btn btn-info fw-bolder fs-6 px-8 py-4 my-3 me-4"
                  type="submit"
                >
                  Send reset link
                </button>
                {/* this button is use to trigger modal */}
                <button
                  type="button"
                  ref={hiddenBtn}
                  style={{ display: 'none', opacity: 0 }}
                  data-bs-target="#modal-noti-sent-email"
                  data-bs-toggle="modal"
                  key={'hidden'}
                >
                  Hidden
                </button>
                <Link
                  className="btn btn-light-info fw-bolder fs-6 px-8 py-4 my-3"
                  to="./"
                >
                  Cancel
                </Link>
              </div>
            </form>
          </div>
          <FooterAuth />
        </div>
      </BannerAuth>
      {/* Modal */}
      <div className="modal fade" id="modal-noti-sent-email" tabIndex={-1}>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <div className="text-center">
                <div className="my-10">
                  <i className="bi bi-check2-circle text-primary fs-5x" />
                </div>
                <h3>An email has been sent to you.</h3>
                <p>
                  Click on the link in the email we’ve sent you and reset your
                  password from there.
                </p>
              </div>
            </div>
            <div className="modal-footer border-0 justify-content-center">
              <button
                className="btn btn-light-primary"
                data-bs-dismiss="modal"
                onClick={onNavigate}
              >
                Back to sign in
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
