import React, { useEffect } from 'react';
import { Switch, Route, useLocation, useHistory } from 'react-router-dom';
import UserList from './Users/UserList';
import IncidentList from './Incident/IncidentList';
import UserDetail from './Users/UserDetail';
import AddUser from './Users/AddUser';
import TripList from './TripList';
import TripDetail from './TripDetail';
import Account from './Account';
import Cms from './Cms';
import Sidebar from './Layout/Sidebar';
import Header from '../../components/Header';
import { useSelector } from 'react-redux';
import { getTitle } from '../../../features/admin/dailyQuotes';
import ScrollTop from '../../components/ScrollToTop';
import useAnimationMobileMenu from '../../../hooks/use-animation-mobile-menu';
import CarList from './CarsList';
import carForm from './CarsList/CarForm';
import AuthorisedUserList from './AuthorisedUser/AuthorisedUserList';

const Admin = () => {
  const { isScrollAnimated } = useAnimationMobileMenu();
  const location = useLocation();
  const history = useHistory();

  const routers = [
    {
      key: 'addUser',
      name: 'Create User',
      path: '/admin/user-list/add',
      needBack: true,
      breadCrumbs: [
        {
          name: 'Cars List',
          path: '/admin/cars',
        },
        {
          name: 'User list',
          path: '/admin/user-list',
        },
        {
          name: 'Create User',
        },
      ],
      component: AddUser,
    },
    {
      key: 'userList',
      name: 'User list',
      path: '/admin/user-list',
      component: UserList,
    },
    {
      key: 'userDetail',
      name: 'User detail',
      path: '/admin/user-detail/:id',
      needBack: true,
      breadCrumbs: [
        {
          name: 'Cars List',
          path: '/admin/cars',
        },
        {
          name: 'User list',
          path: `/admin/user-list`,
        },
        {
          name: 'User detail',
        },
      ],
      component: UserDetail,
    },
    {
      key: 'CarsList',
      name: 'Cars List',
      path: '/admin/cars',
      isHideHeader: true,
      component: CarList,
    },
    {
      key: 'createCar',
      name: 'Create car',
      path: '/admin/create-car',
      needBack: true,
      breadCrumbs: [
        {
          name: 'Cars List',
          path: '/admin/cars',
        },
        {
          name: 'Cars',
          path: '/admin/cars',
        },
        {
          name: 'Create car',
        },
      ],
      component: carForm,
    },
    {
      key: 'cms',
      name: useSelector(getTitle),
      path: '/admin/cms',
      breadCrumbs: [
        {
          name: 'Cars List',
          path: '/admin/cars',
        },
        {
          name: 'Content management system',
          path: '/admin/cms',
        },
        {
          name: 'Daily quotes',
        },
      ],
      component: Cms,
    },
    {
      key: 'incidentList',
      name: 'Incidents',
      path: '/admin/incidents',
      component: IncidentList,
    },
    {
      key: 'authorisedUserList',
      name: 'Authorised Users',
      path: '/admin/authorised-users',
      component: AuthorisedUserList,
    },
    {
      key: 'tripList',
      name: 'Trip list',
      path: '/admin/trips',
      component: TripList,
    },
    {
      key: 'tripDetail',
      name: 'Trip detail',
      path: '/admin/trip/:id',
      component: TripDetail,
    },
  ];

  useEffect(() => {
    if (location.pathname.endsWith('/admin')) {
      history.push('/admin/cars');
    }
  }, []);

  const getWrapperClassName = () => {
    if (
      ['/admin/user-list', '/admin/activity-library'].includes(
        location.pathname
      )
    ) {
      return 'header-fixed header-tablet-and-mobile-fixed aside-enabled aside-fixed toolbar-enabled aside-secondary-disabled';
    } else if (location.pathname.startsWith('/admin/admin-detail/')) {
      return 'header-fixed header-tablet-and-mobile-fixed aside-secondary-enabled .aside-enabled aside-fixed';
    } else if (
      location.pathname.startsWith('/admin/clinician-detail/') ||
      location.pathname.startsWith('/admin/client-detail/')
    ) {
      return 'header-fixed header-tablet-and-mobile-fixed toolbar-enabled aside-secondary-enabled .aside-enabled aside-fixed';
    }
    return 'header-fixed header-tablet-and-mobile-fixed aside-enabled aside-fixed aside-secondary-disabled';
  };

  const onScroll = () => {
    const kt_body = document.getElementById('kt_body');
    const kt_back = document.getElementById('kt_back');
    if (window.scrollY / window.innerHeight > 0.5) {
      kt_body.setAttribute('data-kt-scrolltop', 'on');
      kt_body.setAttribute('data-kt-sticky-header', 'on');
      if (kt_back) {
        kt_back.classList.add('kt-back-mobile');
      }
    } else {
      kt_body.setAttribute('data-kt-scrolltop', 'off');
      kt_body.setAttribute('data-kt-sticky-header', 'off');
      if (kt_back) {
        kt_back.classList.remove('kt-back-mobile');
      }
    }
  };
  useEffect(() => {
    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  return (
    <div
      className={getWrapperClassName()}
      id="kt_body"
      data-kt-scrolltop="off"
      data-kt-sticky-header="off"
    >
      <div className="d-flex flex-column flex-root">
        <div className="page d-flex flex-row flex-column-fluid">
          <Sidebar />
          <div
            className="wrapper d-flex flex-column flex-row-fluid"
            id="kt_wrapper"
          >
            <Header
              routers={routers}
              hideHeaderUser={false}
              isScrollAnimated={isScrollAnimated}
            />
            <Switch>
              {routers.map((router) => {
                return (
                  <Route
                    key={router.key}
                    path={router.path}
                    component={router.component}
                  />
                );
              })}
            </Switch>
            <ScrollTop />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Admin;
