import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectAuthUser,
  updateMe,
  updateMyPassword,
} from '../../../../features/user/auth';
import ChangePWForm from './Components/ChangePWForm';
import UserForm from './Components/UserForm';

const Account = () => {
  const dispatch = useDispatch();

  const authUser = useSelector(selectAuthUser);
  const [isEditMode, setIsEditMode] = useState(false);

  const onHandleCancel = () => {
    setIsEditMode(false);
  };

  const onHandleSave = (data) => {
    dispatch(updateMe({ id: authUser.id, data }));
  };

  const onHanldeEdit = () => {
    setIsEditMode(true);
  };

  const onChangePW = (data) => {
    dispatch(updateMyPassword(data));
  };

  return (
    <div className="d-flex flex-column flex-column-fluid">
      <div className="content fs-6 d-flex flex-column-fluid" id="kt_content">
        <div className="container">
          <div className="d-flex flex-column flex-lg-row">
            <UserForm
              user={authUser}
              isEditMode={isEditMode}
              onHanldeEdit={onHanldeEdit}
              onHandleSave={onHandleSave}
              onHandleCancel={onHandleCancel}
            />
            <ChangePWForm onChangePW={onChangePW} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Account;
