import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchTermConditions,
  selectTermConditions,
} from '../../../features/admin/termCondition';
import { get } from 'lodash';
import { isMobile } from 'react-device-detect';

export const TermCondition = () => {
  const dispatch = useDispatch();
  const termConditions = useSelector(selectTermConditions);

  useEffect(() => {
    dispatch(fetchTermConditions());
  }, []);

  return (
    <div className="d-flex flex-column flex-root">
      <div className="d-flex flex-column flex-lg-row flex-column-fluid bg-white">
        <div className="d-flex flex-column flex-row-fluid position-relative overflow-hidden">
          <div
            className={`d-flex flex-column-fluid d-flex justify-content-center mt-lg-0 ${
              isMobile ? '' : 'align-items-center p-10'
            }`}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: termConditions || '',
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
