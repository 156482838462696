import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ClientActivity from '../../api/client/activity';
import { parseError } from '../../app/utils/helper';

export const selectActivityById = (id) => (state) =>
  state.clientActivity.activities.find((activity) => activity.id == id);

export const fetchActivities = createAsyncThunk(
  'clientActivity/fetchActivities',
  async ({ id, queryParams, isGridView }, { rejectWithValue }) => {
    try {
      const { data } = await ClientActivity.fetchActivities(id, queryParams);
      return { ...data, isGridView };
    } catch (error) {
      return rejectWithValue(parseError(error));
    }
  }
);

export const submitActivity = createAsyncThunk(
  'clientActivity/submitActivity',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await ClientActivity.submitActivity(payload);
      return response.data;
    } catch (error) {
      return rejectWithValue(parseError(error));
    }
  }
);

export const fetchSumissions = createAsyncThunk(
  'clientActivity/fetchActvitySumissions',
  async (params, { rejectWithValue }) => {
    try {
      const response = await ClientActivity.fetchSumissions(params);
      return response.data;
    } catch (error) {
      return rejectWithValue(parseError(error));
    }
  }
);

export const fetchAnswerSumissions = createAsyncThunk(
  'clientActivity/fetchActvityAnswerSumissions',
  async (params, { rejectWithValue }) => {
    try {
      const response = await ClientActivity.fetchAnswerSumissions(params);
      return response.data;
    } catch (error) {
      return rejectWithValue(parseError(error));
    }
  }
);

export const fetchAssignedActivityById = createAsyncThunk(
  'clientActivity/fetchAssignedActivityById',
  async ({ clientId, id }, { rejectWithValue }) => {
    try {
      const response = await ClientActivity.fetchAssignedActivityById(
        clientId,
        id
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(parseError(error));
    }
  }
);

export const fetchActivityById = createAsyncThunk(
  'clientActivity/fetchActivityById',
  async (id, { rejectWithValue }) => {
    try {
      const response = await ClientActivity.fetchActivityById(id);
      return response.data;
    } catch (error) {
      return rejectWithValue(parseError(error));
    }
  }
);

export const submitAnswer = createAsyncThunk(
  'clientActivity/submitAnswer',
  async (data, { rejectWithValue }) => {
    try {
      const response = await ClientActivity.submitAnswer(data);
      return response.data;
    } catch (error) {
      return rejectWithValue(parseError(error));
    }
  }
);

export const fetchLatestAnswerByChapterId = createAsyncThunk(
  'clientActivity/fetchLatestAnswerByChapterId',
  async (queryParams, { rejectWithValue }) => {
    try {
      const response = await ClientActivity.fetchLatestAnswerByChapterId(
        queryParams
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(parseError(error));
    }
  }
);

export const redoActivity = createAsyncThunk(
  'clientActivity/redoActivity',
  async (id, { rejectWithValue }) => {
    try {
      const response = await ClientActivity.redoActivity(id);
      return response.data;
    } catch (error) {
      return rejectWithValue(parseError(error));
    }
  }
);

const initialState = {
  // this is use for activity list
  activities: [],
  totalActivity: 0,
  activityGridView: [],
  // this is use for activity intro
  assignedActivityDetail: {},
  // this is use for activity chapter
  activityDetail: {},
  // this is use to save answer by activity
  answerActivity: [],
};

const activitiesSlice = createSlice({
  name: 'clientActivity',
  initialState,
  reducers: {
    resetActivityGridView: (state, action) => {
      state.activityGridView = [];
    },

    addAnswerActivity: (state, action) => {
      for (var i = 0; i < action.payload.length; i++) {
        const index = state.answerActivity.findIndex(
          (element) => element.questionId === action.payload[i].questionId
        );
        if (index === -1) {
          state.answerActivity.push(action.payload[i]);
        } else {
          state.answerActivity[index] = action.payload[i];
        }
      }
    },

    resetAnswerActivity: (state, action) => {
      state.answerActivity = [];
    },
  },
  extraReducers: {
    [fetchActivities.fulfilled]: (state, action) => {
      const { assignedActivities, totalActivity, isGridView } = action.payload;
      if (isGridView) {
        state.activityGridView = [
          ...state.activityGridView,
          ...assignedActivities,
        ];
      } else {
        state.activities = assignedActivities;
        state.totalActivity = totalActivity;
      }
    },

    [fetchAssignedActivityById.fulfilled]: (state, action) => {
      state.assignedActivityDetail = action.payload;
    },

    [fetchActivityById.fulfilled]: (state, action) => {
      state.activityDetail = action.payload;
    },
  },
});

export const { addAnswerActivity, resetAnswerActivity, resetActivityGridView } =
  activitiesSlice.actions;

export const selectActivityList = (state) => state.clientActivity.activities;
export const selectTotalActivity = (state) =>
  state.clientActivity.totalActivity;
export const selectActivityGridView = (state) =>
  state.clientActivity.activityGridView;
export const selectAssignedActivityDetail = (state) =>
  state.clientActivity.assignedActivityDetail;
export const selectActivityDetail = (state) =>
  state.clientActivity.activityDetail;
export const selectAnswerActivity = (state) =>
  state.clientActivity.answerActivity;

export default activitiesSlice.reducer;
