import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import FaqCategories from '../../api/admin/faqCategory';
import { parseError } from '../../app/utils/helper';

export const fetchFaqCategory = createAsyncThunk(
  'admin/fetchFaqCategory',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await FaqCategories.fetchFaqCategory();
      return data;
    } catch (error) {
      return rejectWithValue(parseError(error));
    }
  }
);

export const createFaqCategory = createAsyncThunk(
  'admin/createFaqCategory',
  async (body, { rejectWithValue }) => {
    try {
      const { data } = await FaqCategories.createFaqCategory(body);
      return { data, message: 'Create Faq Category successfully' };
    } catch (error) {
      return rejectWithValue(parseError(error));
    }
  }
);

export const updateFaqCategory = createAsyncThunk(
  'admin/updateFaqCategory',
  async ({ id, body }, { rejectWithValue }) => {
    try {
      const { data } = await FaqCategories.updateFaqCategory(id, body);
      return { data, message: 'Update Faq Category successfully' };
    } catch (error) {
      return rejectWithValue(parseError(error));
    }
  }
);

export const deleteFaqCategory = createAsyncThunk(
  'admin/deleteFaqCategory',
  async ({ id }, { rejectWithValue }) => {
    try {
      const { data } = await FaqCategories.deleteFaqCategory(id);
      return { data, message: 'Delete Faq Category successfully' };
    } catch (error) {
      return rejectWithValue(parseError(error));
    }
  }
);

const FaqCategoriesSlice = createSlice({
  name: 'faqCategories',
  initialState: {
    faqCategories: [],
    faqCategoryCreated: null,
    faqCategoryUpdated: null,
    faqCategoryDeleted: null,
  },
  reducers: {},
  extraReducers: {
    [fetchFaqCategory.fulfilled]: (state, action) => {
      state.faqCategories = action.payload;
    },
    [createFaqCategory.fulfilled]: (state, action) => {
      state.faqCategoryCreated = action.payload.data;
    },
    [updateFaqCategory.fulfilled]: (state, action) => {
      state.faqCategoryUpdated = action.payload.data;
    },
    [deleteFaqCategory.fulfilled]: (state, action) => {
      state.faqCategoryDeleted = action.payload.data;
    },
  },
});

export default FaqCategoriesSlice.reducer;
