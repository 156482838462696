import React from 'react';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';

import DetailTab from './DetailTab';

function ClientForm({ onHandleSubmit }) {
  const history = useHistory();
  const userDetail = useSelector((state) => state.user.user);

  const onHandleCancel = () => {
    history.push(`/admin/user-list`);
  };

  return (
    <div className="d-flex flex-column flex-column-fluid">
      <div className="content fs-6 d-flex flex-column-fluid" id="kt_content">
        <div className="container">
          <div className="card">
            <div className="card-body">
              <DetailTab
                onHandleSubmit={onHandleSubmit}
                onHandleCancel={onHandleCancel}
                userDetail={userDetail}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClientForm;
