import React from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import SVG from 'react-inlinesvg';
import { useDispatch } from 'react-redux';
import { logout } from '../../../../features/user/auth';

function SideBar() {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  const sideBar = [
    {
      route: '/client/dashboard2',
      activeRoutes: '/client/dashboard2',
      title: 'Dashboard',
      icon: '/images/icons/dashboard.svg',
    },
    {
      route: '/client/activity',
      activeRoutes: '/client/activity',
      title: 'Activity',
      icon: '/images/icons/activities.svg',
    },
    {
      route: '/client/knowledge-hub',
      activeRoutes: '/client/knowledge-hub',
      title: 'Knowledge-base',
      icon: '/images/icons/hub.svg',
    },
    {
      route: '/client/faq',
      activeRoutes: '/client/faq',
      title: 'FAQ',
      icon: '/images/icons/chat.svg',
    },
  ];

  const onLogout = () => {
    dispatch(logout());
    return history.push('/');
  };

  return (
    <div
      className="aside"
      id="kt_aside"
      data-kt-drawer="true"
      data-kt-drawer-name="aside"
      data-kt-drawer-activate="{default: true, lg: false}"
      data-kt-drawer-overlay="true"
      data-kt-drawer-width="70px"
      data-kt-drawer-direction="start"
      data-kt-drawer-toggle="#kt_aside_toggler"
    >
      <div className="aside-primary d-flex flex-column align-items-center flex-row-auto bg-info">
        <div
          className="aside-logo d-flex flex-column align-items-center flex-column-auto py-4 pt-lg-10 pb-lg-7"
          id="kt_aside_logo"
        >
          <Link to="/client/dashboard">
            <img
              width={50}
              height={19}
              className="mh-50px"
              alt="Logo"
              src="/images/logos/logo-compact.svg"
            />
          </Link>
        </div>
        <div
          className="aside-nav d-flex flex-column align-items-center flex-column-fluid pt-0 pb-5 mt-lg-20"
          id="kt_aside_nav"
        >
          <div
            className="hover-scroll-y"
            data-kt-scroll="true"
            data-kt-scroll-height="auto"
            data-kt-scroll-dependencies="#kt_aside_logo, #kt_aside_footer"
            data-kt-scroll-wrappers="#kt_aside_nav"
            data-kt-scroll-offset="10px"
          >
            <ul className="nav flex-column">
              {sideBar.map((item, index) => (
                <li
                  key={index}
                  className="nav-item mb-1"
                  title={item.title}
                  data-bs-toggle="tooltip"
                  data-bs-dismiss="click"
                  data-bs-placement="right"
                >
                  <Link
                    className={`nav-link h-40px w-40px btn btn-custom btn-icon btn-color-white rounded-1 ${
                      location.pathname.startsWith(item.activeRoutes) &&
                      'active'
                    }`}
                    to={item.route}
                  >
                    <span className="svg-icon svg-icon-4  ">
                      <SVG src={item.icon} />
                    </span>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div
          className="aside-footer d-flex flex-column align-items-center flex-column-auto py-5 py-lg-7"
          id="kt_aside_footer"
        >
          <button
            className="h-40px w-40px btn btn-custom btn-icon btn-color-white"
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            data-bs-trigger="hover"
            title="Sign out"
            onClick={onLogout}
          >
            <span className="svg-icon">
              <SVG src="/images/icons/sign-out.svg" />
            </span>
          </button>
        </div>
      </div>
    </div>
  );
}

export default SideBar;
