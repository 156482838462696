import dayjs from 'dayjs';
import { get, filter, includes, isEqual } from 'lodash';
import { UserType } from './constants';
import SVG from 'react-inlinesvg';
import QRCode from 'qrcode';

const hasPrefix = (action, prefix) => {
  for (var i = 0; i < prefix.length; i++) {
    if (action.type.startsWith(prefix[i])) {
      return true;
    }
  }
  return false;
};

const isPending = (action) => action.type.endsWith('/pending');
const isFulfilled = (action) => action.type.endsWith('/fulfilled');
const isRejected = (action) => action.type.endsWith('/rejected');

export const isPendingAction = (prefix) => (action) => {
  // Note: this cast to AnyAction could also be `any` or whatever fits your case best
  return hasPrefix(action, prefix) && isPending(action);
};

export const isRejectedAction = (prefix) => (action) => {
  // Note: this cast to AnyAction could also be `any` or whatever fits your case best - like if you had standardized errors and used `rejectWithValue`
  return hasPrefix(action, prefix) && isRejected(action);
};

export const isFulfilledAction = (prefix) => (action) => {
  return hasPrefix(action, prefix) && isFulfilled(action);
};

export function formatDate(date) {
  if (date) {
    return dayjs(date).format('DD/MM/YYYY');
  }
  return '';
}

export function formatDateTime(date) {
  if (!date) return;

  return dayjs(date).format('DD/MM/YYYY - hh:mmA');
}

export function parseError(error) {
  return {
    message:
      get(error, 'data.message[0].messages[0].message') ||
      get(error, 'data.message'),
    statusCode: get(error, 'status'),
  };
}

export function filterRole(roles, roleAuthUser) {
  if (!roleAuthUser) {
    return [];
  } else {
    return filter(roles, function (role) {
      let allowRole;
      if (roleAuthUser === UserType.SupperAdmin) {
        [UserType.SupperAdmin, ...allowRole] = Object.values(UserType);
      } else if (roleAuthUser === UserType.Admin) {
        [UserType.SupperAdmin, UserType.Admin, ...allowRole] =
          Object.values(UserType);
      } else if (roleAuthUser === UserType.Clinician) {
        [
          UserType.SupperAdmin,
          UserType.Admin,
          UserType.Clinician,
          ...allowRole
        ] = Object.values(UserType);
      }
      return includes(Object.values(allowRole), role.id);
    });
  }
}

export const displayIconSort = (key, sortConfig) => {
  return (
    isEqual(sortConfig?.key, key) && (
      <SVG
        src={`/images/icons/${sortConfig?.direction === 'ascending' ? 'up.svg' : 'down-2.svg'
          }`}
        style={{ width: '10px', marginLeft: '10px' }}
      />
    )
  );
};

export function formatOnlyDate(date) {
  if (!date) return;

  return dayjs(date).format('DD/MM/YYYY');
}

export function formatOnlyTime(date) {
  if (!date) return;

  return dayjs(date).format('hh:mmA');
}

export const printToFile = (text, name) => {
  QRCode.toDataURL(text, {
    width: 1500,
    margin: 0
  }, function (err, myImage) {
    var link = document.createElement("a");
    link.download = `${name}.png`;
    link.href = "data:" + myImage;
    link.click();
  });
}