import React, { useState } from 'react';
import { initialPagination, SizePerPageList } from '../utils/constants';
import ReactPaginate from 'react-paginate';

function Pagination({
  limit = initialPagination._limit,
  onChangeSize,
  onPageClick,
  total,
}) {
  const [itemPerPage, setItemPerPage] = useState(limit);
  const [page, setPage] = useState(0);

  const handlePageClick = (event) => {
    onPageClick(event.selected);
    setPage(event.selected);
  };

  const onChangeSizePerPage = (size) => {
    setItemPerPage(size);
    setPage(0);
    onChangeSize(size);
  };

  const getShowing = () => {
    if (total < itemPerPage) {
      return `Showing 0 to ${total} of ${total} records`;
    } else {
      const count = itemPerPage * (page + 1);
      if (count < total) {
        return `Showing ${itemPerPage * page} to ${count} of ${total} records`;
      } else {
        return `Showing ${itemPerPage * page} to ${total} of ${total} records`;
      }
    }
  };

  return (
    <div className="row">
      <div className="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start">
        <div className="dataTables_length" id="kt_datatable_example_2_length">
          <label>
            <select
              name="kt_datatable_example_2_length"
              aria-controls="kt_datatable_example_2"
              className="form-select form-select-sm form-select-solid"
              value={itemPerPage}
              onChange={(e) => onChangeSizePerPage(e.target.value)}
            >
              {SizePerPageList.map((item, index) => (
                <option key={index} value={item.value}>
                  {item.text}
                </option>
              ))}
            </select>
          </label>
        </div>
        <div
          className="dataTables_info"
          id="kt_datatable_example_2_info"
          role="status"
          aria-live="polite"
        >
          {getShowing()}
        </div>
      </div>
      <div className="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end">
        <div
          className="dataTables_paginate paging_simple_numbers"
          id="kt_datatable_example_2_paginate"
        >
          <ReactPaginate
            previousLabel="<"
            nextLabel=">"
            breakLabel="..."
            breakClassName="paginate_button page-item"
            breakLinkClassName="page-link"
            pageCount={Math.ceil(total / itemPerPage)}
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            onPageChange={handlePageClick}
            containerClassName="pagination justify-content-center"
            pageClassName="paginate_button page-item"
            pageLinkClassName="page-link"
            previousClassName="paginate_button page-item previous "
            previousLinkClassName="page-link"
            nextClassName="paginate_button page-item next"
            nextLinkClassName="page-link"
            activeClassName="active"
            hrefBuilder={(page, pageCount, selected) =>
              page >= 1 && page <= pageCount ? `/page/${page}` : '#'
            }
            hrefAllControls
            forcePage={page}
          />
        </div>
      </div>
    </div>
  );
}

export default Pagination;
