import React from 'react';
import Detail from './Detail';

const TripDetail = () => {
  return (
    <div className="d-flex flex-column flex-column-fluid">
      <div className="content fs-6 d-flex flex-column-fluid" id="kt_content">
        <div className="container">
          <Detail />
        </div>
      </div>
    </div>
  );
};

export default TripDetail;
