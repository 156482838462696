import React from 'react';
import { useHistory } from 'react-router';
import { displayIconSort, formatDate } from '../../../../../utils/helper';
import { get } from 'lodash';
import Pagination from '../../../../../components/Pagination';
import { useSortableData } from '../../../../../../hooks/useSortableData';
import { Link } from 'react-router-dom';

function ActivityTable({
  isCompleted,
  activities,
  totalActivity,
  onChangeSize,
  onPageClick,
}) {
  const history = useHistory();
  const {
    items: dataTable,
    requestSort,
    sortConfig,
  } = useSortableData(activities);

  return (
    <div role="tabpanel">
      <div className="card">
        <div className="card-body">
          <div className="card">
            <div className="card-body p-0 pb-5">
              <div className="table-responsive">
                <table
                  className="table table-striped table-row-bordered gy-5 gs-7 align-middle w-100"
                  id="kt_datatable_example_2"
                >
                  <thead>
                    <tr className="fw-bold fs-6 text-gray-800">
                      <th
                        className="min-w-300px"
                        onClick={() => requestSort('activity.title')}
                      >
                        Activities
                        {displayIconSort('activity.title', sortConfig)}
                      </th>
                      <th
                        className="min-w-100px text-center"
                        onClick={() => requestSort('activity.code')}
                      >
                        Code
                        {displayIconSort('activity.code', sortConfig)}
                      </th>
                      <th
                        className="min-w-150px text-center"
                        onClick={() =>
                          requestSort([
                            'assigned_by.first_name',
                            'assigned_by.surname',
                          ])
                        }
                      >
                        Assigned by
                        {displayIconSort(
                          ['assigned_by.first_name', 'assigned_by.surname'],
                          sortConfig
                        )}
                      </th>

                      <th
                        className="min-w-150px text-center"
                        onClick={() => requestSort('created_at')}
                      >
                        Assigned
                        {displayIconSort('created_at', sortConfig)}
                      </th>
                      {isCompleted && (
                        <th
                          className="min-w-150px text-center"
                          onClick={() => requestSort('completed_at')}
                        >
                          Completed
                          {displayIconSort('completed_at', sortConfig)}
                        </th>
                      )}
                      <th
                        className="min-w-150px"
                        onClick={() => requestSort('activity.created_at')}
                      >
                        Created
                        {displayIconSort('activity.created_at', sortConfig)}
                      </th>
                      {isCompleted && (
                        <th
                          className="min-w-150px"
                          onClick={() => requestSort('status')}
                        >
                          Status
                          {displayIconSort('status', sortConfig)}
                        </th>
                      )}
                      {!isCompleted && <th></th>}
                    </tr>
                  </thead>
                  <tbody>
                    {dataTable.map((activity, index) => {
                      return (
                        <tr
                          key={index}
                          onClick={() =>
                            history.push(
                              `/client/activity/${get(activity, 'activity.id')}`
                            )
                          }
                        >
                          <td className="min-w-400px">
                            <div className="d-flex align-items-center">
                              <div className="symbol symbol-75px symbol-2by3">
                                <img
                                  src={get(
                                    activity,
                                    'activity.activity_picture.url'
                                  )}
                                  alt=""
                                />
                              </div>
                              <div className="ms-5">
                                <Link
                                  className="ellipsis-2rows fw-bolder mb-0"
                                  to={`/client/activity/${get(
                                    activity,
                                    'activity.id'
                                  )}`}
                                >
                                  {get(activity, 'activity.title')}
                                </Link>
                                {get(activity, 'activity.topics') && (
                                  <div className="mt-4">
                                    <div className="d-flex overflow-auto">
                                      {get(activity, 'activity.topics').map(
                                        (topic, index) => (
                                          <span
                                            key={index}
                                            className="badge badge-pill badge-light-info me-3 mb-1 fw-normal"
                                          >
                                            {topic.name}
                                          </span>
                                        )
                                      )}
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </td>
                          <td className="min-w-100px text-center">
                            {get(activity, 'activity.code')}
                          </td>
                          <td className="min-w-150px text-center text-gray-500">
                            {`${get(activity, 'assigned_by.first_name')} ${get(
                              activity,
                              'assigned_by.surname'
                            )}`}
                          </td>

                          <td className="min-w-150px text-center text-gray-500">
                            {formatDate(get(activity, 'created_at'))}
                          </td>
                          {isCompleted && (
                            <td className="min-w-150px text-center text-gray-500">
                              {formatDate(get(activity, 'completed_at'))}
                            </td>
                          )}
                          <td className="min-w-150px text-gray-500">
                            {formatDate(get(activity, 'activity.created_at'))}
                          </td>
                          {isCompleted && (
                            <td className="min-w-150px text-gray-500">
                              {get(activity, 'status')}
                            </td>
                          )}
                          {!isCompleted && (
                            <td className="min-w-150px">
                              <div className="d-flex flex-center wrap-progress">
                                <div className="progress h-6px w-100 bg-secondary">
                                  <div
                                    className="progress-bar"
                                    role="progressbar"
                                    style={{
                                      width: `${
                                        (get(activity, 'total_answer') * 100) /
                                        get(activity, 'total_question')
                                      }%`,
                                    }}
                                    aria-valuenow="40"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                  ></div>
                                </div>
                              </div>
                            </td>
                          )}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                {totalActivity > 0 && (
                  <Pagination
                    onChangeSize={onChangeSize}
                    onPageClick={onPageClick}
                    total={totalActivity}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ActivityTable;
