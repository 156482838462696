import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import PrivacyPolicies from '../../api/admin//privacyPolicy';
import { parseError } from '../../app/utils/helper';

export const fetchPrivacyPolicies = createAsyncThunk(
  'admin/fetchPrivacyPolicies',
  async (_, { rejectWithValue }) => {
    try {
      const response = await PrivacyPolicies.fetchPrivacyPolicies();
      return response.data;
    } catch (error) {
      return rejectWithValue(parseError(error));
    }
  }
);

export const createPrivacyPolicy = createAsyncThunk(
  'admin/createPrivacyPolicy',
  async (body, { rejectWithValue }) => {
    try {
      const { data } = await PrivacyPolicies.createPrivacyPolicy(body);
      return { data, message: 'Create privacy policy successfully' };
    } catch (error) {
      return rejectWithValue(parseError(error));
    }
  }
);

export const updatePrivacyPolicy = createAsyncThunk(
  'admin/updatePrivacyPolicy',
  async ({ id, body }, { rejectWithValue }) => {
    try {
      const { data } = await PrivacyPolicies.updatePrivacyPolicy(id, body);
      return { data, message: 'Update privacy policy successfully' };
    } catch (error) {
      return rejectWithValue(parseError(error));
    }
  }
);

const PrivacyPoliciesSlice = createSlice({
  name: 'privacyPolicies',
  initialState: {
    privacyPolicies: [],
  },
  reducers: {},
  extraReducers: {
    [fetchPrivacyPolicies.fulfilled]: (state, action) => {
      state.privacyPolicies = action.payload;
    },
  },
});

export default PrivacyPoliciesSlice.reducer;

export const selectPrivacyPolicies = (state) =>
  state.privacyPolicy.privacyPolicies;
