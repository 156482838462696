import http from '../../services/http-common';

export default class Activity {
  static fetchActivities(id, queryParams) {
    return http.get(`/assigned-activities/client/${id}`, queryParams);
  }

  static fetchAssignedActivityById(clientId, id) {
    return http.get(`/assigned-activities/client/${clientId}/activity/${id}`);
  }

  static fetchActivityById(id) {
    return http.get(`/activities/${id}`);
  }

  static submitAnswer(data) {
    return http.post(`/latest-answers/submit`, data);
  }

  static fetchLatestAnswerByChapterId(queryParams) {
    return http.get(`/latest-answers`, queryParams);
  }

  static redoActivity(id) {
    return http.put(`/assigned-activities/redo/${id}`);
  }

  static submitActivity(payload) {
    return http.post(`/activity-submissions/unique`, payload);
  }

  static fetchSumissions(params) {
    return http.get(`/activity-submissions/`, { params });
  }

  static fetchAnswerSumissions(params) {
    return http.get(`/answer-submissions/`, { params });
  }
}
