import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from '@mui/material/Modal';
import { get } from 'lodash';

import {
  fetchKnowledges,
  createKnowledge,
  updateKnowledge,
  deleteKnowledge,
} from '../../../../../features/admin/knowledge';
import ModalConfirm from '../../../../components/Modal/ModalConfirm';

import FormKnowledgeHub from './form';
import { upload } from '../../../../../features/upload';

const KnowledgeHub = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [knowledgeImage, setKnowledgeImage] = useState('');
  const [filePdf, setFilePdf] = useState('');
  const [knowledgeSelected, setKnowledgeSelected] = useState(null);
  const [isError, setIsError] = useState(false);
  const dispatch = useDispatch();
  const { knowledges, knowledgeUpdated, knowledgeCreated, knowledgeDeleted } =
    useSelector((state) => state.knowledge);
  const { uploaded } = useSelector((state) => state.upload);
  useEffect(() => {
    dispatch(fetchKnowledges());
  }, [
    knowledgeCreated?.id,
    knowledgeUpdated?.id,
    knowledgeDeleted?.id,
    uploaded?.id,
  ]);

  const handleClose = () => {
    setKnowledgeSelected(null);
    setKnowledgeImage(null);
    setFilePdf(null);
    return setIsOpen(false);
  };

  const uploadFile = async (file) => {
    const formData = new FormData();
    formData.append('files', file);
    formData.append(
      'fileInfo',
      JSON.stringify({ alternativeText: '', caption: '', name: null })
    );
    try {
      const uploadResponse = await dispatch(upload(formData)).unwrap();
      return uploadResponse;
    } catch (error) {
      return setIsError(true);
    }
  };

  const setImage = useCallback(
    (imageResource) => {
      return setKnowledgeImage(
        !!imageResource?.length && get(imageResource, '0.id', '')
      );
    },
    [uploaded?.id]
  );

  const getImageKnowledge = async (file) => {
    const imageResource = await uploadFile(file);
    return setImage(imageResource);
  };

  const getFilePdf = async (file) => {
    const resource = await uploadFile(file);
    return setFilePdf(resource[0]);
  };

  const handleSubmitKnowledge = (data) => {
    const knowledgeId = knowledgeSelected?.id;
    const body = {
      ...data,
      cover: knowledgeImage ? knowledgeImage : knowledgeSelected?.cover?.id,
      file: filePdf?.id ? filePdf.id : '',
    };

    if (knowledgeId) {
      dispatch(updateKnowledge({ id: knowledgeId, body }));
      return setKnowledgeSelected(null);
    }
    dispatch(createKnowledge(body));
    return setIsOpen(false);
  };

  const onDeleteKnowledge = () => {
    const knowledgeId = knowledgeSelected?.id;
    if (knowledgeId) {
      dispatch(deleteKnowledge({ id: knowledgeId }));
      setIsOpen(false);
      setFilePdf(null);
      return setKnowledgeSelected(null);
    }
    return setIsOpen(false);
  };

  const removeAttachFile = () => {
    return setFilePdf(null);
  };

  const selectKnowledgeSelect = (knowledge) => () => {
    const file = knowledge?.file || null;
    if (file) {
      setFilePdf(file);
    }
    return setKnowledgeSelected(knowledge);
  };

  return (
    <>
      <div className="card-header border-0 pt-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bolder text-dark fs-2">
            Knowledge hub
          </span>
        </h3>
        <div className="card-toolbar">
          <a
            className="btn btn-primary ms-auto"
            onClick={() => setIsOpen(true)}
            data-bs-toggle="modal"
          >
            <i className="fas fa-plus me-2 opacity-50" />
            Add New Knowledge
          </a>
        </div>
      </div>

      <div className="card-body pt-12 mt-n3">
        <ul className="list-group list-group-flush">
          {knowledges?.length > 0 &&
            knowledges.map((knowledge) => (
              <li className="list-group-item py-5 px-0" key={knowledge.id}>
                <div className="d-flex align-items-center">
                  <div className="mb-0" style={{ cursor: 'pointer' }}>
                    <div className="symbol symbol-50px symbol-2by3 me-5">
                      {knowledge?.cover?.url && (
                        <img alt="cover-image" src={knowledge?.cover?.url} />
                      )}
                    </div>
                    <span
                      className="text-dark text-hover-primary"
                      style={{ fontWeight: 500 }}
                    >
                      {knowledge.title}
                    </span>
                  </div>
                  <div className="ms-auto min-w-100px text-end">
                    <button
                      className="btn btn-icon btn-primary btn-sm"
                      onClick={selectKnowledgeSelect(knowledge)}
                    >
                      <i className="bi bi-pencil fs-6"></i>
                    </button>
                  </div>
                </div>
              </li>
            ))}
        </ul>
      </div>
      <Modal open={isOpen || !!knowledgeSelected?.id} onClose={handleClose}>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <FormKnowledgeHub
              handleClose={handleClose}
              onSubmit={handleSubmitKnowledge}
              knowledgeSelected={knowledgeSelected}
              onDeleteKnowledge={onDeleteKnowledge}
              getFile={getImageKnowledge}
              getFilePdf={getFilePdf}
              filePdf={filePdf}
              knowledgeImage={knowledgeImage}
              isRemoveAttachFile={removeAttachFile}
            />
          </div>
        </div>
      </Modal>
      {isError && (
        <ModalConfirm
          isOpen={!!isError}
          handleClose={() => setIsError(false)}
          confirm={() => setIsError(false)}
          modalTitle={'Create Cover Image Failed'}
          modalNotes={'This action can not be undone!'}
        />
      )}
    </>
  );
};

export default KnowledgeHub;
