import React, { useEffect, useState } from 'react';
import { formatDate } from '../../../../utils/helper';
import { get } from 'lodash';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { upload } from '../../../../../features/upload';

function UserForm({
  user,
  isEditMode,
  onHanldeEdit,
  onHandleCancel,
  onHandleSave,
}) {
  const dispatch = useDispatch();

  const [image, setImage] = useState(
    get(user, 'profile_picture[0].formats.thumbnail.url') || ''
  );

  const schema = yup.object().shape({
    first_name: yup.string().required('First name is required'),
    surname: yup.string().required('Surname is required'),
    email: yup
      .string()
      .email('Must be a valid email')
      .required('Email Address is required'),
    mobile_number: yup.string().required('Mobile number is required'),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    defaultValues: {
      first_name: get(user, 'first_name') || '',
      surname: get(user, 'surname') || '',
      mobile_number: get(user, 'mobile_number') || '',
      email: get(user, 'email') || '',
    },
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    const transformData = {
      ...data,
      username: data.email,
    };
    onHandleSave(transformData);
  };

  const onCancel = () => {
    reset({
      first_name: get(user, 'first_name') || '',
      surname: get(user, 'surname') || '',
      mobile_number: get(user, 'mobile_number') || '',
      email: get(user, 'email') || '',
    });
    onHandleCancel();
  };

  const getAvatar = async (file) => {
    const formData = new FormData();
    formData.append('files', file);
    formData.append(
      'fileInfo',
      JSON.stringify({ alternativeText: '', caption: '', name: null })
    );
    const uploadResponse = await dispatch(upload(formData)).unwrap();
    setValue('profile_picture', uploadResponse);
  };

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      const img = event.target.files[0];
      setImage(URL.createObjectURL(img));
      getAvatar(img);
    }
  };

  return (
    <div className="flex-lg-row-fluid me-lg-12 mb-5 mb-xxl-8">
      <div className="card">
        <form
          className="form d-flex flex-center"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="card-body py-10 px-6 p-lg-20">
            <div className="row mb-8">
              <label className="col-lg-3 col-form-label">Created at</label>
              <div className="col-lg-9 col-form-label text-gray-600">
                {formatDate(user.created_at)}
              </div>
            </div>
            <div className="row mb-8">
              <label className="col-lg-3 col-form-label">
                Choose new profile picture
                <small className="text-muted">(optional)</small>
              </label>
              <div className="col-lg-9">
                <div
                  className="image-input image-input-empty symbol symbol-circle symbol-100px symbol-lg-130px border-info-3px"
                  data-kt-image-input="true"
                  style={{
                    backgroundImage: `url(${image})`,
                  }}
                >
                  <div className="image-input-wrapper border-circle w-60px w-md-100px h-60px h-md-100px"></div>

                  <label
                    className="btn btn-icon btn-circle btn-active-color-primary w-20px w-md-25px h-20px h-md-25px bg-white shadow"
                    data-kt-image-input-action="change"
                    data-bs-toggle="tooltip"
                    data-bs-dismiss="click"
                    title="Change avatar"
                  >
                    <i className="bi bi-pencil-fill fs-7" />
                    <input
                      type="file"
                      name="avatar"
                      accept=".png, .jpg, .jpeg"
                      disabled={!isEditMode}
                      onChange={onImageChange}
                    />
                    <input type="hidden" name="avatar_remove" />
                  </label>
                  <span
                    className="btn btn-icon btn-circle btn-active-color-primary w-20px w-md-25px h-20px h-md-25px bg-white shadow"
                    data-kt-image-input-action="cancel"
                    data-bs-toggle="tooltip"
                    data-bs-dismiss="click"
                    title="Cancel avatar"
                  >
                    <i className="bi bi-x fs-2" />
                  </span>
                  <span
                    className="btn btn-icon btn-circle btn-active-color-primary w-20px w-md-25px h-20px h-md-25px bg-white shadow"
                    data-kt-image-input-action="remove"
                    data-bs-toggle="tooltip"
                    data-bs-dismiss="click"
                    title="Remove avatar"
                  >
                    <i className="bi bi-x fs-2" />
                  </span>
                </div>
              </div>
            </div>
            <div className="row mb-8">
              <label className="col-lg-3 col-form-label">First name</label>
              <div className="col-lg-9">
                <div className="spinner spinner-sm spinner-primary spinner-right">
                  <input
                    className="form-control form-control-lg form-control-solid"
                    type="text"
                    {...register('first_name')}
                    disabled={!isEditMode}
                  />
                  <span className="invalid-feedback">
                    {errors['first_name']?.message}
                  </span>
                </div>
              </div>
            </div>
            <div className="row mb-8">
              <label className="col-lg-3 col-form-label">Surname</label>
              <div className="col-lg-9">
                <div className="spinner spinner-sm spinner-primary spinner-right">
                  <input
                    className="form-control form-control-lg form-control-solid"
                    type="text"
                    {...register('surname')}
                    disabled={!isEditMode}
                  />
                  <span className="invalid-feedback">
                    {errors['surname']?.message}
                  </span>
                </div>
              </div>
            </div>
            <div className="row mb-8">
              <label className="col-lg-3 col-form-label">Email Address</label>
              <div className="col-lg-9">
                <div className="input-group input-group-lg input-group-solid">
                  <span className="input-group-text pe-0">
                    <i className="la la-at fs-4" />
                  </span>
                  <input
                    className="form-control form-control-lg form-control-solid"
                    type="text"
                    {...register('email')}
                    disabled={!isEditMode}
                    placeholder="mail@example.com"
                  />
                </div>
                <span className="invalid-feedback">
                  {errors['email']?.message}
                </span>
                <div className="form-text">
                  Email will not be publicly displayed.{' '}
                  <a className="fw-bold" href="#">
                    Learn more
                  </a>
                  .
                </div>
              </div>
            </div>
            <div className="row mb-8">
              <label className="col-lg-3 col-form-label">Mobile number</label>
              <div className="col-lg-9">
                <div className="spinner spinner-sm spinner-primary spinner-right">
                  <input
                    className="form-control form-control-lg form-control-solid"
                    type="text"
                    {...register('mobile_number')}
                    disabled={!isEditMode}
                  />
                  <span className="invalid-feedback">
                    {errors['mobile_number']?.message}
                  </span>
                </div>
              </div>
            </div>
            <div className="row justify-content-end">
              <div className="col-lg-9">
                {isEditMode === true ? (
                  <>
                    <button
                      className="btn btn-primary fw-bolder px-6 py-3 me-3"
                      type="submit"
                    >
                      Save Changes
                    </button>
                    <button
                      className="btn btn-color-gray-600 btn-active-light-primary fw-bolder px-6 py-3"
                      type="reset"
                      onClick={onCancel}
                    >
                      Cancel
                    </button>
                  </>
                ) : (
                  <button
                    className="btn btn-primary fw-bolder px-6 py-3 me-3 w-100px"
                    type="reset"
                    onClick={onHanldeEdit}
                    key={'Edit'}
                  >
                    Edit
                  </button>
                )}
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default UserForm;
