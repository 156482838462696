import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { brands } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Link } from 'react-router-dom';

function FooterAuth() {
  return (
    <footer
      className="footer mt-auto py-8 d-flex flex-lg-column"
      id="kt_footer"
    >
      <div className="container d-flex flex-column flex-center flex-lg-stack false">
        <div className="mb-4 mb-lg-0">
          <Link to="#">
            <img src="images/logos/logo-footer.svg" alt="strong logo" width={50} height={19} />
          </Link>
        </div>
        <ul className="menu mb-4 mb-lg-0 fw-bold ms-lg-auto">
          <li className="menu-item">
            <Link className="menu-link text-hover-info" to="#">
              FAQ
            </Link>
          </li>
          <li className="menu-item">
            <Link className="menu-link text-hover-info" to="/term-condition">
              Terms & Conditions
            </Link>
          </li>
          <li className="menu-item">
            <Link className="menu-link text-hover-info" to="/privacy-policy">
              Privacy Policy
            </Link>
          </li>
        </ul>
        <ul className="menu mb-4 mb-lg-0 justify-content-center">
          <li className="menu-item">
            <a
              className="menu-link"
              href="https://facebook.com/VeretisAus"
              target="_blank"
            >
              <FontAwesomeIcon
                icon={brands('facebook-f')}
                size="lg"
                className="text-hover-info"
              />
            </a>
          </li>
          <li className="menu-item">
            <a
              className="menu-link"
              href="https://twitter.com/VeretisAus"
              target="_blank"
            >
              <FontAwesomeIcon
                icon={brands('twitter')}
                size="lg"
                className="text-hover-info"
              />
            </a>
          </li>
          <li className="menu-item">
            <a
              className="menu-link"
              href="https://www.instagram.com/veretis_aus/"
              target="_blank"
            >
              <FontAwesomeIcon
                icon={brands('instagram')}
                size="lg"
                className="text-hover-info"
              />
            </a>
          </li>
          <li className="menu-item">
            <a
              className="menu-link"
              href="https://www.linkedin.com/company/veretis"
              target="_blank"
            >
              <FontAwesomeIcon
                icon={brands('linkedIn-In')}
                size="lg"
                className="text-hover-info"
              />
            </a>
          </li>
        </ul>
      </div>
    </footer>
  );
}

export default FooterAuth;
