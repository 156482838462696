import React from 'react';
import Modal from '@mui/material/Modal';

function PreviewPdfModal({ urlPdfSelected, isOpen, handleClose }) {
  return (
    <div>
      <Modal open={isOpen} onClose={handleClose}>
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">PDF Name</h5>
              <div
                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleClose}
              >
                <i className="bi bi-x fs-2" />
              </div>
            </div>
            <div className="modal-body">
              <embed
                src={urlPdfSelected}
                width="100%"
                height={700}
                alt="pdf"
                pluginspage="http://www.adobe.com/products/acrobat/readstep2.html"
              />
            </div>
            <div className="modal-footer">
              <button
                className="btn btn-light"
                type="button"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default PreviewPdfModal;
