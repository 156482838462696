import React from 'react';
import SVG from 'react-inlinesvg';
import { get } from 'lodash';

function Achievements({ achievements }) {
  const achievementsInrow = [
    {
      value: 3,
    },
    {
      value: 5,
    },
    {
      value: 7,
    },
  ];

  const achievementsActivityCompleted = [
    {
      value: 5,
    },
    {
      value: 10,
    },
    {
      value: 25,
    },
    {
      value: 50,
    },
  ];

  const getClassAchievementsInrow = (value) => {
    if (
      (value === 3 && get(achievements, 'threeDaysInRow') === true) ||
      (value === 5 && get(achievements, 'fiveDaysInRow') === true) ||
      (value === 7 && get(achievements, 'sevenDaysInRow') === true)
    ) {
      return 'list-group-item border-0 text-center px-5';
    }
    return 'list-group-item border-0 text-center px-5 opacity-25';
  };

  const getClassAchievementsActivityCompleted = (value) => {
    if (
      (value === 5 && get(achievements, 'totalCompletedActivities') >= value) ||
      (value === 10 &&
        get(achievements, 'totalCompletedActivities') >= value) ||
      (value === 25 &&
        get(achievements, 'totalCompletedActivities') >= value) ||
      (value === 50 && get(achievements, 'totalCompletedActivities') >= value)
    ) {
      return 'list-group-item border-0 text-center px-5';
    }
    return 'list-group-item border-0 text-center px-5 opacity-25';
  };

  return (
    <div className="card card-stretch mb-5 mb-xxl-8 pb-5">
      <div className="card-header border-0 pt-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bolder text-dark fs-2">
            Achievements
          </span>
          <span className="text-muted mt-2 fw-bold fs-5 ellipsis-1row">
            Collect your streaks here!
          </span>
        </h3>
        <div className="card-toolbar overflow-auto">
          <div className="d-flex">
            <ul className="list-group list-group-flush list-group-horizontal flex-column-fluid">
              {achievementsInrow.map(({ value }, index) => (
                <li key={index} className={getClassAchievementsInrow(value)}>
                  <div className="d-flex flex-center flex-column">
                    <span className="svg-icon svg-icon-2x  svg-icon-warning">
                      <SVG src="/images/icons/achievement.svg" />
                    </span>
                    <small className="text-warning fs-8">{`${value} Days in a row`}</small>
                  </div>
                </li>
              ))}
            </ul>
            <ul className="list-group list-group-flush list-group-horizontal flex-column-fluid">
              {achievementsActivityCompleted.map(({ value }, index) => (
                <li
                  key={index}
                  className={getClassAchievementsActivityCompleted(value)}
                >
                  <div className="d-flex flex-center flex-column">
                    <span className="svg-icon svg-icon-2x  svg-icon-warning">
                      <SVG src="/images/icons/achievement.svg" />
                    </span>
                    <small className="text-warning fs-8">
                      {`${value} activities completed`}
                    </small>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Achievements;
