import React, { userState, useEffect } from 'react';
import Achievements from './Components/Achievements';
import BookAppointment from './Components/BookAppointment';
import Activities from './Components/Activities';
import PulseCheck from './Components/PulseCheck';
import DailyQuote from './Components/DailyQuote';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchClientDashboardInfo,
  selectAchievements,
  selectDailyQuote,
  selectListActivities,
} from '../../../../features/client/dashboard';
import { selectAuthUser } from '../../../../features/user/auth';
import { get } from 'lodash';

const Dashboard2 = () => {
  const dispatch = useDispatch();
  const listActivitiesClientDashboard = useSelector(selectListActivities);
  const dailyQuote = useSelector(selectDailyQuote);
  const authUser = useSelector(selectAuthUser);
  const achievements = useSelector(selectAchievements);

  useEffect(() => {
    dispatch(fetchClientDashboardInfo());
  }, []);

  return (
    <div className="d-flex flex-column flex-column-fluid">
      <div className="content fs-6 d-flex flex-column-fluid" id="kt_content">
        <div className="container">
          <div className="mt-6 mb-12 mt-lg-0">
            <h3 className="fs-2x text-center text-md-start">
              Hey {authUser.first_name}!{' '}
              <span className="text-primary">Nicely done!</span>
            </h3>
          </div>
          <Achievements achievements={achievements} />
          <div className="row">
            <div className="col-md-6">
              <BookAppointment
                clinicianName={`${get(
                  authUser,
                  'clinician.first_name',
                  ''
                )} ${get(authUser, 'clinician.surname', '')}`}
                location={get(authUser, 'clinician_location.name', '')}
              />
              <div className="d-md-none">
                <Activities
                  listActivitiesClientDashboard={listActivitiesClientDashboard}
                />
              </div>
              <PulseCheck />
              <DailyQuote dailyQuote={dailyQuote} />
            </div>
            <div className="col-md-6 d-none d-md-block">
              <Activities
                listActivitiesClientDashboard={listActivitiesClientDashboard}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard2;
