import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchKnowledges,
  selectListKnowledge,
} from '../../../../features/client/knowledges';
import KnowledgeCard from './Components/KnowledgeCard';
import PreviewPdfModal from './Components/PreviewPdfModal';
import { get } from 'lodash';

const Knowledgeable = () => {
  const dispatch = useDispatch();
  const knowledgesList = useSelector(selectListKnowledge);

  const [urlPdfSelected, SetUrlPdfSelected] = useState();
  const [isOpenPreviewModal, SetOpenPreviewModal] = useState(false);

  useEffect(() => {
    dispatch(fetchKnowledges());
  }, []);

  const onPreviewPdf = (url) => {
    SetUrlPdfSelected(url);
  };

  return (
    <>
      <div className="d-flex flex-column flex-column-fluid">
        <div className="content fs-6 d-flex flex-column-fluid" id="kt_content">
          <div className="container">
            <div className="card">
              <div className="card-body py-10">
                <div className="row g-5 g-xxl-8">
                  {knowledgesList.map((item, index) => (
                    <KnowledgeCard
                      key={index}
                      srcImage={get(item, 'cover.url')}
                      title={item.title}
                      description={item.description}
                      pdfFile={get(item, 'file.url')}
                      onPreviewPdf={(url) => {
                        SetOpenPreviewModal(true);
                        onPreviewPdf(url);
                      }}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <PreviewPdfModal
        urlPdfSelected={urlPdfSelected}
        isOpen={isOpenPreviewModal}
        handleClose={() => SetOpenPreviewModal(false)}
      />
    </>
  );
};

export default Knowledgeable;
