import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Upload from '../api/upload';
import { parseError } from '../app/utils/helper';

export const upload = createAsyncThunk(
  'upload/upload',
  async (file, { rejectWithValue }) => {
    try {
      const response = await Upload.upload(file);
      return response.data;
    } catch (error) {
      return rejectWithValue(parseError(error));
    }
  }
);

const initialState = {
  uploaded: null,
};

const uploadSlice = createSlice({
  name: 'upload',
  initialState,
  reducers: {},
  extraReducers: {
    [upload.fulfilled]: (state, action) => {
      state.uploaded = action.payload;
    },
  },
});

export default uploadSlice.reducer;
