import React from 'react';

function BookAppointment({ clinicianName, location }) {
  return (
    <div className="card card-stretch mb-5 mb-xxl-8 pb-5">
      <div className="card-header border-0 pt-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bolder text-dark fs-2">
            {clinicianName}
          </span>
          <span className="text-muted mt-2 fw-bold fs-5 ellipsis-1row">
            {location}
          </span>
        </h3>
        <div className="card-toolbar flex-row-fluid flex-sm-row-auto">
          <a
            className="btn btn-info w-100 fw-bolder d-flex flex-center py-5"
            href="https://veretis-group.au2.cliniko.com/bookings?appointment_type_id[]=569775020276980285&appointment_type_id[]=569775335982242367#service"
            target="_blank"
            rel="noopener noreferrer"
          >
            Book an appointment
            <i className="far fa-calendar ms-3 text-gray-500 fs-4" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default BookAppointment;
