import React from 'react';
import { Editor } from '@tinymce/tinymce-react';

function TextEditor({ onChange, value }) {
  const handleEditorChange = (editor) => onChange(editor);
  return (
    <div>
      <Editor
        apiKey="9f27od4t2eba6d0o988vnqdpeekqryczjteikzcmkl06izpy"
        onEditorChange={handleEditorChange}
        value={value}
        init={{
          height: 500,
          plugins: [
            'advlist autolink lists link image charmap print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media table paste code help wordcount',
          ],
          toolbar:
            'undo redo | formatselect | ' +
            'bold italic backcolor | image media | alignleft aligncenter ' +
            'alignright alignjustify | bullist numlist outdent indent | ' +
            'removeformat | help',
          content_style:
            'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
        }}
      />
    </div>
  );
}

export default TextEditor;
