import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Modal from '@mui/material/Modal';
import { get } from 'lodash';

import {
  fetchLocations,
  createLocation,
  updateLocation,
  deleteLocation,
} from '../../../../../features/admin/location';
import ModalConfirm from '../../../../components/Modal/ModalConfirm';
import FormLocation from './form';

const Locations = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [locationSelected, setLocationSelected] = useState(null);
  const [isError, setIsError] = useState(false);
  const dispatch = useDispatch();
  const { locations, locationUpdated, locationCreated, locationDeleted } =
    useSelector((state) => state.location);
  useEffect(() => {
    dispatch(fetchLocations());
  }, [locationCreated?.id, locationUpdated?.id, locationDeleted?.id]);

  const handleClose = () => {
    setLocationSelected(null);
    return setIsOpen(false);
  };

  const onDeleteLocation = () => {
    const locationId = locationSelected?.id;
    if (locationId) {
      dispatch(deleteLocation({ id: locationId }));
      setIsOpen(false);
      return setLocationSelected(null);
    }
    return setIsOpen(false);
  };

  const handleSubmitLocation = (data) => {
    const locationId = locationSelected?.id;
    const body = {
      ...data,
    };
    if (locationId) {
      dispatch(updateLocation({ id: locationId, body }));
      return setLocationSelected(null);
    }
    dispatch(createLocation(body));
    return setIsOpen(false);
  };

  return (
    <>
      <div className="card-header border-0 pt-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bolder text-dark fs-2">Locations</span>
        </h3>
        <div className="card-toolbar">
          <a
            className="btn btn-primary ms-auto"
            onClick={() => setIsOpen(true)}
            data-bs-toggle="modal"
          >
            <i className="fas fa-plus me-2 opacity-50" />
            Add New Location
          </a>
        </div>
      </div>

      <div className="card-body pt-12 mt-n3">
        <ul className="list-group list-group-flush">
          {locations?.length > 0 &&
            locations.map((location) => (
              <li className="list-group-item py-5 px-0" key={location.id}>
                <div className="d-flex align-items-center">
                  <span className="text-dark text-hover-primary mb-0">
                    {location.name}
                  </span>
                  <div className="ms-auto min-w-100px text-end">
                    <button
                      className="btn btn-icon btn-primary btn-sm"
                      onClick={() => setLocationSelected(location)}
                    >
                      <i className="bi bi-pencil fs-6"></i>
                    </button>
                  </div>
                </div>
              </li>
            ))}
        </ul>
      </div>
      <Modal open={isOpen || !!locationSelected?.id} onClose={handleClose}>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <FormLocation
              handleClose={handleClose}
              onSubmit={handleSubmitLocation}
              locationSelected={locationSelected}
              onDeleteLocation={onDeleteLocation}
            />
          </div>
        </div>
      </Modal>
      {isError && (
        <ModalConfirm
          isOpen={!!isError}
          handleClose={() => setIsError(false)}
          confirm={() => setIsError(false)}
          modalTitle={'Create Cover Image Failed'}
          modalNotes={'This action can not be undone!'}
        />
      )}
    </>
  );
};

export default Locations;
