import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ClientForm from './Components/ClientForm';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchChartInfoByClientId,
  fetchLocation,
  fetchRole,
  fetchUsers,
  getUserById,
  resetActivitiesByClientId,
  resetChartInfoByClientId,
  resetUserDetail,
  selectLocations,
  selectRoles,
  selectUserDetail,
  selectUserList,
  updateUserById,
} from '../../../../../features/user';

function UserDetail() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const userDetail = useSelector(selectUserDetail);

  useEffect(() => {
    if (id) {
      dispatch(getUserById(id));
    }

    return () => {
      dispatch(resetUserDetail());
    };
  }, [id]);

  const onHandleSubmit = (userData) => {
    const body = { ...userData, appleId: userData?.appleId || null };
    if (id) dispatch(updateUserById({ body, id }));
  };

  return <ClientForm onHandleSubmit={onHandleSubmit} />;
}

export default UserDetail;
