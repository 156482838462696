import React from 'react';
import './styles.scss';
import { formatDateTime } from '../../../../../utils/helper';

const IncidentReportView = ({ time, description, lat, lng, images }) => {
  return (
    <>
      <div className="report-description-title">Time</div>
      <div className="report-description">{formatDateTime(time)}</div>
      <div className="report-description-title">Description</div>
      <div className="report-description">{description}</div>
      <div style={{ marginTop: 30 }} className="report-description-title">
        Incident Location
      </div>
      <img
        style={{ marginTop: 5 }}
        className="image-map"
        src={`https://maps.googleapis.com/maps/api/staticmap?center=${lat},${lng}&markers=${lat},${lng}&zoom=15&size=1000x500&key=AIzaSyC697tRMOHzfTCtJmtLaWCRDVPpbIh_vuc`}
        width="100%"
        height="300"
      />
      <div style={{ marginTop: 30 }} className="report-description-title">
        Media
      </div>
      {images.map((image) => (
        <img className="media" src={image} width="100%" />
      ))}
    </>
  );
};

export default IncidentReportView;
