import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AdminDashboard from '../../api/admin/dashboard';
import { parseError } from '../../app/utils/helper';

export const fetchAdminDashboardInfo = createAsyncThunk(
  'adminDashboard/totalInfo',
  async (_, { rejectWithValue }) => {
    try {
      const response = await AdminDashboard.fetchAdminDashboardInfo();
      return response.data;
    } catch (error) {
      return rejectWithValue(parseError(error));
    }
  }
);

const dashBoardSlice = createSlice({
  name: 'adminDashboard',
  initialState: {
    total: {},
  },
  extraReducers: {
    [fetchAdminDashboardInfo.fulfilled]: (state, action) => {
      state.total = action.payload;
    },
  },
});

export const selectAdminTotal = (state) => state.adminDashboard.total;

export default dashBoardSlice.reducer;
