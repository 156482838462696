import React from 'react';

function DailyQuote({ dailyQuote }) {
  return (
    <div className="card card-stretch mb-5 mb-xxl-8 pb-3 bg-accent">
      <div className="card-header border-0 pt-5">
        <h3 className="card-title align-items-start flex-column text-break">
          <span className="card-label fw-bolder text-dark fs-2">
            {dailyQuote?.name}
          </span>
        </h3>
      </div>
      <div className="card-body mt-n3">
        <p className="fw-bold text-gray-800 fs-4">{dailyQuote?.quote}</p>
        <p className="fw-bolder text-gray-800 mb-0 fs-3 text-end">
          {dailyQuote?.created_by?.firstname} {dailyQuote?.created_by?.lastname}
        </p>
      </div>
    </div>
  );
}

export default DailyQuote;
