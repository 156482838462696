import http from '../../services/http-common';

export default class Knowledges {
  static fetchKnowledges() {
    return http.get('/knowledges');
  }

  static createKnowledge(body) {
    return http.post('/knowledges', body);
  }

  static updateKnowledge(id, body) {
    return http.put(`/knowledges/${id}`, body);
  }

  static deleteKnowledge(id) {
    return http.delete(`/knowledges/${id}`);
  }
}
