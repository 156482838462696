import * as React from 'react';
import Modal from '@mui/material/Modal';
import AuthorisedUser from '../../../../../api/admin/authorisedUser';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAuthorisedUsers } from '../../../../../features/admin/authorisedUser';
import { Alert } from '@mui/material';

const ModalCreate = (props) => {
  const { onCancel, visible } = props;
  const dispatch = useDispatch();
  const [name, setName] = React.useState();
  const [message, setMessage] = React.useState();
  const activePage = useSelector((state) => state.authorisedUser.activePage);
  const pageSize = useSelector((state) => state.authorisedUser.pageSize);

  const handCreateAuthorisedUser = async () => {
    try {
      await AuthorisedUser.createAuthorisedUser({
        data: {
          name,
        },
      });
      getListAuthorisedUsers();
      onCancel();
    } catch (error) {
      setMessage(error?.data?.error?.message);
    }
  };

  const getListAuthorisedUsers = () => {
    const queryParams = {
      'pagination[page]': activePage,
      'pagination[pageSize]': pageSize,
    };
    dispatch(fetchAuthorisedUsers(queryParams));
  };

  return (
    <div>
      <Modal open={visible} onClose={onCancel}>
        <div className="modal-content modal-centered">
          <div className="modal-body">
            <div className="pt-5">
              {message && (
                <Alert className="mb-3" severity="error">
                  {message}
                </Alert>
              )}
            </div>
            <div className="row">
              <div className="col-xxl-2 col-xl-2 col-lg-3 col-md-3 col-sm-12 col-12 d-flex align-items-center">
                <label>Name</label>
              </div>
              <div className="col-xxl-10 col-xl-10 col-lg-9 col-md-9 col-sm-12 col-12">
                <input
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="form-control"
                />
              </div>
            </div>
          </div>
          <div className="modal-footer border-0 justify-content-end">
            <button
              className="w-100px btn btn-light"
              type="button"
              data-bs-dismiss="modal"
              onClick={onCancel}
            >
              Cancel
            </button>
            <button
              className="w-100px btn btn-light-primary"
              type="button"
              onClick={handCreateAuthorisedUser}
            >
              Save
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ModalCreate;
