import http from '../../services/http-common';

export default class Location {
  static fetchLocations() {
    return http.get('/clinician-locations');
  }

  static createLocation(body) {
    return http.post('/clinician-locations', body);
  }

  static updateLocation(id, body) {
    return http.put(`/clinician-locations/${id}`, body);
  }

  static deleteLocation(id) {
    return http.delete(`/clinician-locations/${id}`);
  }
}
