import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import DailyQuotes from '../../api/admin/dailyQuotes';
import { parseError } from '../../app/utils/helper';

export const fetchDailyQuotesMoods = createAsyncThunk(
  'admin/fetch-daily-quotes-moods',
  async (_, { rejectWithValue }) => {
    try {
      const response = await DailyQuotes.fetchDailyQuotesMoods();
      return response.data;
    } catch (error) {
      return rejectWithValue(parseError(error));
    }
  }
);

export const createDailyQuote = createAsyncThunk(
  'admin/create-daily-quote',
  async (body, { rejectWithValue }) => {
    try {
      const { data } = await DailyQuotes.createDailyQuote(body);
      return { data, message: 'Create daily quote successfully' };
    } catch (error) {
      return rejectWithValue(parseError(error));
    }
  }
);

export const updateQuote = createAsyncThunk(
  'admin/update-quote',
  async ({ quoteId, body }, { rejectWithValue }) => {
    try {
      const { data } = await DailyQuotes.updateQuote(quoteId, body);
      return { data, message: 'Update daily quote successfully' };
    } catch (error) {
      return rejectWithValue(parseError(error));
    }
  }
);

export const deleteQuote = createAsyncThunk(
  'admin/delete-quote',
  async ({ quoteId }, { rejectWithValue }) => {
    try {
      const { data } = await DailyQuotes.deleteQuote(quoteId);
      return { data, message: 'Delete daily quote successfully' };
    } catch (error) {
      return rejectWithValue(parseError(error));
    }
  }
);

const dailyQuotesSlices = createSlice({
  name: 'dailyQuotes',
  initialState: {
    moods: [],
    dailyQuoteCreated: null,
    quoteUpdated: null,
    quoteDeleted: null,
    currentTab: {
      title: 'Daily quotes',
      tab: 'daily-quotes',
    },
  },
  reducers: {
    setCurrentTab: (state, action) => {
      state.currentTab = action.payload;
    },
  },
  extraReducers: {
    [fetchDailyQuotesMoods.fulfilled]: (state, action) => {
      state.moods = action.payload;
    },
    [createDailyQuote.fulfilled]: (state, action) => {
      state.dailyQuoteCreated = action.payload.data;
    },
    [updateQuote.fulfilled]: (state, action) => {
      state.quoteUpdated = action.payload.data;
    },
    [deleteQuote.fulfilled]: (state, action) => {
      state.quoteDeleted = action.payload.data;
    },
  },
});

export const { setCurrentTab } = dailyQuotesSlices.actions;

export const getTitle = (state) => state.dailyQuotes.currentTab.title;
export const getTab = (state) => state.dailyQuotes.currentTab.tab;

export default dailyQuotesSlices.reducer;
