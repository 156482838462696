import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import React from 'react';
import { useForm } from 'react-hook-form';
import { get } from 'lodash';

function FormFaqCategory({
  handleClose,
  onSubmit,
  categorySelected,
  onDeleteCategory,
}) {
  const schema = yup.object().shape({
    category: yup.string().required('Title is required'),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="modal-body">
        <div className="row mb-8">
          <div>
            <div className="row mb-8">
              <label className="col-lg-3 col-form-label">Title</label>
              <div className="col-lg-9">
                <div className="spinner spinner-sm spinner-primary spinner-right">
                  <input
                    className="form-control form-control-lg"
                    type="text"
                    defaultValue={get(categorySelected, 'category', '')}
                    {...register('category')}
                  />
                  <span className="invalid-feedback">
                    {errors['category']?.message}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-footer">
        {categorySelected?.id && (
          <button
            className="btn btn-link me-auto text-danger"
            onClick={onDeleteCategory}
            type="button"
          >
            Remove
          </button>
        )}
        <button className="btn btn-light" type="button" onClick={handleClose}>
          Cancel
        </button>
        <button className="btn btn-light-primary">Save</button>
      </div>
    </form>
  );
}

export default FormFaqCategory;
