import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch } from 'react-redux';
import { get } from 'lodash';
import { upload } from '../../../../../../features/upload';
import { UserType } from '../../../../../utils/constants';
import useDidMountEffect from '../../../../../../hooks/useDidMountEffect ';

function UserForm({
  onHandleSubmit,
  onHandleCancel,
  locations,
  roles,
  clinician,
}) {
  const dispatch = useDispatch();

  const [image, setImage] = useState();

  useEffect(() => {
    reset({
      first_name: '',
      surname: '',
      clinician_location: get(locations, '[0].id', ''),
      mobile_number: '',
      role: get(roles, '[0].id', ''),
      email: '',
      profile_picture: '',
      username: '',
      clinician: get(clinician, '[0].id', ''),
    });
  }, [locations, roles]);

  const schema = yup.object().shape({
    first_name: yup.string().required('First name is required'),
    surname: yup.string().required('Surname is required'),
    email: yup
      .string()
      .email('Must be a valid email')
      .required('Email Address is required'),
    mobile_number: yup.string().required('Mobile number is required'),
    clinician_location: yup.string().required('Clinician location is required'),
    role: yup.string().required('Account type is required'),
  });

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    // check condition
    if (data.role == UserType.Client && !data.clinician) {
      return;
    } else {
      const transformData = {
        ...data,
        username: data.email,
        blocked: false,
        clinician: data.role == UserType.Client ? data.clinician : null,
      };
      onHandleSubmit(transformData);
    }
  };

  const getAvatar = async (file) => {
    const formData = new FormData();
    formData.append('files', file);
    formData.append(
      'fileInfo',
      JSON.stringify({ alternativeText: '', caption: '', name: null })
    );
    const uploadResponse = await dispatch(upload(formData)).unwrap();
    setValue('profile_picture', uploadResponse);
  };

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      const img = event.target.files[0];
      setImage(URL.createObjectURL(img));
      getAvatar(img);
    }
  };

  useDidMountEffect(() => {
    const filteredClinician = clinician.filter(
      (item) =>
        get(item, 'clinician_location.id') == watch('clinician_location')
    );
    if (filteredClinician) {
      setValue('clinician', get(filteredClinician[0], 'id'));
    } else {
      setValue('clinician', '');
    }
  }, [watch('clinician_location'), watch('role')]);

  return (
    <div className="d-flex flex-column flex-column-fluid">
      <div className="content fs-6 d-flex flex-column-fluid" id="kt_content">
        <div className="container">
          <div className="card">
            <div className="card-body">
              <form
                className="form d-flex flex-center"
                onSubmit={handleSubmit(onSubmit)}
              >
                <div className="card-body mw-800px py-20">
                  <div className="row mb-8">
                    <label className="col-lg-3 col-form-label">
                      Upload profile picture
                      <small className="text-muted">(optional)</small>
                    </label>
                    <div className="col-lg-9">
                      <div
                        className="image-input image-input-empty symbol symbol-circle symbol-100px symbol-lg-130px border-info-3px"
                        data-kt-image-input="true"
                        style={{
                          backgroundImage: `url(${image})`,
                        }}
                      >
                        <div className="image-input-wrapper border-circle w-60px w-md-100px h-60px h-md-100px"></div>
                        <label
                          className="btn btn-icon btn-circle btn-active-color-primary w-20px w-md-25px h-20px h-md-25px bg-white shadow"
                          data-kt-image-input-action="change"
                          data-bs-toggle="tooltip"
                          data-bs-dismiss="click"
                          title="Change avatar"
                        >
                          <i className="bi bi-pencil-fill fs-7" />
                          <input
                            type="file"
                            name="avatar"
                            accept=".png, .jpg, .jpeg"
                            onChange={onImageChange}
                          />
                          <input type="hidden" name="avatar_remove" />
                        </label>
                        <span
                          className="btn btn-icon btn-circle btn-active-color-primary w-20px w-md-25px h-20px h-md-25px bg-white shadow"
                          data-kt-image-input-action="remove"
                          data-bs-toggle="tooltip"
                          data-bs-dismiss="click"
                          title="Remove avatar"
                        >
                          <i className="bi bi-x fs-2" />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-8">
                    <label className="col-lg-3 col-form-label">
                      First name
                    </label>
                    <div className="col-lg-9">
                      <div className="spinner spinner-sm spinner-primary spinner-right">
                        <input
                          className="form-control form-control-lg form-control-solid"
                          type="text"
                          {...register('first_name')}
                        />
                        <span className="invalid-feedback">
                          {errors['first_name']?.message}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-8">
                    <label className="col-lg-3 col-form-label">Surname</label>
                    <div className="col-lg-9">
                      <div className="spinner spinner-sm spinner-primary spinner-right">
                        <input
                          className="form-control form-control-lg form-control-solid"
                          type="text"
                          {...register('surname')}
                        />
                        <span className="invalid-feedback">
                          {errors['surname']?.message}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-8">
                    <label className="col-lg-3 col-form-label">
                      Email Address
                    </label>
                    <div className="col-lg-9">
                      <div className="input-group input-group-lg input-group-solid">
                        <span className="input-group-text pe-0">
                          <i className="la la-at fs-4" />
                        </span>
                        <input
                          className="form-control form-control-lg form-control-solid"
                          type="text"
                          {...register('email')}
                          placeholder="mail@example.com"
                        />
                      </div>
                      <span className="invalid-feedback">
                        {errors['email']?.message}
                      </span>
                      <div className="form-text">
                        Email will not be publicly displayed.
                        <a className="fw-bold" href="#">
                          Learn more
                        </a>
                        .
                      </div>
                    </div>
                  </div>
                  <div className="row mb-8">
                    <label className="col-lg-3 col-form-label">
                      Mobile number
                    </label>
                    <div className="col-lg-9">
                      <div className="spinner spinner-sm spinner-primary spinner-right">
                        <input
                          className="form-control form-control-lg form-control-solid"
                          type="text"
                          {...register('mobile_number')}
                        />
                        <span className="invalid-feedback">
                          {errors['mobile_number']?.message}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-8">
                    <label className="col-lg-3 col-form-label">
                      Account type
                    </label>
                    <div className="col-lg-9">
                      <select
                        className="form-select form-select-lg form-select-solid"
                        data-control="select2"
                        data-placeholder="Select Type..."
                        data-hide-search
                        {...register('role')}
                      >
                        {roles.map((item, index) => (
                          <option key={index} value={item.id}>
                            {item.name}
                          </option>
                        ))}
                      </select>
                      <span className="invalid-feedback">
                        {errors['role']?.message}
                      </span>
                    </div>
                  </div>
                  {watch('role') == UserType.Client && (
                    <div className="row mb-8">
                      <label className="col-lg-3 col-form-label">
                        Clinician
                      </label>
                      <div className="col-lg-9">
                        <select
                          className="form-select form-select-lg form-select-solid"
                          data-control="select2"
                          data-placeholder="Select Clinician..."
                          data-hide-search
                          {...register('clinician')}
                        >
                          {clinician.map((item, index) => (
                            <option key={index} value={item.id}>
                              {`${item.first_name} ${item.surname}`}
                            </option>
                          ))}
                        </select>
                        {!watch('clinician') && (
                          <span className="invalid-feedback">
                            Clinician is required
                          </span>
                        )}
                      </div>
                    </div>
                  )}

                  <div className="row mb-8">
                    <label className="col-lg-3 col-form-label">
                      Clinician location
                    </label>
                    <div className="col-lg-9">
                      <select
                        className="form-select form-select-lg form-select-solid"
                        data-control="select2"
                        data-placeholder="Select Location..."
                        data-hide-search
                        {...register('clinician_location')}
                      >
                        {locations.map((item, index) => (
                          <option key={index} value={item.id}>
                            {item.name}
                          </option>
                        ))}
                      </select>
                      <span className="invalid-feedback">
                        {errors['clinician_location']?.message}
                      </span>
                    </div>
                  </div>
                  <div className="row justify-content-end">
                    <div className="col-lg-9">
                      <button
                        className="btn btn-primary fw-bolder px-6 py-3 me-3"
                        type="submit"
                      >
                        Save Changes
                      </button>
                      <button
                        className="btn btn-color-gray-600 btn-active-light-primary fw-bolder px-6 py-3"
                        onClick={onHandleCancel}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserForm;
