import React from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

const FormDailyQuotes = (props) => {
  const schema = yup.object().shape({
    name: yup.string().required('Daily Name is required'),
    quote: yup
      .string()
      .required('Daily Quote is required')
      .max(150, 'Daily Quote max length is 150 characters'),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const triggerSubmit = (data) => {
    props.onSubmit(data);
  };

  return (
    <form onSubmit={handleSubmit(triggerSubmit)}>
      <div>
        <div className="row mb-8">
          <label className="col-lg-3 col-form-label">Name</label>
          <div className="col-lg-9">
            <div className="spinner spinner-sm spinner-primary spinner-right">
              <input
                className="form-control form-control-lg"
                type="text"
                defaultValue={props.quoteSelected?.name}
                {...register('name')}
              />
              <span className="invalid-feedback">
                {errors['name']?.message}
              </span>
            </div>
          </div>
        </div>
        <div className="row mb-8">
          <label className="col-lg-3 col-form-label">Quote</label>
          <div className="col-lg-9">
            <div className="spinner spinner-sm spinner-primary spinner-right">
              <textarea
                className="form-control form-control-lg"
                rows="10"
                type="text"
                defaultValue={props.quoteSelected?.quote}
                {...register('quote')}
              />
              <span className="invalid-feedback">
                {errors['quote']?.message}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-footer">
        {props.quoteSelected?.id && (
          <button
            className="btn btn-link me-auto text-danger"
            onClick={props.onDeleteQuote}
            type="button"
          >
            Remove
          </button>
        )}
        <button
          className="btn btn-light"
          onClick={props.handleClose}
          type="button"
        >
          Cancel
        </button>
        <button className="btn btn-light-primary">Save</button>
      </div>
    </form>
  );
};

export default FormDailyQuotes;
