import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { get } from 'lodash';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { selectAvatarResponse } from '../../../../../../features/user';
import { SingleSelect } from '../../../../../components/Selects';
import UploadLicenseImage from './UploadLicenseImage';
import { upload } from '../../../../../../features/upload';
import {
  UserTransmissions,
  AccountTypes,
} from '../../../../../utils/constants';

function DetailTab({ onHandleSubmit, onHandleCancel, userDetail }) {
  const dispatch = useDispatch();

  const avatarResponse = useSelector(selectAvatarResponse);

  const [frontImage, setFrontImage] = useState(0);
  const [backImage, setBackImage] = useState(0);

  const schema = yup.object().shape({
    firstName: yup.string().required('First name is required'),
    lastName: yup.string().required('Surname is required'),
    username: yup.string().required('Username is required'),
    email: yup
      .string()
      .email('Must be a valid email')
      .required('Email Address is required'),
    dob: yup.date().required('Date of birth is required'),
    address: yup.string().required('Address is required'),
    tranmission: yup.string().required('Transmission is required'),
    license: yup.string().required('License is required'),
    appleId: yup.string().nullable(),
    type: yup.string().required('Type is required'),
    displayName: yup.string().required('Display name is required'),
    expiryDate: yup.date().required('expiry date is required'),
  });

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    getValues,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (userDetail) {
      setValue('firstName', get(userDetail, 'firstName'));
      setValue('lastName', get(userDetail, 'lastName'));
      setValue('username', get(userDetail, 'username'));
      setValue('email', get(userDetail, 'email'));
      setValue('dob', get(userDetail, 'dob'));
      setValue('address', get(userDetail, 'address'));
      setValue('tranmission', get(userDetail, 'tranmission'));
      setValue('license', get(userDetail, 'license'));
      setValue('appleId', get(userDetail, 'appleId'));
      setValue('type', get(userDetail, 'type'));
      setValue('displayName', get(userDetail, 'displayName'));
      setValue('expiryDate', get(userDetail, 'expiryDate'));
    }
  }, [userDetail]);

  const onSubmit = (data) => {
    // check condition
    let dataSubmit = { ...data };
    if (avatarResponse) {
      dataSubmit = { ...dataSubmit, avatar: get(avatarResponse, '[0].id') };
    }
    if (frontImage) {
      dataSubmit = { ...dataSubmit, front: frontImage };
    }
    if (backImage) {
      dataSubmit = { ...dataSubmit, back: backImage };
    }
    onHandleSubmit(dataSubmit);
  };

  const handleDeactivate = () => {
    onHandleSubmit({ blocked: true });
  };

  const handleSelectValue = (value, type) => {
    switch (type) {
      case 'tranmission':
        setValue('tranmission', get(value, 'tranmission'));
        break;
      case 'type':
        setValue('type', get(value, 'type'));
        break;
    }
  };

  const handleMappingTransmission = () => {
    return UserTransmissions.map((tranmission) => {
      return { tranmission, title: tranmission };
    });
  };

  const handleMappingAccountType = () => {
    return AccountTypes.map((type) => {
      return { type, title: type };
    });
  };

  const uploadImage = async (file) => {
    const formData = new FormData();
    formData.append('files', file);
    formData.append(
      'fileInfo',
      JSON.stringify({ alternativeText: '', caption: '', name: null })
    );
    try {
      const uploadResponse = await dispatch(upload(formData)).unwrap();
      return uploadResponse;
    } catch (error) {
      console.log(error);
    }
  };

  const getFrontImage = async (file) => {
    const imageResource = await uploadImage(file);
    return setFrontImage(
      !!imageResource?.length && get(imageResource, '0.id', '')
    );
  };

  const getBackImage = async (file) => {
    const imageResource = await uploadImage(file);
    return setBackImage(
      !!imageResource?.length && get(imageResource, '0.id', '')
    );
  };

  return (
    <>
      <form
        className="form d-flex flex-center"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="card-body mw-800px py-20">
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">First name</label>
            <div className="col-lg-9">
              <div className="spinner spinner-sm spinner-primary spinner-right">
                <input
                  className="form-control form-control-lg form-control-solid"
                  type="text"
                  {...register('firstName')}
                />
                <span className="invalid-feedback">
                  {errors['firstName']?.message}
                </span>
              </div>
            </div>
          </div>

          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">Last Name</label>
            <div className="col-lg-9">
              <div className="spinner spinner-sm spinner-primary spinner-right">
                <input
                  className="form-control form-control-lg form-control-solid"
                  type="text"
                  {...register('lastName')}
                />
                <span className="invalid-feedback">
                  {errors['lastName']?.message}
                </span>
              </div>
            </div>
          </div>

          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">User Name</label>
            <div className="col-lg-9">
              <div className="spinner spinner-sm spinner-primary spinner-right">
                <input
                  className="form-control form-control-lg form-control-solid"
                  type="text"
                  {...register('username')}
                />
                <span className="invalid-feedback">
                  {errors['username']?.message}
                </span>
              </div>
            </div>
          </div>

          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">Email Address</label>
            <div className="col-lg-9">
              <div className="input-group input-group-lg input-group-solid">
                <span className="input-group-text pe-0">
                  <i className="la la-at fs-4" />
                </span>
                <input
                  className="form-control form-control-lg form-control-solid"
                  type="text"
                  {...register('email')}
                  placeholder="mail@example.com"
                />
              </div>
              <span className="invalid-feedback">
                {errors['email']?.message}
              </span>
              <div className="form-text">
                Email will not be publicly displayed.{' '}
                <a className="fw-bold" href="#">
                  Learn more
                </a>
                .
              </div>
            </div>
          </div>

          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">Date of birth</label>
            <div className="col-lg-9">
              <div className="spinner spinner-sm spinner-primary spinner-right">
                <input
                  className="form-control form-control-lg form-control-solid"
                  type="date"
                  {...register('dob')}
                />
                <span className="invalid-feedback">
                  {errors['dob']?.message}
                </span>
              </div>
            </div>
          </div>

          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">Address</label>
            <div className="col-lg-9">
              <div className="spinner spinner-sm spinner-primary spinner-right">
                <input
                  className="form-control form-control-lg form-control-solid"
                  type="text"
                  {...register('address')}
                />
                <span className="invalid-feedback">
                  {errors['address']?.message}
                </span>
              </div>
            </div>
          </div>

          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">Transmission</label>
            <div className="col-lg-9">
              <SingleSelect
                data={handleMappingTransmission()}
                defaultValue={handleMappingTransmission().find(
                  (item) => item.tranmission === get(userDetail, 'tranmission')
                )}
                getData={(value) => handleSelectValue(value, 'tranmission')}
                placeholder={'Select Tranmission...'}
              />
            </div>
          </div>

          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">License Number</label>
            <div className="col-lg-9">
              <div className="spinner spinner-sm spinner-primary spinner-right">
                <input
                  className="form-control form-control-lg form-control-solid"
                  type="text"
                  {...register('license')}
                />
                <span className="invalid-feedback">
                  {errors['license']?.message}
                </span>
              </div>
            </div>
          </div>

          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">Apple ID</label>
            <div className="col-lg-9">
              <div className="spinner spinner-sm spinner-primary spinner-right">
                <input
                  className="form-control form-control-lg form-control-solid"
                  type="text"
                  {...register('appleId')}
                />
                <span className="invalid-feedback">
                  {errors['appleId']?.message}
                </span>
              </div>
            </div>
          </div>

          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">Account Type</label>
            <div className="col-lg-9">
              <SingleSelect
                data={handleMappingAccountType()}
                defaultValue={handleMappingAccountType().find(
                  (item) => item.type === get(userDetail, 'type')
                )}
                getData={(value) => handleSelectValue(value, 'type')}
                placeholder={'Select Account Type...'}
              />
            </div>
          </div>

          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">Display Name</label>
            <div className="col-lg-9">
              <div className="spinner spinner-sm spinner-primary spinner-right">
                <input
                  className="form-control form-control-lg form-control-solid"
                  type="text"
                  {...register('displayName')}
                />
                <span className="invalid-feedback">
                  {errors['displayName']?.message}
                </span>
              </div>
            </div>
          </div>

          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">Expiry Date</label>
            <div className="col-lg-9">
              <div className="spinner spinner-sm spinner-primary spinner-right">
                <input
                  className="form-control form-control-lg form-control-solid"
                  type="date"
                  {...register('expiryDate')}
                />
                <span className="invalid-feedback">
                  {errors['expiryDate']?.message}
                </span>
              </div>
            </div>
          </div>

          <div className="row mb-8">
            <div className="col-lg-6 ">
              <div>
                <label className="col-form-label">Front License Image</label>
              </div>
              <UploadLicenseImage
                defaultImage={get(userDetail, 'front.url')}
                getImage={getFrontImage}
              />
            </div>
            <div className="col-lg-6 ">
              <div>
                <label className="col-form-label">Back License Image</label>
              </div>
              <UploadLicenseImage
                defaultImage={get(userDetail, 'back.url')}
                getImage={getBackImage}
              />
            </div>
          </div>

          <div className="row justify-content-end ">
            <div className="col-lg-9 mt-4">
              <button
                className="btn btn-primary fw-bolder px-6 py-3 me-3"
                type="submit"
              >
                Save Changes
              </button>
              <button
                className="btn btn-color-gray-600 btn-active-light-primary fw-bolder px-6 py-3"
                type="button"
                onClick={onHandleCancel}
              >
                Cancel
              </button>
              <button
                className="btn btn-light-danger fw-bolder px-6 py-3 float-end"
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#modal-warning"
              >
                Deactivate
              </button>
            </div>
          </div>
        </div>
      </form>

      {/* Warning modal */}
      <div className="modal fade" id="modal-warning" tabIndex={-1}>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <div className="text-center">
                <div className="my-10">
                  <i className="bi bi-exclamation-circle text-danger fs-5x" />
                </div>
                <h3>Are you sure you want to do this?</h3>
                <p>This action can not be undone</p>
              </div>
            </div>
            <div className="modal-footer border-0 justify-content-center">
              <button
                className="w-100px btn btn-light"
                type="button"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                className="w-100px btn btn-light-primary"
                type="button"
                data-bs-dismiss="modal"
                onClick={handleDeactivate}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DetailTab;
