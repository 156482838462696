import http from '../../services/http-common';

export default class AuthorisedUser {
  static fetchAuthorisedUsers(queryParams) {
    return http.get('/api/authorised-users', queryParams);
  }

  static getAuthorisedUserDetail(id, queryParams) {
    return http.get(`/api/authorised-users/${id}`, queryParams);
  }

  static createAuthorisedUser(body) {
    return http.post('/api/authorised-users', body);
  }

  static updateAuthorisedUser(id, body) {
    return http.put(`/api/authorised-users/${id}`, body);
  }
}
