import React from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import SVG from 'react-inlinesvg';

import './styles.scss';
import UploadImage from '../../../../components/UploadImage';

const FormKnowledgeHub = (props) => {
  const schema = yup.object().shape({
    title: yup.string().required('Title is required'),
    description: yup.string().required('Description is required'),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const triggerSubmit = (data) => {
    props.onSubmit(data);
  };

  return (
    <form onSubmit={handleSubmit(triggerSubmit)}>
      <div className="modal-body">
        <div className="row mb-8">
          <label className="col-lg-3 col-form-label">Cover Image</label>
          <div className="col-lg-9">
            <UploadImage
              getImage={props.getFile}
              typeResource="Image"
              defaultImage={
                props?.knowledgeSelected?.cover?.url ?? props.knowledgeImage
              }
            />
          </div>
        </div>
        <div className="row mb-8">
          <div>
            <div className="row mb-8">
              <label className="col-lg-3 col-form-label">Title</label>
              <div className="col-lg-9">
                <div className="spinner spinner-sm spinner-primary spinner-right">
                  <input
                    className="form-control form-control-lg"
                    type="text"
                    defaultValue={props?.knowledgeSelected?.title ?? ''}
                    {...register('title')}
                  />
                  <span className="invalid-feedback">
                    {errors['title']?.message}
                  </span>
                </div>
              </div>
            </div>
            <div className="row mb-8">
              <label className="col-lg-3 col-form-label">Description</label>
              <div className="col-lg-9">
                <div className="spinner spinner-sm spinner-primary spinner-right">
                  <input
                    className="form-control form-control-lg"
                    type="text"
                    defaultValue={props?.knowledgeSelected?.description ?? ''}
                    {...register('description')}
                  />
                  <span className="invalid-feedback">
                    {errors['description']?.message}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="form-row">
          <input
            className="d-none"
            id="pdf-upload"
            type="file"
            accept=".pdf"
            onChange={(event) => {
              props.getFilePdf(event.target.files[0]);
            }}
          />
          <label htmlFor="pdf-upload">
            <i className="bi bi-file-earmark-plus fs-2 me-3"></i>Attach PDF file
          </label>
        </div>
        <div className="d-flex align-items-center">
          <p className="mb-0 text-primary">
            {props?.filePdf?.name ?? 'File name after Attached'}
          </p>
          <button
            type="button"
            className="btn btn-icon btn-icon-danger ms-auto"
            onClick={props.isRemoveAttachFile}
          >
            <span className="svg-icon svg-icon-2">
              <span className="svg-icon svg-icon-4">
                <SVG src="/images/icons/trash.svg" />
              </span>
            </span>
          </button>
        </div>
      </div>
      <div className="modal-footer">
        {props?.knowledgeSelected?.id && (
          <button
            className="btn btn-link me-auto text-danger"
            onClick={props.onDeleteKnowledge}
            type="button"
          >
            Remove
          </button>
        )}

        <button
          className="btn btn-light"
          type="button"
          onClick={props.handleClose}
        >
          Cancel
        </button>
        <button className="btn btn-light-primary">Save</button>
      </div>
    </form>
  );
};

export default FormKnowledgeHub;
