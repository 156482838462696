import http from '../../services/http-common';

export default class Faqs {
  static fetchFaqs() {
    return http.get('/faqs');
  }

  static createFaq(body) {
    return http.post('/faqs', body);
  }

  static updateFaq(id, body) {
    return http.put(`/faqs/${id}`, body);
  }

  static deleteFaq(id) {
    return http.delete(`/faqs/${id}`);
  }

  static updateOrderFaq(body) {
    return http.post('/faqs/order', body);
  }

  static fetchFaqCategory(body) {
    return http.get('/faq-categories', body);
  }
}
