import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
// import { createTopic } from '../../../../features/admin/topic';
import Trips from './Trips';
// import Topics from './Topics';
import { get } from 'lodash';
// import { UserType } from '../../../utils/constants';
import SVG from 'react-inlinesvg';

const TripList = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const onCreate = () => {
    //
  };

  return (
    <div className="d-flex flex-column flex-column-fluid">
      <div className="content fs-6 d-flex flex-column-fluid" id="kt_content">
        <div className="container">
          <Trips />
        </div>
      </div>
    </div>
  );
};

export default TripList;
