import React, { useEffect, useState } from 'react';
import { get } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { formatDate, formatDateTime } from '../../../utils/helper';
import { upload } from '../../../../features/upload';
import { updateMe } from '../../../../features/user/auth';
import {
  resetAvatarResponse,
  selectUserDetail,
  setAvatarResponse,
} from '../../../../features/user';
import { useLocation } from 'react-router';

function UserSideBar({ isUserDetail }) {
  const location = useLocation();
  const dispatch = useDispatch();

  const [image, setImage] = useState();
  const userDetail = useSelector(selectUserDetail);

  useEffect(() => {
    if (userDetail?.avatar) {
      setImage(get(userDetail, 'avatar.url'));
    }
  }, [userDetail]);

  useEffect(() => {
    return () => {
      dispatch(resetAvatarResponse());
    };
  }, [location.pathname]);

  const getAvatar = async (file) => {
    const formData = new FormData();
    formData.append('files', file);
    formData.append(
      'fileInfo',
      JSON.stringify({ alternativeText: '', caption: '', name: null })
    );

    const uploadResponse = await dispatch(upload(formData)).unwrap();
    if (uploadResponse && uploadResponse.length > 0) {
      dispatch(
        updateMe({
          id: userDetail.id,
          data: {
            avatar: uploadResponse[0].id,
          },
        })
      );
    }
    dispatch(setAvatarResponse(uploadResponse));
  };

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      const img = event.target.files[0];
      setImage(URL.createObjectURL(img));
      getAvatar(img);
    }
  };

  return (
    <div className={`aside-secondary d-flex flex-row-fluid bg-info-op9`}>
      <div
        className="aside-workspace my-7 ps-5 pe-4 px-lg-8"
        id="kt_aside_wordspace"
      >
        <div className="hover-scroll-y me-lg-n5 pe-lg-4 h-100">
          <div className="card bg-transparent">
            <div className="card-body px-0">
              <div className="pt-0">
                <div
                  className="d-flex flex-center position-relative bgi-no-repeat bgi-size-contain bgi-position-x-center bgi-position-y-center h-175px mt-lg-10"
                  style={{
                    backgroundImage:
                      'url("/images/svg/illustrations/bg-3.svg")',
                  }}
                >
                  <div
                    className="image-input image-input-empty symbol symbol-circle symbol-100px symbol-lg-130px border-info-3px bgi-size-cover bgi-position-center"
                    data-kt-image-input="true"
                    style={{
                      backgroundImage: `url(${image})`,
                    }}
                  >
                    <div className="image-input-wrapper border-circle w-60px w-md-100px h-60px h-md-100px"></div>
                    <label
                      className="btn btn-icon btn-circle btn-active-color-primary w-20px w-md-25px h-20px h-md-25px bg-white shadow"
                      data-kt-image-input-action="change"
                      data-bs-toggle="tooltip"
                      data-bs-dismiss="click"
                      title="Change avatar"
                    >
                      <i className="bi bi-pencil-fill fs-7" />
                      <input
                        type="file"
                        name="avatar"
                        accept=".png, .jpg, .jpeg"
                        onChange={onImageChange}
                      />
                      <input type="hidden" name="avatar_remove" />
                    </label>
                    <span
                      className="btn btn-icon btn-circle btn-active-color-primary w-20px w-md-25px h-20px h-md-25px bg-white shadow"
                      data-kt-image-input-action="remove"
                      data-bs-toggle="tooltip"
                      data-bs-dismiss="click"
                      title="Remove avatar"
                    >
                      <i className="bi bi-x fs-2" />
                    </span>
                  </div>
                </div>
                <div className="pt-4">
                  <div className="text-center pb-5">
                    <h3 className="fw-bolder fs-1 pb-3 false">
                      {get(userDetail, 'firstName')}
                      <br />
                      {get(userDetail, 'lastName')}
                    </h3>
                  </div>
                  <div className="row fw-light row-cols-2 px-5 px-xl-8 pb-12 sidebar-toolbar">
                    <div className="col-7">
                      <p>
                        Last active
                        <br />
                        {formatDateTime(get(userDetail, 'updatedAt'))}
                      </p>
                    </div>
                    <div className="col-5">
                      <p>
                        Creation date
                        <br />
                        {formatDate(get(userDetail, 'createdAt'))}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserSideBar;
