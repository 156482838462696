import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AuthorisedUser from '../../api/admin/authorisedUser';
import { parseError } from '../../app/utils/helper';

export const fetchAuthorisedUsers = createAsyncThunk(
  'authorisedUser/fetchAuthorisedUsers',
  async (queryParams, { rejectWithValue }) => {
    try {
      const response = await AuthorisedUser.fetchAuthorisedUsers(queryParams);
      return response.data;
    } catch (error) {
      return rejectWithValue(parseError(error));
    }
  }
);

export const updateAuthorisedUserById = createAsyncThunk(
  'authorisedUser/updateAuthorisedUserById',
  async ({ id, body }, { rejectWithValue }) => {
    try {
      const response = await AuthorisedUser.updateAuthorisedUser(id, body);
      return response.data;
    } catch (error) {
      return rejectWithValue(parseError(error));
    }
  }
);

export const setActivePage = createAsyncThunk(
  'authorisedUser/setActivePage',
  (data) => {
    return data;
  }
);

export const setPageSize = createAsyncThunk(
  'authorisedUser/setPageSize',
  (data) => {
    return data;
  }
);

export const setEditableAuthorisedUser = createAsyncThunk(
  'authorisedUser/setEditableAuthorisedUser',
  async (id, { rejectWithValue }) => {
    if (!id) return;

    try {
      const res = await AuthorisedUser.getAuthorisedUserDetail(id);
      return res.data;
    } catch (error) {
      return rejectWithValue(parseError(error));
    }
  }
);

const authorisedUserSlice = createSlice({
  name: 'authorisedUser',
  initialState: {
    authorisedUsers: [],
    activePage: 1,
    pageSize: 10,
    total: 0,
    editableAuthorisedUser: null,
  },
  reducers: {
    resetAuthorisedUsers: (state, action) => {
      state.authorisedUsers = [];
    },
  },
  extraReducers: {
    [fetchAuthorisedUsers.fulfilled]: (state, action) => {
      state.authorisedUsers = action.payload.data;
      state.activePage = action.payload.meta?.pagination?.page;
      state.pageSize = action.payload.meta?.pagination?.pageSize;
      state.total = action.payload.meta?.pagination?.total;
    },
    [setActivePage.fulfilled]: (state, action) => {
      state.activePage = action.payload;
    },
    [setPageSize.fulfilled]: (state, action) => {
      state.pageSize = action.payload;
    },
    [setEditableAuthorisedUser.fulfilled]: (state, action) => {
      state.editableAuthorisedUser = action.payload?.data;
    },
  },
});

export default authorisedUserSlice.reducer;
