import React, { useEffect, useState, useMemo, useCallback, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { get } from 'lodash';
import { useLocation } from 'react-router-dom';
import DeleteButton from '../../../../components/DeleteButton';
import ModalConfirm from '../../../../components/Modal/ModalConfirm';
import {
  fetchUsers,
  selectTotalUser,
  selectUserList,
  updateUserWithoutRefreshById
} from '../../../../../features/user';
import { selectAuthUser } from '../../../../../features/user/auth';
import {
  DebounceTimeDefault,
  initialStrapiPagination,
} from '../../../../utils/constants';
import { displayIconSort, formatDate } from '../../../../utils/helper';
import SVG from 'react-inlinesvg';
import Pagination from '../../../../components/Pagination';
import { debounce } from 'lodash';
import { useSortableData } from '../../../../../hooks/useSortableData';

const UserList = () => {
  const [isShowModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const users = useSelector(selectUserList);
  const totalUser = useSelector(selectTotalUser);
  const userLogin = useSelector(selectAuthUser);

  const {
    items: dataTable,
    requestSort,
    sortConfig,
  } = useSortableData([...users]);

  const [keyword, setKeyword] = useState('');
  const [searchQuery, setSearchQuery] = useState('');

  const [pagination, setPagination] = useState({
    ...initialStrapiPagination,
    'populate[avatar]': true,
  });
  const selected = useRef(0);

  const queryParams = useMemo(() => {
    return {
      ...pagination,
      'filters[$or][0][username][$contains]': searchQuery,
      'filters[$or][1][firstName][$contains]': searchQuery,
      'filters[$or][2][lastName][$contains]': searchQuery,
    };
  }, [searchQuery, pagination]);

  useEffect(() => {
    if (queryParams) {
      dispatch(fetchUsers(queryParams));
    }
  }, [queryParams]);

  const getLinkDetail = (user) => {
    return `/admin/user-detail/${user.id}`;
  };
  const authUser = useSelector(selectAuthUser);

  const onPageClick = (page) => {
    setPagination({
      ...pagination,
      'pagination[page]': page + 1,
    });
  };

  const onChangeSize = (size) => {
    setPagination({
      ...pagination,
      'pagination[page]': 1,
      'pagination[pageSize]': size,
    });
  };

  const handleOnSearch = (inputValue) => {
    setSearchQuery(inputValue);
  };

  const onSearch = useCallback(
    debounce(handleOnSearch, DebounceTimeDefault),
    []
  );

  const handleOnChange = (e) => {
    setKeyword(e.target.value);
    onSearch(e.target.value);
  };

  return (
    <div className="d-flex flex-column flex-column-fluid">
      <div className="toolbar mt-8 mt-lg-0" id="kt_toolbar">
        <div className="container d-flex flex-stack flex-wrap flex-sm-nowrap">
          <div className="d-flex align-items-center flex-nowrap text-nowrap overflow-auto py-1"></div>
          <div className="ms-md-auto">
            <button className="btn btn-primary d-none">
              <i className="fas fa-plus opacity-50 me-2"></i>
              Create User
            </button>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="d-flex align-items-center position-relative my-1">
          <span className="svg-icon svg-icon-2 svg-icon-info ms-6 z-index-1">
            <SVG src="/images/icons/search.svg" />
          </span>
          <input
            className="form-control form-control-solid w-100 mw-350px ps-15 ms-n12 bg-white"
            type="text"
            data-kt-docs-table-filter="search"
            placeholder="Search by name"
            value={keyword}
            onChange={(e) => handleOnChange(e)}
          />
        </div>
      </div>
      <div className="content fs-6 d-flex flex-column-fluid" id="kt_content">
        <div className="container">
          <div className="card">
            <div className="card-body">
              <div
                id="kt_datatable_example_2_wrapper"
                className="dataTables_wrapper dt-bootstrap4 no-footer"
              >
                <div className="mobile-table">
                  <table
                    className="table table-striped table-row-bordered gy-5 gs-7 w-100 align-middle"
                    id="kt_datatable_example_2"
                  >
                    <thead>
                      <tr className="fw-bold fs-6 text-gray-800">
                        <th
                          className="min-w-100px sorting sorting_asc"
                          onClick={() => requestSort('id')}
                        >
                          ID
                          {displayIconSort('id', sortConfig)}
                        </th>
                        <th
                          className="min-w-100px "
                          onClick={() => requestSort('displayName')}
                        >
                          User Name
                          {displayIconSort('displayName', sortConfig)}
                        </th>
                        <th
                          className="min-w-100px "
                          onClick={() => requestSort('avatar')}
                        >
                          Avatar
                          {displayIconSort('avatar', sortConfig)}
                        </th>
                        <th
                          className="min-w-100px "
                          onClick={() => requestSort('email')}
                        >
                          Email
                          {displayIconSort('email', sortConfig)}
                        </th>
                        <th
                          className="min-w-125px "
                          onClick={() => requestSort('firstName')}
                        >
                          First Name
                          {displayIconSort('firstName', sortConfig)}
                        </th>
                        <th
                          className="min-w-125px "
                          onClick={() => requestSort('lastName')}
                        >
                          Last Name
                          {displayIconSort('lastName', sortConfig)}
                        </th>
                        <th
                          className="min-w-125px "
                          onClick={() => requestSort('username')}
                        >
                          Phone
                          {displayIconSort('username', sortConfig)}
                        </th>
                        <th
                          className="min-w-100px "
                          onClick={() => requestSort('createdAt')}
                        >
                          Created
                          {displayIconSort('createdAt', sortConfig)}
                        </th>
                        <th
                          className="min-w-100px"
                          onClick={() => requestSort('tranmission')}
                        >
                          Transmission
                          {displayIconSort('tranmission', sortConfig)}
                        </th>
                        <th
                          className="min-w-100px"
                          onClick={() => requestSort('type')}
                        >
                          Type
                          {displayIconSort('type', sortConfig)}
                        </th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {!!dataTable?.length > 0 &&
                        dataTable.map((user, index) => {
                          return (
                            <tr key={index}>
                              <td>{get(user, 'id')}</td>
                              <td>
                                <Link to={getLinkDetail(user)} className="link">
                                  {get(user, 'username')}
                                </Link>
                              </td>
                              <td className="py-1">
                                <div
                                  style={{
                                    backgroundImage: `url(${get(
                                      user,
                                      'avatar.url'
                                    )})`,
                                  }}
                                  className="w-40px h-40px bgi-position-center bgi-size-cover rounded-circle"
                                ></div>
                              </td>
                              <td>{get(user, 'email')}</td>
                              <td>{get(user, 'firstName')}</td>
                              <td>{get(user, 'lastName', '')}</td>
                              <td>
                                {get(user, 'type') === 'phone'
                                  ? get(user, 'username', '')
                                  : ''}
                              </td>
                              <td>{formatDate(get(user, 'createdAt'))}</td>
                              <td>{get(user, 'tranmission')}</td>
                              <td>{get(user, 'type')}</td>
                              <td>
                                {
                                  user.id !== authUser.id ? (
                                    <DeleteButton onClick={() => {
                                      setShowModal(true);
                                      selected.current = user.id;
                                    }} />
                                  ) : (<></>)
                                }
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
                {totalUser > 0 && (
                  <Pagination
                    onChangeSize={onChangeSize}
                    onPageClick={onPageClick}
                    total={totalUser}
                  />
                )}
                <ModalConfirm
                  isOpen={isShowModal}
                  handleClose={() => setShowModal(false)}
                  confirm={async () => {
                    setShowModal(false);
                    await dispatch(updateUserWithoutRefreshById({
                      id: selected.current,
                      body: {
                        appleId: null,
                        blocked: true
                      },
                    }));
                    onPageClick(0);
                  }}
                  modalTitle={'Are you sure you want to do this?'}
                  modalNotes={'This action can not be undone'}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserList;
