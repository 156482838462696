import React from 'react';
import './styles.scss';

function getTitleOfType(type) {
  switch (type) {
    case 'new':
      return 'New';
    case 'standard':
      return 'Standard Wear';
    default:
      return 'Excessive Wear';
  }
}

const ConditionReportView = ({ title, data }) => {
  if (data) {
    const { description, images, type } = data;
    return (
      <>
        <div className="report-title">{title}</div>
        {type && (
          <>
            <div className="report-description-title">Type</div>
            <div className="report-description">{getTitleOfType(type)}</div>
          </>
        )}
        <div className="report-description-title">Description</div>
        <div className="report-description">{description}</div>
        <div className="report-description-title">Media</div>
        {images.map((image) => (
          <img className="media" src={image} width="100%" />
        ))}
      </>
    );
  }
  return (
    <>
      <div className="report-title">{title}</div>
      <div className="report-description">No</div>
    </>
  );
};

export default ConditionReportView;
