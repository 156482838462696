import React, { useState, useEffect, useMemo } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import SVG from 'react-inlinesvg';

import { logout, selectAuthUser } from '../../../../features/user/auth';
import UserSideBar from './UserSideBar';
import useCheckMobileScreen from '../../../../hooks/useCheckMobileScreen ';
import { CustomizeTooltip } from '../../../components/ToolTips';

const sideBar = [
  {
    key: 1,
    tab: 'cars-list',
    route: '/admin/cars',
    activeRoutes: ['/admin/cars'],
    roles: ['admin', 'super_admin', 'clinician'],
    title: 'Cars',
    icon: '/images/icons/car.svg',
  },
  {
    key: 2,
    tab: 'user-list',
    route: '/admin/user-list',
    activeRoutes: [
      '/admin/user-list',
      '/admin/user-list/add',
      '/admin/admin-detail/',
      '/admin/clinician-detail/',
      '/admin/client-detail/',
    ],
    roles: ['admin', 'super_admin', 'clinician'],
    title: 'Users',
    icon: '/images/icons/users.svg',
  },
  {
    key: 3,
    tab: 'cms',
    route: '/admin/cms',
    activeRoutes: ['/admin/cms'],
    roles: ['super_admin'],
    title: 'CMS',
    icon: '/images/icons/content-management.svg',
  },
  {
    key: 4,
    tab: 'incident-list',
    route: '/admin/incidents',
    activeRoutes: ['/admin/incidents'],
    roles: ['admin', 'super_admin', 'clinician'],
    title: 'Incidents',
    icon: '/images/icons/incident.svg',
  },
  {
    key: 5,
    tab: 'trip-list',
    route: '/admin/trips',
    activeRoutes: ['/admin/trips'],
    roles: ['admin', 'super_admin', 'clinician'],
    title: 'Trips',
    icon: '/images/icons/road.svg',
  },
  {
    key: 6,
    tab: 'authorised-user-list',
    route: '/admin/authorised-users',
    activeRoutes: ['/admin/authorised-users'],
    roles: ['admin', 'super_admin', 'clinician'],
    title: 'Authorised Users',
    icon: '/images/icons/user.svg',
  },
];

const Sidebar = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const authUser = useSelector(selectAuthUser);
  const role = authUser?.role?.type || 'admin';
  const [toggleAside, setToggleAside] = useState(true);
  const isMobile = useCheckMobileScreen();

  useEffect(() => {
    const aside = document.querySelector('#kt_aside');
    const wrapper = document.querySelector('#kt_wrapper');
    if (!isMobile) {
      if (aside && wrapper) {
        if (toggleAside) {
          aside.style.width = '100px';
          wrapper.style.paddingLeft = '100px';
        } else {
          aside.style.width = '500px';
          wrapper.style.paddingLeft = '500px';
        }
      }
    } else {
      aside.style.width = '0px';
      wrapper.style.paddingLeft = '0px';
    }
  }, [toggleAside, isMobile]);

  useEffect(() => {
    // user detail
    if (isUserDetail) {
      setToggleAside(false);
      // other pages
    } else {
      setToggleAside(true);
    }
  }, [location.pathname]);

  const isUserDetail = useMemo(() => {
    return location.pathname.startsWith('/admin/user-detail/');
  }, [location.pathname]);

  const signOut = () => {
    dispatch(logout());
    return history.push('/');
  };

  return (
    <div
      className="aside"
      id="kt_aside"
      data-kt-drawer="true"
      data-kt-drawer-name="aside"
      data-kt-drawer-activate="{default: true, lg: false}"
      data-kt-drawer-overlay="true"
      data-kt-drawer-width="70px"
      data-kt-drawer-direction="start"
      data-kt-drawer-toggle="#kt_aside_toggler"
    >
      <div className="aside-primary d-flex flex-column align-items-center flex-row-auto bg-info">
        <div
          className="aside-logo d-flex flex-column align-items-center flex-column-auto py-4 pt-lg-10 pb-lg-7"
          id="kt_aside_logo"
        >
          <Link to="/admin/cars">
            <img
              width={50}
              height={19}
              className="mh-50px"
              alt="Logo"
              src="/images/logos/logo-compact.svg"
            />
          </Link>
        </div>
        <div
          className="aside-nav d-flex flex-column align-items-center flex-column-fluid pt-0 pb-5 mt-lg-20"
          id="kt_aside_nav"
        >
          <div
            className="hover-scroll-y"
            data-kt-scroll="true"
            data-kt-scroll-height="auto"
            data-kt-scroll-dependencies="#kt_aside_logo, #kt_aside_footer"
            data-kt-scroll-wrappers="#kt_aside_nav"
            data-kt-scroll-offset="10px"
          >
            <ul className="nav flex-column">
              {sideBar?.length > 0 &&
                sideBar.map((ele, index) => {
                  return (
                    ele?.roles?.length > 0 &&
                    ele?.roles?.includes(role) && (
                      <CustomizeTooltip
                        key={index}
                        title={ele.title}
                        placement="right"
                      >
                        <li className="nav-item mb-1">
                          <Link
                            className={`nav-link h-40px w-80px btn btn-custom btn-icon btn-color-white rounded-1 ${
                              ele.activeRoutes.includes(location.pathname) &&
                              'active'
                            }`}
                            to={ele.route}
                          >
                            <span className="svg-icon svg-icon-4">
                              <SVG src={ele.icon} />
                            </span>
                          </Link>
                        </li>
                      </CustomizeTooltip>
                    )
                  );
                })}
            </ul>
          </div>
        </div>

        <div
          className="aside-footer d-flex flex-column align-items-center flex-column-auto py-5 py-lg-7"
          id="kt_aside_footer"
        >
          {location.pathname.startsWith('/admin/user-detail/') && (
            <button
              className="btn btn-sm btn-icon btn-white btn-active-primary position-absolute translate-middle start-100 end-0 bottom-0 shadow-sm d-none d-lg-flex z-index-1"
              id="kt-toggle-btn"
              data-kt-toggle="true"
              data-kt-toggle-state="active"
              data-kt-toggle-target="body"
              data-kt-toggle-name="aside-minimize"
              title="Toggle Aside"
              onClick={() => setToggleAside(!toggleAside)}
            >
              <SVG
                src={`${
                  toggleAside
                    ? '/images/icons/right.svg'
                    : '/images/icons/back-2.svg'
                }`}
                alt=""
                id="kt_aside_toggle"
              />
            </button>
          )}

          <button
            className="h-40px w-40px btn btn-custom btn-icon btn-color-white"
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            data-bs-trigger="hover"
            title="Sign out"
            onClick={signOut}
          >
            <span className="svg-icon">
              <SVG src="/images/icons/sign-out.svg" />
            </span>
          </button>
        </div>
      </div>
      {/* Admin Detail */}
      {location.pathname.startsWith('/admin/user-detail/') && (
        <UserSideBar isUserDetail={isUserDetail} />
      )}
    </div>
  );
};

export default Sidebar;
