import { useEffect, useState, useCallback } from 'react';

export default function useAnimationMobileMenu() {
  const [isScrollAnimated, setIsScrollAnimated] = useState(false);

  const onScroll = useCallback(() => {
    if (window.scrollY > 80) {
      return setIsScrollAnimated(true);
    }
    return setIsScrollAnimated(false);
  }, [window.scrollY]);

  useEffect(() => {
    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  return { isScrollAnimated };
}
