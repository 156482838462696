import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { logout, selectAcessToken } from '../../features/user/auth';
import { useIdleTimer } from 'react-idle-timer';
import { useDispatch } from 'react-redux';
import { useEffect, useRef } from 'react';
import {
  resetUnauthorized,
  selectStateUnauthorized,
} from '../../features/error/errorSlice';

function AuthRoute({ children, ...rest }) {
  const hiddenBtn = useRef(null);
  const dispatch = useDispatch();
  const accessToken = useSelector(selectAcessToken);
  const unauthorized = useSelector(selectStateUnauthorized);

  const onLogout = () => {
    dispatch(logout());
  };

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * 30,
    onIdle: onLogout,
    debounce: 500,
  });

  useEffect(() => {
    if (unauthorized.isUnauthorized) {
      hiddenBtn.current.click();
    }
  }, [unauthorized]);

  const handleUnauthorized = () => {
    dispatch(resetUnauthorized());
    dispatch(logout());
  };

  return (
    <>
      {/* this button is use to trigger modal */}
      <button
        type="button"
        ref={hiddenBtn}
        style={{ display: 'none', opacity: 0 }}
        data-bs-target="#modal-unauthorized"
        data-bs-toggle="modal"
        key={'hidden'}
      >
        Hidden
      </button>
      {/* Warning modal */}
      <div
        className="modal fade"
        id="modal-unauthorized"
        tabIndex={-1}
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <div className="text-center">
                <div className="my-10">
                  <i className="bi bi-exclamation-circle text-danger fs-5x" />
                </div>
                <h4>{unauthorized.messageUnauthorized}</h4>
              </div>
            </div>
            <div className="modal-footer border-0 justify-content-center">
              <button
                className="btn btn-light-primary"
                data-bs-dismiss="modal"
                onClick={handleUnauthorized}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>

      <Route
        {...rest}
        render={(props) =>
          accessToken ? (
            children
          ) : (
            <Redirect to={{ pathname: '/', state: { from: props.location } }} />
          )
        }
      />
    </>
  );
}
export default AuthRoute;
