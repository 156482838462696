import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Car from '../../api/admin/cars';
import { parseError } from '../../app/utils/helper';

const ShowType = {
  List: 0,
  Grid: 1,
};

export const fetchCars = createAsyncThunk(
  'Car/fetchCars',
  async (queryParams, { rejectWithValue }) => {
    try {
      const response = await Car.fetchCars(queryParams);
      return response.data;
    } catch (error) {
      return rejectWithValue(parseError(error));
    }
  }
);

export const updateCar = createAsyncThunk(
  'Car/updateCar',
  async ({ id, body }, { rejectWithValue }) => {
    try {
      const { data } = await Car.updateCar(id, body);
      return { data, message: 'Update car successfully' };
    } catch (error) {
      return rejectWithValue(parseError(error));
    }
  }
);

export const createCar = createAsyncThunk(
  'Car/createCar',
  async (body, { rejectWithValue }) => {
    try {
      const { data } = await Car.createCar(body);
      return { data, message: 'create car successfully' };
    } catch (error) {
      return rejectWithValue(parseError(error));
    }
  }
);

const carsSlice = createSlice({
  name: 'car',
  initialState: {
    list: [],
    typeShow: ShowType.Grid,
  },
  reducers: {
    resetCarsList: (state, action) => {
      state.list = [];
    },
    setTypeShow: (state, action) => {
      state.typeShow = action.payload;
    },
  },
  extraReducers: {
    [fetchCars.fulfilled]: (state, action) => {
      state.list = action.payload.data;
    },
  },
});

export const { resetCarsList } = carsSlice.actions;
export const selectCars = (state) => state.car.list;

export default carsSlice.reducer;
