import React, { useState } from 'react';

import './Components/styles.scss';
import SearchTrips from './Components/SearchTrips';
import TripTable from './Components/TripTable';

const Trips = ({}) => {
  const [keyword, setKeyword] = useState('');
  return (
    <>
      <SearchTrips keyword={keyword} setKeyword={setKeyword} />
      <TripTable keyword={keyword} />
    </>
  );
};

export default Trips;
