import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchFaqCategory } from '../../../../../features/admin/faqs';
import Modal from '@mui/material/Modal';
import FormFaqCategory from './form';
import {
  createFaqCategory,
  deleteFaqCategory,
  updateFaqCategory,
} from '../../../../../features/admin/faqCategory';

function FaqCategory() {
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(false);
  const [categorySelected, setCategorySelected] = useState();
  const {
    faqCategories,
    faqCategoryCreated,
    faqCategoryUpdated,
    faqCategoryDeleted,
  } = useSelector((state) => state.faqCategory);

  useEffect(() => {
    dispatch(fetchFaqCategory());
  }, [faqCategoryCreated?.id, faqCategoryUpdated?.id, faqCategoryDeleted?.id]);

  const onHandleCategorySelected = (item) => {
    setCategorySelected(item);
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const onHandleCreateCategory = () => {
    setCategorySelected(null);
    setIsOpen(true);
  };

  const handleSubmitCategory = (data) => {
    // update
    if (categorySelected) {
      dispatch(updateFaqCategory({ id: categorySelected.id, body: data }));
      // create
    } else {
      dispatch(createFaqCategory(data));
    }
    setIsOpen(false);
  };

  const onDeleteCategory = () => {
    if (categorySelected) {
      dispatch(deleteFaqCategory({ id: categorySelected.id }));
      setIsOpen(false);
      setCategorySelected(null);
    }
  };

  return (
    <>
      <div className="card-header border-0 pt-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bolder text-dark fs-2">
            FAQ Category
          </span>
        </h3>
        <div className="card-toolbar">
          <a
            className="btn btn-primary ms-auto"
            onClick={() => onHandleCreateCategory()}
            data-bs-toggle="modal"
          >
            <i className="fas fa-plus me-2 opacity-50"></i>Create New FAQ
            Category
          </a>
        </div>
      </div>

      <div className="card-body pt-12 mt-n3">
        <ul className="list-group list-group-flush">
          {faqCategories.length > 0 &&
            faqCategories.map((item) => (
              <li className="list-group-item py-5 px-0" key={item.id}>
                <div className="d-flex align-items-center">
                  <span className="text-dark text-hover-primary mb-0">
                    {item.category}
                  </span>
                  <div className="ms-auto min-w-100px text-end">
                    <button
                      className="btn btn-icon btn-primary btn-sm"
                      onClick={() => onHandleCategorySelected(item)}
                    >
                      <i className="bi bi-pencil fs-6"></i>
                    </button>
                  </div>
                </div>
              </li>
            ))}
        </ul>
      </div>

      <Modal open={isOpen} onClose={handleClose}>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <FormFaqCategory
              handleClose={handleClose}
              onSubmit={handleSubmitCategory}
              categorySelected={categorySelected}
              onDeleteCategory={onDeleteCategory}
            />
          </div>
        </div>
      </Modal>
    </>
  );
}

export default FaqCategory;
