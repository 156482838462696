import http from '../../services/http-common';

export default class FaqCategories {
  static fetchFaqCategory(body) {
    return http.get('/faq-categories', body);
  }

  static createFaqCategory(body) {
    return http.post('/faq-categories', body);
  }

  static updateFaqCategory(id, body) {
    return http.put(`/faq-categories/${id}`, body);
  }

  static deleteFaqCategory(id) {
    return http.delete(`/faq-categories/${id}`);
  }
}
