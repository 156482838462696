import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import TermConditions from '../../api/admin/termCondition';
import { parseError } from '../../app/utils/helper';

export const fetchTermConditions = createAsyncThunk(
  'admin/fetchTermConditions',
  async (_, { rejectWithValue }) => {
    try {
      const response = await TermConditions.fetchTermConditions();
      return response.data;
    } catch (error) {
      return rejectWithValue(parseError(error));
    }
  }
);

export const createTermConditions = createAsyncThunk(
  'admin/createTermConditions',
  async (body, { rejectWithValue }) => {
    try {
      const { data } = await TermConditions.createTermCondition(body);
      return { data, message: 'Create term & condition successfully' };
    } catch (error) {
      return rejectWithValue(parseError(error));
    }
  }
);

export const updateTermConditions = createAsyncThunk(
  'admin/updateTermConditions',
  async ({ id, body }, { rejectWithValue }) => {
    try {
      const { data } = await TermConditions.updateTermCondition(id, body);
      return { data, message: 'Update term & condition successfully' };
    } catch (error) {
      return rejectWithValue(parseError(error));
    }
  }
);

const TermConditionssSlice = createSlice({
  name: 'termConditions',
  initialState: {
    termConditions: [],
  },
  reducers: {},
  extraReducers: {
    [fetchTermConditions.fulfilled]: (state, action) => {
      state.termConditions = action.payload;
    },
  },
});

export default TermConditionssSlice.reducer;

export const selectTermConditions = (state) =>
  state.termCondition.termConditions;
