import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import User from '../../api/user';
import { parseError } from '../../app/utils/helper';
import { push } from 'connected-react-router';

export const fetchRole = createAsyncThunk(
  'user/fetchRole',
  async (_, { rejectWithValue }) => {
    try {
      const response = await User.fetchRole();
      return response.data.roles;
    } catch (error) {
      return rejectWithValue(parseError(error));
    }
  }
);

export const fetchLocation = createAsyncThunk(
  'user/fetchLocation',
  async (queryParams, { rejectWithValue }) => {
    try {
      const response = await User.fetchLocation(queryParams);
      return response;
    } catch (error) {
      return rejectWithValue(parseError(error));
    }
  }
);

export const fetchUsers = createAsyncThunk(
  'user/fetchUsers',
  async (queryParams, { rejectWithValue }) => {
    try {
      const response = await User.fetchUsers(queryParams);
      return response.data;
    } catch (error) {
      return rejectWithValue(parseError(error));
    }
  }
);

export const addUser = createAsyncThunk(
  'user/addUser',
  async (userData, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await User.create(userData);
      dispatch(push(`/admin/client-detail/${data.user.id}`));
      return { data, message: 'Create a new user successfully' };
    } catch (error) {
      return rejectWithValue(parseError(error));
    }
  }
);

export const getUserById = createAsyncThunk(
  'user/getUserById',
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await User.getUserById(id);
      return data;
    } catch (error) {
      return rejectWithValue(parseError(error));
    }
  }
);

export const updateUserById = createAsyncThunk(
  'user/updateUserById',
  async ({ body, id }, { rejectWithValue }) => {
    try {
      await User.updateUserById(body, id);
      const { data } = await User.getUserById(id);
      return { data, message: 'Update user successfully' };
    } catch (error) {
      return rejectWithValue(parseError(error));
    }
  }
);

export const updateUserWithoutRefreshById = createAsyncThunk(
  'user/updateUserWithoutRefreshById',
  async ({ body, id }, { rejectWithValue }) => {
    try {
      await User.updateUserById(body, id);
      return { message: 'Update user successfully' };
    } catch (error) {
      return rejectWithValue(parseError(error));
    }
  }
);

export const fetchChartInfoByClinicianId = createAsyncThunk(
  'user/fetchChartInfoByClinicianId',
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await User.fetchChartInfoByClinicianId(id);
      return data;
    } catch (error) {
      return rejectWithValue(parseError(error));
    }
  }
);

export const fetchChartInfoByClientId = createAsyncThunk(
  'user/fetchChartInfoByClientId',
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await User.fetchChartInfoByClientId(id);
      return data;
    } catch (error) {
      return rejectWithValue(parseError(error));
    }
  }
);

export const fetchActivitiesByClientId = createAsyncThunk(
  'user/fetchActivitiesByClientId',
  async ({ id, queryParams }, { rejectWithValue }) => {
    try {
      const { data } = await User.fetchActivitiesByClientId(id, queryParams);
      return data;
    } catch (error) {
      return rejectWithValue(parseError(error));
    }
  }
);

export const previewAssignedActivity = createAsyncThunk(
  'user/previewAssignedActivity',
  async ({ clientId, id }, { rejectWithValue }) => {
    try {
      const { data } = await User.previewAssignedActivity(clientId, id);
      return data;
    } catch (error) {
      return rejectWithValue(parseError(error));
    }
  }
);

const initialState = {
  totalUsers: 0,
  users: [],
  user: null,
  locations: [],
  roles: [],
  // this is use for engagement tab in clinician detail
  ChartInfoByClinicianId: {},
  // this is use for engagement tab in client detail
  ChartInfoByClientId: {},
  // this is use for update avatar because it split to 2 screens
  updateAvatarResponse: null,
  // this is use for activities tab in client detail
  activitiesByClientId: [],
  totalActivity: 0,
  previewActivity: {},
  isOpenProfile: false,
};

const usersSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setAvatarResponse: (state, action) => {
      state.updateAvatarResponse = action.payload;
    },
    resetAvatarResponse: (state, action) => {
      state.updateAvatarResponse = null;
    },
    resetUserDetail: (state, action) => {
      state.user = null;
    },
    resetChartInfoByClinicianId: (state, action) => {
      state.ChartInfoByClinicianId = {};
    },
    resetChartInfoByClientId: (state, action) => {
      state.ChartInfoByClientId = {};
    },
    resetActivitiesByClientId: (state, action) => {
      state.activitiesByClientId = [];
      state.totalActivity = 0;
    },
    resetPreviewActivity: (state, action) => {
      state.previewActivity = {};
    },
    toggleOpenProfile: (state, action) => {
      state.isOpenProfile = action.payload;
    },
  },
  extraReducers: {
    [fetchRole.fulfilled]: (state, action) => {
      state.roles = action.payload;
    },
    [fetchUsers.fulfilled]: (state, action) => {
      state.users = action.payload?.data;
      state.totalUsers = action.payload?.meta?.pagination?.total;
    },
    [getUserById.fulfilled]: (state, action) => {
      state.user = action.payload;
    },
    [updateUserById.fulfilled]: (state, action) => {
      state.user = action.payload?.data;
    },
    [updateUserWithoutRefreshById.fulfilled]: (state, action) => {

    },
    [fetchLocation.fulfilled]: (state, action) => {
      state.locations = action.payload;
    },
    [fetchChartInfoByClinicianId.fulfilled]: (state, action) => {
      state.ChartInfoByClinicianId = action.payload;
    },
    [fetchChartInfoByClientId.fulfilled]: (state, action) => {
      state.ChartInfoByClientId = action.payload;
    },
    [fetchActivitiesByClientId.fulfilled]: (state, action) => {
      const { assignedActivities, totalActivity } = action.payload;
      state.activitiesByClientId = assignedActivities;
      state.totalActivity = totalActivity;
    },
    [previewAssignedActivity.fulfilled]: (state, action) => {
      state.previewActivity = action.payload;
    },
  },
});

export const {
  setAvatarResponse,
  resetAvatarResponse,
  resetUserDetail,
  resetChartInfoByClinicianId,
  resetChartInfoByClientId,
  resetActivitiesByClientId,
  resetPreviewActivity,
  toggleOpenProfile,
} = usersSlice.actions;

export const selectUserList = (state) => state.user.users;
export const selectTotalUser = (state) => state.user.totalUsers;
export const selectUserDetail = (state) => state.user.user;
export const selectLocations = (state) => state.user.locations;
export const selectRoles = (state) => state.user.roles;
export const selectAvatarResponse = (state) => state.user.updateAvatarResponse;
export const selectChartInfoByClinicianId = (state) =>
  state.user.ChartInfoByClinicianId;
export const selectChartInfoByClientId = (state) =>
  state.user.ChartInfoByClientId;
export const selectActivitiesByClientId = (state) =>
  state.user.activitiesByClientId;
export const selectTotalActivitiesByClientId = (state) =>
  state.user.totalActivity;
export const selectPreviewActivity = (state) => state.user.previewActivity;
export const selectOpenProfile = (state) => state.user.isOpenProfile;

export default usersSlice.reducer;
