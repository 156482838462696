import React from 'react';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

export const CustomizeTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    boxShadow: theme.shadows[1],
    fontSize: '12.05px',
    padding: '9.75px 13px',
    borderRadius: '8px',
  },
  [`& .${tooltipClasses.tooltipPlacementRight}`]: {
    marginLeft: '0px !important',
  },
  [`& .${tooltipClasses.tooltipPlacementTop}`]: {
    marginBottom: '0px !important',
  },
}));
