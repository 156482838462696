import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Knowledges from '../../api/admin/knowledge';
import { parseError } from '../../app/utils/helper';

export const fetchKnowledges = createAsyncThunk(
  'admin/fetch-knowledge',
  async (_, { rejectWithValue }) => {
    try {
      const response = await Knowledges.fetchKnowledges();
      return response.data;
    } catch (error) {
      return rejectWithValue(parseError(error));
    }
  }
);

export const createKnowledge = createAsyncThunk(
  'admin/create-knowledge',
  async (body, { rejectWithValue }) => {
    try {
      const { data } = await Knowledges.createKnowledge(body);
      return { data, message: 'Create knowledge successfully' };
    } catch (error) {
      return rejectWithValue(parseError(error));
    }
  }
);

export const updateKnowledge = createAsyncThunk(
  'admin/update-knowledge',
  async ({ id, body }, { rejectWithValue }) => {
    try {
      const { data } = await Knowledges.updateKnowledge(id, body);
      return { data, message: 'Update knowledge successfully' };
    } catch (error) {
      return rejectWithValue(parseError(error));
    }
  }
);

export const deleteKnowledge = createAsyncThunk(
  'admin/delete-knowledge',
  async ({ id }, { rejectWithValue }) => {
    try {
      const { data } = await Knowledges.deleteKnowledge(id);
      return { data, message: 'Delete knowledge successfully' };
    } catch (error) {
      return rejectWithValue(parseError(error));
    }
  }
);

const KnowledgeSlice = createSlice({
  name: 'knowledge',
  initialState: {
    knowledges: [],
    knowledgeCreated: null,
    knowledgeUpdated: null,
    knowledgeDeleted: null,
  },
  reducers: {},
  extraReducers: {
    [fetchKnowledges.fulfilled]: (state, action) => {
      state.knowledges = action.payload;
    },
    [createKnowledge.fulfilled]: (state, action) => {
      state.knowledgeCreated = action.payload.data;
    },
    [updateKnowledge.fulfilled]: (state, action) => {
      state.knowledgeUpdated = action.payload.data;
    },
    [deleteKnowledge.fulfilled]: (state, action) => {
      state.knowledgeDeleted = action.payload.data;
    },
  },
});

export default KnowledgeSlice.reducer;
