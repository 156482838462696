import React from 'react';
import { Dialog, DialogContent } from '@mui/material';
import SVG from 'react-inlinesvg';

function Modal({
  isOpen,
  onClose,
  onConfirm,
  content,
  doneMessage = null,
  style = null,
}) {
  return (
    <TransitionsModal isOpen={isOpen} onClose={onClose} style={style}>
      <div className="text-center mb-10">
        <div className="my-10 symbol symbol-circle symbol-50px">
          <div className="symbol-label bg-info">
            <span className="svg-icon svg-icon-2x svg-icon-white ">
              <SVG src="/images/icons/user-hub.svg" />
            </span>
          </div>
        </div>
        <h3 className="fw-bolder text-gray-800">{content}</h3>
        {doneMessage && <p className="text-gray-500 my-5">{doneMessage}</p>}
      </div>
      <a
        className="btn btn-primary w-100"
        href="#"
        onClick={(e) => onConfirm(e)}
      >
        OK
        <span className="svg-icon svg-icon-4  me-0 ms-2 opacity-50">
          <SVG src="/images/icons/check.svg" />
        </span>
      </a>
    </TransitionsModal>
  );
}

const TransitionsModal = ({ isOpen, onClose, style, children }) => {
  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogContent xs={{ p: 1 }}>
        <div
          style={{ width: 300, margin: '-20px -24px', ...style, padding: 20 }}
        >
          {children}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default Modal;
