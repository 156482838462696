import React, { useEffect, useMemo, useState } from 'react';
import SVG from 'react-inlinesvg';
import Chart from 'react-apexcharts';
import { useDispatch, useSelector } from 'react-redux';
import {
  getDataPulseChart,
  selectPulseChart,
} from '../../../../../features/client/dashboard';
import { selectAuthUser } from '../../../../../features/user/auth';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { transformDataPulseChart } from '../../../../utils/chartHelper';

dayjs.extend(utc);
function PulseCheck() {
  const currentDay = dayjs();

  const TypeDate = useMemo(() => {
    // let addition = -2;
    // // saturday
    // if (currentDay.get('day') === 6) {
    //   addition = -3;
    //   // sunday
    // } else if (currentDay.get('day') === 0) {
    //   addition = -4;
    // }
    return [
      {
        key: -6,
        value: 'Last 7 days',
      },
      {
        key: -13,
        value: 'Last 14 days',
      },
      {
        key: -27,
        value: 'Last 28 days',
      },
      {
        key: -29,
        value: 'Last 30 days',
      },
      {
        key: -55,
        value: 'Last 8 weeks',
      },
    ];
  }, [currentDay]);

  const [showDropdown, setShowDropdown] = useState(false);
  const [currentTypeDate, setCurrentTypeDate] = useState(TypeDate[0]);
  const dispatch = useDispatch();
  const authUser = useSelector(selectAuthUser);
  const pulseChartData = useSelector(selectPulseChart);
  const pulseCheckDataCurrents = transformDataPulseChart(pulseChartData);

  useEffect(() => {
    const queryParams = {
      user: authUser.id,
      date_gte: +dayjs()
        .add(currentTypeDate.key, 'day')
        .hour(9)
        .minute(0)
        .second(0)
        .millisecond(0),
      _sort: 'date:asc',
    };
    dispatch(getDataPulseChart(queryParams));
  }, [currentTypeDate]);

  const onHandleSelectDate = (typeDate) => {
    setCurrentTypeDate(typeDate);
  };

  var _color = '#20D489';
  var _colorLight = '#ADFEDC';

  const series = [
    {
      name: 'Pulse Check',
      data: pulseCheckDataCurrents.data,
    },
  ];

  const options = {
    chart: {
      type: 'area',
      height: 136,
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
      show: true,
      width: 3,
      colors: ['#20D489', ''],
    },
    labels: ['1', '2', '3', '4', '5', '6', '7'],
    xaxis: {
      categories: pulseCheckDataCurrents.categories,
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      show: false,
    },
    markers: {
      colors: _colorLight,
      strokeColor: _color,
      strokeWidth: 3,
    },
    grid: {
      show: false,
    },
    colors: [_color],
  };

  return (
    <div className="card card-stretch mb-5 mb-xxl-8">
      <div className="card-header border-0 pt-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bolder text-dark fs-2">
            Your Pulse Checks
          </span>
        </h3>
        <div className="card-toolbar">
          <button
            className={`btn btn-link text-success ${
              showDropdown ? 'show menu-dropdown' : ''
            }`}
            data-kt-menu-trigger="click"
            data-kt-menu-placement="bottom-end"
            style={{ position: 'relative' }}
            onClick={() => setShowDropdown(!showDropdown)}
          >
            <span>{currentTypeDate.value}</span>
            <span className="svg-icon svg-icon-4 svg-icon-success svg-icon-sm opacity-50">
              <SVG src="/images/icons/arrow-down.svg" />
            </span>

            <div
              className={`menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold w-200px p-3 ${
                showDropdown ? 'show' : ''
              }`}
              style={{
                position: 'absolute',
                left: '-100%',
                top: '45px',
              }}
              data-kt-menu="true"
            >
              <div className="menu-item">
                {TypeDate.map((item, index) => (
                  <a
                    key={index}
                    className={`menu-link p-3 pulse-check-option ${
                      item.key === currentTypeDate.key
                        ? 'text-dark fw-bolder'
                        : ''
                    }`}
                    onClick={() => onHandleSelectDate(item)}
                  >
                    {item.value}
                  </a>
                ))}
              </div>
            </div>
          </button>
        </div>
      </div>
      <div className="card-body pt-12 p-0 mt-n3">
        <div className="flex-grow-1">
          <Chart options={options} series={series} height={136}></Chart>
        </div>
      </div>
    </div>
  );
}

export default PulseCheck;
