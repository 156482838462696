import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import BannerAuth from '../../components/BannerAuth';
import FooterAuth from '../../components/FooterAuth';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch } from 'react-redux';
import { resetPassword, triggerSentMail } from '../../../features/user/auth';
import { PasswordRegex } from '../../utils/constants';

const ResetPassword = () => {
  const hiddenBtn = useRef(null);
  const dismissBtn = useRef(null);
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const code = query.get('code');
  const dispatch = useDispatch();
  const history = useHistory();

  const [second, setSecond] = useState(5);

  useEffect(() => {
    const timerInterval = setInterval(() => {
      setSecond((currentValue) => currentValue - 1);
    }, 1000);

    return () => {
      clearInterval(timerInterval);
    };
  }, [second]);

  const schema = yup.object().shape({
    password: yup
      .string()
      .min(6, 'Please ensure your password contains at least 6 characters')
      .required('Password is required'),
    passwordConfirmation: yup
      .string()
      .required('Password is required')
      .oneOf(
        [yup.ref('password'), null],
        'The password you have entered do not match. Please try again.'
      ),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      password: '',
      passwordConfirmation: '',
    },
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    const trasformData = {
      ...data,
      code,
    };
    const result = await dispatch(resetPassword(trasformData)).unwrap();
    if (result.user) {
      hiddenBtn.current.click();
      setSecond(5);
      setTimeout(function () {
        if (dismissBtn.current) {
          dismissBtn.current.click();
          history.push('/');
        }
      }, 5000);
    }
  };

  const onNavigate = () => {
    history.push('/');
    triggerSentMail(true);
  };

  return (
    <>
      <BannerAuth>
        <div className="login-content flex-lg-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden py-10 px-10 p-lg-7 mx-auto mw-450px w-100">
          <div className="d-flex flex-column-fluid flex-center py-10">
            <form
              className="form w-100"
              noValidate="novalidate"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="pb-5 pb-lg-10">
                <h3 className="fw-bolder text-dark fs-2x">Reset password</h3>
                <div className="text-danger">
                  Please ensure your password contains at least 6 characters
                </div>
              </div>
              <div className="fv-row mb-10">
                <div className="d-flex justify-content-between mt-n5">
                  <label className="form-label fs-6 fw-bolder text-dark pt-5">
                    New password
                  </label>
                </div>
                <input
                  className="form-control form-control-lg form-control-solid"
                  type="password"
                  name="passwordNew"
                  autoComplete="off"
                  {...register('password')}
                />
                <span className="invalid-feedback">
                  {errors['password']?.message}
                </span>
              </div>

              <div className="fv-row mb-10">
                <div className="d-flex justify-content-between mt-n5">
                  <label className="form-label fs-6 fw-bolder text-dark pt-5">
                    Retype new password
                  </label>
                </div>
                <input
                  className="form-control form-control-lg form-control-solid"
                  type="password"
                  name="passwordConfirmation"
                  autoComplete="off"
                  {...register('passwordConfirmation')}
                />
                <span className="invalid-feedback">
                  {errors['passwordConfirmation']?.message}
                </span>
              </div>

              <div className="d-flex flex-wrap pb-lg-0">
                <button
                  className="btn btn-info fw-bolder fs-6 px-8 py-4 my-3 me-4"
                  type="submit"
                >
                  Reset password
                </button>
                {/* this button is use to trigger modal */}
                <button
                  type="button"
                  ref={hiddenBtn}
                  style={{ display: 'none', opacity: 0 }}
                  data-bs-target="#modal-reset-password-successfully"
                  data-bs-toggle="modal"
                  key={'hidden'}
                >
                  Hidden
                </button>
                <Link
                  className="btn btn-light-info fw-bolder fs-6 px-8 py-4 my-3"
                  to="./"
                >
                  Cancel
                </Link>
              </div>
            </form>
          </div>
          <FooterAuth />
        </div>
      </BannerAuth>
      {/* Modal */}
      <div
        className="modal fade"
        id="modal-reset-password-successfully"
        tabIndex={-1}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <div className="text-center">
                <div className="my-10">
                  <i className="bi bi-check2-circle text-primary fs-5x" />
                </div>
                <h3>Reset password successfully</h3>
                <p>{`You will be navigated to login in ${second} seconds`}</p>
              </div>
            </div>
            <div className="modal-footer border-0 justify-content-center">
              <button
                className="btn btn-light-primary"
                data-bs-dismiss="modal"
                onClick={onNavigate}
              >
                Back to sign in
              </button>
              {/* this button is use to trigger modal */}
              <button
                className="btn btn-light-primary"
                data-bs-dismiss="modal"
                ref={dismissBtn}
                style={{ display: 'none', opacity: 0 }}
              >
                Dismiss
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
