import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchActivities,
  resetActivityGridView,
  selectActivityGridView,
  selectActivityList,
  selectTotalActivity,
} from '../../../../../features/client/activity';
import { Link } from 'react-router-dom';
import SVG from 'react-inlinesvg';
import ActivityTable from './Components/ActivityTable';
import ActivityGrid from './Components/ActivityGrid';
import { selectAuthUser } from '../../../../../features/user/auth';
import { initialPagination } from '../../../../utils/constants';

const ShowType = {
  List: 0,
  Grid: 1,
};

const ActivityType = {
  Active: 0,
  Complete: 1,
};

const ActivityList = () => {
  const dispatch = useDispatch();
  const authUser = useSelector(selectAuthUser);
  const activities = useSelector(selectActivityList);
  const totalActivity = useSelector(selectTotalActivity);
  const activityGridView = useSelector(selectActivityGridView);

  const [tab, setTab] = useState(ActivityType.Active);
  const [show, setShow] = useState(ShowType.Grid);
  const [pagination, setPagination] = useState(initialPagination);

  const queryParams = useMemo(() => {
    if (tab === ActivityType.Active) {
      return { completed_at_null: true, ...pagination };
    } else {
      return { completed_at_null: false, ...pagination };
    }
  }, [tab, pagination, show]);

  useEffect(() => {
    if (authUser) {
      const data = {
        id: authUser.id,
        queryParams: queryParams,
        isGridView: show === ShowType.List ? false : true,
      };
      dispatch(fetchActivities(data));
    }
  }, [authUser, queryParams]);

  useEffect(() => {
    return () => {
      dispatch(resetActivityGridView());
    };
  }, []);

  const handleSelectTab = (e, tabName) => {
    dispatch(resetActivityGridView());
    e.preventDefault();
    setTab(tabName);
    setPagination(initialPagination);
  };

  const handleSelectTypeShow = (type) => {
    dispatch(resetActivityGridView());
    setShow(type);
    setPagination(initialPagination);
  };

  const getTabClass = (name) => {
    const defaultClass =
      'nav-link rounded btn btn-active btn-active-primary fw-bolder btn-color-gray-600 py-3 px-5 mx-md-1 my-1 w-50 w-md-150px';
    return defaultClass + (name === tab ? ' active' : '');
  };

  const onChangeSize = (size) => {
    setPagination({ ...pagination, _start: 0, _limit: size });
  };

  const onPageClick = (page) => {
    setPagination({
      ...pagination,
      _start: pagination._limit * page,
    });
  };

  const handleLoadMore = () => {
    setPagination({
      ...pagination,
      _start: pagination._limit + pagination._start,
    });
  };

  return (
    <>
      <div className="d-flex flex-column flex-column-fluid">
        <div className="toolbar pt-8 pt-lg-0" id="kt_toolbar">
          <div className="container d-flex flex-stack flex-wrap flex-sm-nowrap">
            <div
              className="nav bg-white rounded px-3 py-2 w-100 w-md-auto"
              role="tablist"
            >
              <a
                className={getTabClass(ActivityType.Active)}
                data-bs-toggle="tab"
                role="tab"
                onClick={(e) => handleSelectTab(e, ActivityType.Active)}
                aria-selected="true"
              >
                Active
              </a>
              <a
                className={getTabClass(ActivityType.Complete)}
                data-bs-toggle="tab"
                role="tab"
                onClick={(e) => handleSelectTab(e, ActivityType.Complete)}
                aria-selected="false"
              >
                Complete
              </a>
            </div>
            <div className="d-none d-md-block">
              <ul className="nav flex-nowrap">
                <li
                  className="nav-item mb-1"
                  title=""
                  data-bs-toggle="tooltip"
                  data-bs-dismiss="click"
                  data-bs-placement="top"
                  data-bs-original-title="Show as grid"
                >
                  <div
                    className={`nav-link h-40px w-40px btn btn-custom btn-icon rounded-1 btn-view-mode me-5 ${
                      show === ShowType.Grid ? 'active' : ''
                    }`}
                    onClick={() => handleSelectTypeShow(ShowType.Grid)}
                  >
                    <span className="svg-icon svg-icon-4 svg-icon-dark ">
                      <SVG src="/images/icons/dashboard.svg" />
                    </span>
                  </div>
                </li>
                <li
                  className="nav-item mb-1"
                  title=""
                  data-bs-toggle="tooltip"
                  data-bs-dismiss="click"
                  data-bs-placement="top"
                  data-bs-original-title="Show as list"
                >
                  <div
                    className={`nav-link h-40px w-40px btn btn-custom btn-icon rounded-1 btn-view-mode ${
                      show === ShowType.List ? 'active' : ''
                    }`}
                    onClick={() => handleSelectTypeShow(ShowType.List)}
                  >
                    <span className="svg-icon svg-icon-4 svg-icon-dark ">
                      <SVG src="/images/icons/list.svg" />
                    </span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="content fs-6 d-flex flex-column-fluid" id="kt_content">
          <div className="container">
            <div className="d-flex flex-column flex-md-row block_activities-list position-relative">
              <div className="flex-lg-row-fluid">
                {tab === ActivityType.Complete && (
                  <div className="alert alert-dismissible bg-light-info d-flex p-5 my-lg-5">
                    <i className="fas fa-info-circle text-info me-5 fs-1"></i>
                    <div className="d-flex flex-column">
                      <h5 className="mb-1">You can redo activities</h5>
                      <span className="text-gray-500">
                        Enjoyed doing an activity?. Feel free to do the same
                        activity again.
                      </span>
                    </div>
                    <button
                      className="position-sm-relative m-2 m-sm-0 btn btn-icon ms-sm-auto align-self-center"
                      type="button"
                      data-bs-dismiss="alert"
                    >
                      <span className="svg-icon svg-icon-2x svg-icon-info ">
                        <SVG src="/images/icons/close.svg" />
                      </span>
                    </button>
                  </div>
                )}
                <div className="tab-content mt-5" id="myTabTables1">
                  {show === ShowType.List && (
                    <ActivityTable
                      isCompleted={tab === ActivityType.Complete}
                      activities={activities}
                      totalActivity={totalActivity}
                      onChangeSize={onChangeSize}
                      onPageClick={onPageClick}
                    />
                  )}
                  {show === ShowType.Grid && (
                    <ActivityGrid
                      activities={activityGridView}
                      handleLoadMore={handleLoadMore}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ActivityList;
