import React, { useState, useEffect, useRef } from 'react';
import SVG from 'react-inlinesvg';
import Pagination from '../../../../components/Pagination';
import { useDispatch, useSelector } from 'react-redux';
import DeleteButton from '../../../../components/DeleteButton';
import ModalConfirm from '../../../../components/Modal/ModalConfirm';
import {
  fetchAuthorisedUsers,
  setActivePage,
  setEditableAuthorisedUser,
  setPageSize,
  updateAuthorisedUserById
} from '../../../../../features/admin/authorisedUser';
import ModalCreate from './ModalCreate';
import ModalEdit from './ModalEdit';
import AuthorisedUser from '../../../../../api/admin/authorisedUser';
import useNotyf from '../../../../../hooks/useNotyf';

const AuthorisedUserList = () => {
  const [isShowModal, setShowModal] = useState(false);
  const selected = useRef(0);
  const dispatch = useDispatch();
  const [keyword, setKeyword] = useState('');
  const [visibleModal, setVisibleCreateModal] = useState(false);
  const authorisedUsers = useSelector(
    (state) => state.authorisedUser.authorisedUsers
  );
  const notify = useNotyf();
  const activePage = useSelector((state) => state.authorisedUser.activePage);
  const pageSize = useSelector((state) => state.authorisedUser.pageSize);
  const total = useSelector((state) => state.authorisedUser.total);

  useEffect(() => {
    getListAuthorisedUsers();
  }, [pageSize, activePage, keyword]);

  const getListAuthorisedUsers = () => {
    const queryParams = {
      'pagination[page]': activePage,
      'pagination[pageSize]': pageSize,
      'populate[0]': 'trips',
      sort: 'createdAt:desc',
    };
    if (keyword) {
      queryParams['filters[name][$contains]'] = keyword;
    }
    dispatch(fetchAuthorisedUsers(queryParams));
  };

  const onPageClick = (page) => {
    dispatch(setActivePage(page));
  };

  const onChangeSize = (size) => {
    dispatch(setPageSize(size));
  };

  const openEditAuthorisedUserModal = (id) => {
    dispatch(setEditableAuthorisedUser(id));
  };

  const handleUpdateStatus = async (id, status) => {
    try {
      await AuthorisedUser.updateAuthorisedUser(id, {
        data: {
          status,
        },
      });
      notify.success('Updated status successfully');
      getListAuthorisedUsers();
    } catch (error) {
      notify.error(error.message);
    }
  };

  const renderAuthorisedUserList = () => {
    return authorisedUsers.map((item, index) => {
      return (
        <tr key={index}>
          <td>{item.id}</td>
          <td>
            <div
              onClick={() => openEditAuthorisedUserModal(item.id)}
              className="car-trip cursor-pointer link"
            >
              {item?.attributes?.name}
            </div>
          </td>
          <td>{item?.attributes?.trips?.data?.length || 0}</td>
          <td className="text-capitalize">{item.attributes.status}</td>
          <td>
            <span
              className="car-trip cursor-pointer link"
              onClick={() => handleUpdateStatus(item.id, 'active')}
            >
              Show
            </span>{' '}
            /{' '}
            <span
              className="car-trip cursor-pointer link"
              onClick={() => handleUpdateStatus(item.id, 'inactive')}
            >
              Hide
            </span>
          </td>
          <td>
            <DeleteButton onClick={() => {
              selected.current = item.id;
              setShowModal(true);
            }} />
          </td>
        </tr>
      );
    });
  };

  return (
    <>
      <div className="d-flex flex-column flex-column-fluid">
        <div className="toolbar mb-5 mt-lg-0" id="kt_toolbar">
          <div className="container d-flex flex-stack flex-wrap flex-sm-nowrap">
            <div className="d-flex align-items-center flex-nowrap text-nowrap overflow-auto py-1"></div>
            <div className="d-flex align-items-center position-relative my-1">
              <span className="svg-icon svg-icon-2 svg-icon-info ms-6 z-index-1">
                <SVG src="/images/icons/search.svg" />
              </span>
              <input
                className="form-control form-control-solid w-100 mw-350px ps-15 ms-n12 bg-white"
                type="text"
                data-kt-docs-table-filter="search"
                placeholder="Search (name)"
                value={keyword}
                onChange={(e) => setKeyword(e.target.value)}
              />
            </div>
            <div className="ms-md-auto">
              <button
                className="btn btn-primary"
                onClick={() => setVisibleCreateModal(true)}
              >
                <i className="fas fa-plus opacity-50 me-2"></i>
                Create
              </button>
            </div>
          </div>
        </div>
        <div className="content fs-6 d-flex flex-column-fluid" id="kt_content">
          <div className="container">
            <div className="card">
              <div className="card-body">
                <div
                  id="kt_datatable_example_2_wrapper"
                  className="dataTables_wrapper dt-bootstrap4 no-footer"
                >
                  <div className="mobile-table">
                    <table
                      className="table table-striped table-row-bordered gy-5 gs-7 w-100 align-middle"
                      id="kt_datatable_example_2"
                    >
                      <thead>
                        <tr className="fw-bold fs-6 text-gray-800">
                          <th className="min-w-200px sorting sorting_asc">
                            ID
                          </th>
                          <th className="min-w-100px ">Name</th>
                          <th className="min-w-200px ">No of Trips</th>
                          <th className="min-w-100px ">Status</th>
                          <th className="min-w-100px "></th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>{renderAuthorisedUserList()}</tbody>
                    </table>
                  </div>
                  <Pagination
                    limit={pageSize}
                    onChangeSize={onChangeSize}
                    onPageClick={onPageClick}
                    total={total}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <ModalCreate
          visible={visibleModal}
          onCancel={() => setVisibleCreateModal(false)}
        />
        <ModalEdit />
        <ModalConfirm
          isOpen={isShowModal}
          handleClose={() => setShowModal(false)}
          confirm={async () => {
            setShowModal(false);
            await dispatch(updateAuthorisedUserById({
              id: selected.current,
              body: {
                data: {
                  publishedAt: null
                },
              },
            }));
            onPageClick(0);
          }}
          modalTitle={'Are you sure you want to do this?'}
          modalNotes={'This action can not be undone'}
        />
      </div>
    </>
  );
};

export default AuthorisedUserList;
