import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { get } from 'lodash';
import SVG from 'react-inlinesvg';
import './styles.scss';
import DeleteButton from '../../../../../components/DeleteButton';
import ModalConfirm from '../../../../../components/Modal/ModalConfirm';
import { useDispatch } from 'react-redux';
import { updateCar } from '../../../../../../features/admin/cars';

function CarCard({ car, handleShowConfirm, handleEditCar, hideBar, onDelete }) {
  const [isShowModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  return (
    <div className="card card-stretch border-0">
      <div className="card-body p-0 border-0 ribbon ribbon-start ribbon-clip">
        <Link className="d-block" to="#">
          <div
            className={`bgi-no-repeat bgi-size-cover bgi-position-center min-h-sm-150px min-h-md-250px p-5 ${hideBar === true ? 'rounded' : 'rounded-top'
              }`}
            style={{
              backgroundImage: `url(${get(car, 'attributes.image.data.attributes.url') ||
                '/images/illustrations/carModel.png'
                })`,
            }}
            onClick={handleEditCar(car)}
          />
        </Link>
        <div className="ribbon-label ribbon-topic text-white fw-normal">
          {get(car, 'attributes.status')}
          <span
            className={`ribbon-inner ${get(car.attributes, 'status') === 'available'
              ? 'bg-primary'
              : 'bg-info'
              }`}
          ></span>
        </div>
        {/* {onDelete && (
          <DeleteButton
            style={{ right: 10, top: 10, position: 'absolute' }}
            onClick={() => setShowModal(true)}
          />
        )} */}
        <div className="rounded car-id">{get(car, 'attributes.qrId')}</div>
        {get(car, 'attributes.incidentReport') && (
          <div
            // className="position-absolute text-white fw-normal fs-md-7 shadow-sm py-1 px-2 rounded-pill bg-info"
            className="ribbon ribbon-triangle bg-info py-1 px-2 rounded-pill "
            style={{
              bottom: '35px',
              right: '30px',
            }}
          >
            <span className="d-flex align-items-center text-white">
              <SVG
                src="/images/icons/carIncident.svg"
                width={16}
                height="100%"
              />
              <span className="px-1">Incident Reported</span>
              <SVG
                src="/images/icons/borderClose.svg"
                width={16}
                height="100%"
                className="hoverable"
                onClick={() => handleShowConfirm(car)}
              />
            </span>
          </div>
        )}
      </div>
      <ModalConfirm
        isOpen={isShowModal}
        handleClose={() => setShowModal(false)}
        confirm={() => {
          setShowModal(false);
          dispatch(
            updateCar({
              id: car.id,
              body: {
                data: {
                  publishedAt: null,
                },
              },
            })
          );
          onDelete();
        }}
        modalTitle={'Are you sure you want to do this?'}
        modalNotes={'This action can not be undone'}
      />
      {hideBar !== true && (
        <div className="d-flex card-body p-5 items-center justify-content-between">
          <h1 className="text-dark text-uppercase fw-boldest m-0">
            {get(car, 'attributes.name')}
          </h1>
          <h1 className="text-dark text-uppercase fw-normal m-0 reg-text">
            {get(car, 'attributes.regNumber')}
          </h1>
        </div>
      )}
    </div>
  );
}

export default CarCard;
