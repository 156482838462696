import http from '../../services/http-common';

export default class Topic {
  static fetchTopics(queryParams) {
    return http.get('/topics', queryParams);
  }

  static createTopic(body) {
    return http.post('/topics', body);
  }

  static updateTopic(id, body) {
    return http.put(`/topics/${id}`, body);
  }

  static deleteTopic(id) {
    return http.delete(`/topics/${id}`);
  }
}
