import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AssignedActivity from '../../api/admin/assignedActivity';
import { parseError } from '../../app/utils/helper';

export const fetchAssignedClients = createAsyncThunk(
  'adminAssignedActivity/fetchAssignedClients',
  async (data, { rejectWithValue }) => {
    try {
      const response = await AssignedActivity.fetchAssignedActivities(data);
      return response.data;
    } catch (error) {
      return rejectWithValue(parseError(error));
    }
  }
);

export const fetchAssignedUserByName = createAsyncThunk(
  'adminAssignedActivity/fetchAssignedUserByName',
  async (queryParams, { rejectWithValue }) => {
    try {
      const response = await AssignedActivity.fetchAssignedUserByName(
        queryParams
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(parseError(error));
    }
  }
);

export const createAssignedActivity = createAsyncThunk(
  'adminAssignedActivity/createAssignedActivity',
  async (assignedActivityData, { rejectWithValue }) => {
    try {
      const { data } = await AssignedActivity.createAssignActivity(
        assignedActivityData
      );
      return { data, message: 'Assign activity successfully' };
    } catch (error) {
      return rejectWithValue(parseError(error));
    }
  }
);

export const deleteAssignedActivity = createAsyncThunk(
  'adminAssignedActivity/deleteAssignedActivity',
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await AssignedActivity.deleteAssignActivity(id);
      return { data, message: 'Delete assign activity successfully' };
    } catch (error) {
      return rejectWithValue(parseError(error));
    }
  }
);

const assignedActivitiesSlice = createSlice({
  name: 'adminAssignedActivity',
  initialState: {
    assignedClients: [],
    isShowDeleteModal: false,
  },
  reducers: {},
  extraReducers: {
    [fetchAssignedClients.fulfilled]: (state, action) => {
      state.assignedClients = action.payload;
    },
    [fetchAssignedUserByName.fullfilled]: (state, action) => {
      state.assignedClients = action.payload;
    },
  },
});

export default assignedActivitiesSlice.reducer;
