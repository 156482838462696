import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { get, find } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import SVG from 'react-inlinesvg';

import ModalConfirm from '../../../../components/Modal/ModalConfirm';
import UploadCarImage from './UploadCarImage';
import { MultiSelect, SingleSelect } from '../../../../components/Selects';
import { upload } from '../../../../../features/upload';
import { createCar } from '../../../../../features/admin/cars';

const carModel = {
  image: '',
  name: '',
  regNumber: '',
  incidentReport: false,
  status: 'available',
};

const carForm = () => {
  const dispatch = useDispatch();
  const { activityId } = useParams();
  const history = useHistory();

  const [isDisabled, setDisabled] = useState(true);
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const [image, setImage] = useState(0);
  const [name, setName] = useState('');
  const [regNumber, setRegNumber] = useState('');

  useEffect(() => {
    if (image && name && regNumber) setDisabled(false);
    else setDisabled(true);
  }, [image, name, regNumber]);

  const handleSubmitCreateCar = async () => {
    try {
      if (isDisabled) return;
      const body = { name, image, regNumber };
      const data = { data: body };
      const result = await dispatch(createCar(data)).unwrap();
      setIsSuccess(true);
    } catch (error) {
      setIsError(true);
    }
  };

  const uploadImage = async (file) => {
    const formData = new FormData();
    formData.append('files', file);
    formData.append(
      'fileInfo',
      JSON.stringify({ alternativeText: '', caption: '', name: null })
    );
    try {
      const uploadResponse = await dispatch(upload(formData)).unwrap();
      return uploadResponse;
    } catch (error) {
      console.log(error);
    }
  };

  const getImageCar = async (file) => {
    const imageResource = await uploadImage(file);
    return setImage(!!imageResource?.length && get(imageResource, '0.id', ''));
  };

  const handleConfirmSuccess = () => {
    history.push('/admin/cars');
    return setIsSuccess(false);
  };

  return (
    <div>
      <div className="d-flex flex-column flex-column-fluid">
        <div className="content fs-6 d-flex flex-column-fluid">
          <div className="container">
            <div className="card">
              <div className="m-10">
                <div className="mb-10">
                  <label className="fw-bold fs-3 text-dark mb-10">
                    Select Car Picture
                  </label>
                  <div>
                    <UploadCarImage
                      getImage={getImageCar}
                      typeResource="Image"
                      // defaultImage={activityPicture}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col mb-10">
                    <div className="d-flex justify-content-between mb-5">
                      <label className="fw-bold fs-3 text-dark">Name</label>
                      <span className="fw-bold fs-6 text-muted">{`${0}/100 characters`}</span>
                    </div>
                    <input
                      className="form-control fw-bold fs-6 text-gray-600"
                      placeholder="Name car"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                  <div className="col mb-10">
                    <div className="d-flex justify-content-between mb-5">
                      <label className="fw-bold fs-3 text-dark">
                        Registration number
                      </label>
                      <span className="fw-bold fs-6 text-muted">{`${0}/50 characters`}</span>
                    </div>
                    <input
                      className="form-control fw-bold fs-6 text-gray-600"
                      placeholder="Registration number"
                      value={regNumber}
                      onChange={(e) => setRegNumber(e.target.value)}
                    />
                  </div>
                </div>

                <div className="mb-10">
                  <button
                    className={`btn px-11 py-4 fw-bolder float-end ${
                      !isDisabled ? 'btn-primary' : 'btn-light'
                    }`}
                    onClick={handleSubmitCreateCar}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isSuccess && (
        <ModalConfirm
          isOpen={!!isSuccess}
          handleClose={() => setIsSuccess(false)}
          confirm={handleConfirmSuccess}
          modalTitle={'Create Car Successfully'}
          modalNotes={'This action was created!'}
          CustomIcon={CheckCircleIcon}
        />
      )}

      {isError && (
        <ModalConfirm
          isOpen={!!isError}
          handleClose={() => setIsError(false)}
          confirm={() => setIsError(false)}
          modalTitle={'Create Activity Failed'}
          modalNotes={'This action can not be undone!'}
        />
      )}
    </div>
  );
};

export default carForm;
