import React from 'react';
import SVG from 'react-inlinesvg';

function ScrollTop() {
  const ScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    const body = document.getElementById('kt_body');
    body.setAttribute('data-kt-scrolltop', 'off');
  };

  return (
    <div
      className="scrolltop"
      id="kt_scrolltop"
      data-kt-scrolltop="true"
      onClick={ScrollToTop}
    >
      <span className="svg-icon svg-icon-4  ">
        <SVG src="/images/icons/up-2.svg" />
      </span>
    </div>
  );
}

export default ScrollTop;
