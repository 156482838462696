import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Knowledges from '../../api/client/knowledges';
import { parseError } from '../../app/utils/helper';

export const fetchKnowledges = createAsyncThunk(
  'clientKnowledges/fetchKnowledges',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await Knowledges.fetchKnowledges();
      return data;
    } catch (error) {
      return rejectWithValue(parseError(error));
    }
  }
);

const initialState = {
  listKnowledge: [],
};

const knowledgesSlice = createSlice({
  name: 'clientKnowledges',
  initialState,
  extraReducers: {
    [fetchKnowledges.fulfilled]: (state, action) => {
      state.listKnowledge = action.payload;
    },
  },
});

export const selectListKnowledge = (state) =>
  state.clientKnowledge.listKnowledge;

export default knowledgesSlice.reducer;
