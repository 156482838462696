import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import { useSelector } from 'react-redux';
import { selectStateLoading } from '../../features/loading/loadingSlice';

const Loading = () => {
  const loadingState = useSelector(selectStateLoading);
  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: 10000 }}
      open={loadingState === 'loading' ? true : false}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default Loading;
