import React from 'react';

import { Link } from 'react-router-dom';

const Breadcrumbs = ({ list }) => {
  return (
    <ul className="breadcrumb breadcrumb-line bg-transparent text-muted fw-bold p-0 my-1 fs-7">
      {list.map((item, index) => (
        <li
          className={`breadcrumb-item ${!item.path && 'text-dark'}`}
          key={`breadcrumb-${index}`}
        >
          {item.path ? (
            <Link className="text-muted text-hover-primary" to={item.path}>
              {item.name}
            </Link>
          ) : (
            item.name
          )}
        </li>
      ))}
    </ul>
  );
};

export default Breadcrumbs;
