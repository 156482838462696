import React, { useState, useEffect } from 'react';
import * as yup from 'yup';
import { get } from 'lodash';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { CarTypes } from '../../../../utils/constants';
import QRCode from 'react-qr-code';
import { printToFile } from '../../../../utils/helper';
import ModalConfirm from '../../../../components/Modal/ModalConfirm';
import { updateCar } from '../../../../../features/admin/cars';
import { useDispatch } from 'react-redux';

import './styles.scss';
import { SingleSelect } from '../../../../components/Selects';
import UploadImage from '../../../../components/UploadImage';

const FormCar = (props) => {
  const schema = yup.object().shape({
    regNumber: yup.string().required('Registration Number is required'),
    name: yup.string().required('Car Name is required'),
  });

  const [carStatus, setCarStatus] = useState(null);
  const [isShowModal, setShowModal] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (props?.carSelect?.status) {
      const carType = handleMappingCarType().find(
        (item) => item.type === (props?.carSelect?.status ?? 'available')
      );
      return setCarStatus(carType);
    }
  }, [props?.carSelect]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const triggerSubmit = (data) => {
    data = { ...data, status: carStatus?.title ?? 'available' };
    props.onSubmit(data);
  };

  const handleMappingCarType = () => {
    return CarTypes.map((type) => {
      return { type, title: type };
    });
  };

  const handleSelectValue = (data) => {
    setCarStatus(data);
  };

  return (
    <>
      <form onSubmit={handleSubmit(triggerSubmit)}>
        <div className="modal-body">
          <div className="row mb-4">
            <label className="col-lg-3 col-form-label">Id</label>
            <div className="col-lg-9 d-flex align-items-center">
              <div>{props?.carSelect?.qrId ?? ''}</div>
            </div>
          </div>
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">Status</label>
            <div className="col-lg-9">
              <SingleSelect
                data={handleMappingCarType()}
                defaultValue={handleMappingCarType().find(
                  (item) => item.type === (carStatus?.type ?? 'available')
                )}
                getData={(value) => handleSelectValue(value, 'type')}
                placeholder={'Select Status...'}
              />
            </div>
          </div>
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">Upload Image</label>
            <div className="col-lg-9">
              <UploadImage
                getImage={props.getFile}
                typeResource="Image"
                defaultImage={get(
                  props.carSelect,
                  'image.data.attributes.url',
                  ''
                )}
              />
            </div>
          </div>
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">Car Name</label>
            <div className="col-lg-9">
              <div className="spinner spinner-sm spinner-primary spinner-right">
                <input
                  className="form-control form-control-lg"
                  type="text"
                  defaultValue={props?.carSelect?.name ?? ''}
                  {...register('name')}
                />
                <span className="invalid-feedback">
                  {errors['name']?.message}
                </span>
              </div>
            </div>
          </div>
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">Registration</label>
            <div className="col-lg-9">
              <div className="spinner spinner-sm spinner-primary spinner-right">
                <input
                  className="form-control form-control-lg"
                  type="text"
                  defaultValue={props?.carSelect?.regNumber ?? ''}
                  {...register('regNumber')}
                />
                <span className="invalid-feedback">
                  {errors['regNumber']?.message}
                </span>
              </div>
            </div>
          </div>
          {props?.id && props?.carSelect && (
            <div className="row">
              <label className="col-lg-3 col-form-label">QR Code</label>
              <div className="col-lg-9">
                <div className="overlay rounded overflow-hidden w-150px">
                  <div className="overlay-wrapper rounded bg-light text-center">
                    <QRCode value={props?.carSelect?.qrId?.toString()} size={120} />
                  </div>
                  <div className="overlay-layer">
                    <div
                      onClick={() => {
                        printToFile(
                          props?.carSelect?.qrId?.toString(),
                          props?.carSelect?.qrId?.toString()
                        );
                      }}
                      className="btn fw-bolder btn-sm btn-light-primary"
                    >
                      Download
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className='d-flex flex-row justify-content-between'>
          <div className="modal-footer"><button
            className="btn btn-danger"
            type="button"
            onClick={() => setShowModal(true)}
          >
            Delete
          </button></div>
          <div className="modal-footer">
            <button
              className="btn btn-light"
              type="button"
              onClick={props.handleClose}
            >
              Cancel
            </button>
            <button className="btn btn-light-primary">Save</button>
          </div>
        </div>
      </form>
      <ModalConfirm
        isOpen={isShowModal}
        handleClose={() => setShowModal(false)}
        confirm={() => {
          setShowModal(false);
          props.handleClose();
          props.onDelete();
          dispatch(
            updateCar({
              id: props.id,
              body: {
                data: {
                  publishedAt: null,
                },
              },
            })
          );
        }}
        modalTitle={'Are you sure you want to do this?'}
        modalNotes={'This action can not be undone'}
      /></>
  );
};

export default FormCar;
