import React, { useState } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { SingleSelect } from '../../../../components/Selects';
import { find } from 'lodash';

const FormFaq = (props) => {
  const [faqType, setFaqType] = useState({
    title: props.faqCategory[0].category,
    id: props.faqCategory[0].id,
  });

  const schema = yup.object().shape({
    question: yup.string().required('Question is required'),
    answer: yup.string().required('Answer is required'),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const triggerSubmit = (data) => {
    data = { ...data, faq_category: faqType };
    props.onSubmit(data);
  };

  const getDefaultValue = () => {
    const { faqSelected, faqCategory } = props;
    if (faqSelected) {
      const matched = find(faqCategory, { id: faqSelected.faq_category.id });
      const { category, id, ...rest } = matched;
      return { title: category, id };
    } else {
      return {
        title: faqCategory[0].category,
        id: faqCategory[0].id,
      };
    }
  };

  const handleSetFaqType = (value) => {
    setFaqType(value);
  };

  return (
    <>
      <div className="row mb-8">
        <label className="col-lg-3 col-form-label">Question Type</label>
        <div className="col-lg-9">
          <SingleSelect
            data={props.faqCategory.map((item) => ({
              id: item.id,
              title: item.category,
            }))}
            getData={(value) => handleSetFaqType(value)}
            defaultValue={getDefaultValue}
            {...register('type')}
          />
        </div>
      </div>
      <form onSubmit={handleSubmit(triggerSubmit)}>
        <div>
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">Question</label>
            <div className="col-lg-9">
              <div className="spinner spinner-sm spinner-primary spinner-right">
                <input
                  className="form-control form-control-lg"
                  type="text"
                  defaultValue={props.faqSelected?.question}
                  {...register('question')}
                />
                <span className="invalid-feedback">
                  {errors['question']?.message}
                </span>
              </div>
            </div>
          </div>
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">Answer</label>
            <div className="col-lg-9">
              <div className="spinner spinner-sm spinner-primary spinner-right">
                <textarea
                  className="form-control form-control-lg"
                  rows="10"
                  type="text"
                  defaultValue={props.faqSelected?.answer}
                  {...register('answer')}
                />
                <span className="invalid-feedback">
                  {errors['answer']?.message}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          {props.faqSelected?.id && (
            <button
              className="btn btn-link me-auto text-danger"
              onClick={props.onDeleteFaq}
              type="button"
            >
              Remove
            </button>
          )}
          <button
            className="btn btn-light"
            onClick={props.handleClose}
            type="button"
          >
            Cancel
          </button>
          <button className="btn btn-light-primary">Save</button>
        </div>
      </form>
    </>
  );
};

export default FormFaq;
